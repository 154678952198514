import React from 'react';
import { Tooltip } from 'react-tooltip';
import { renderToString } from 'react-dom/server';

const InfoCustom = ({ content, componentPassed, customClass }: { content: string, componentPassed: any, customClass: string }) => {
  return (
    <div className='select-none'>
      <Tooltip anchorSelect=".my-anchor-element" place="top">
        <div style={{ whiteSpace: "normal" }}>{content}</div>
      </Tooltip>
      <div
        className="my-anchor-element mt-[2px] text "
        data-tooltip-id="my-tooltip"
        data-tooltip-content={content}
        data-tooltip-place="top"
      >
        <div className={customClass} dangerouslySetInnerHTML={{ __html: renderToString(componentPassed) }} />
      </div>
    </div>
  );
};

export default InfoCustom;