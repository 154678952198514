import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../hooks/storeHook";
import RestrictVerificationModal from "../../pages/Home/Restrict";

const AuthRoutes = () => {
  const { user } = useAppSelector((state) => state.auth);
  const restrict = user?.suspension?.isSuspended;

  return Boolean(user) ? (
    <>
      {restrict ? (
        <RestrictVerificationModal />
      ) : (
        <Outlet />
      )}
    </>
  ) : (
    <Navigate to="/auth" />
  );
};

export default AuthRoutes;