import  { Suspense, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { authClasses } from '../Auth/authClasses';
import { UserCredentialFormDetails } from '../../models/user/UserDetailsForm';
import { db } from '../../firebase';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook';
import { get, ref, set, update } from 'firebase/database';
import { login } from '../../features/authSlice';
import { threeToTwoLetterMap } from '../../models/other/Allcountries';
import Footer from '../../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../Home/Loading';

const UserCredentialForm = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const {
    form,
    button,
    input,
  } = authClasses;

  useEffect(() => {
    setLoading(true)
    if (user?.userData) {
      navigate("/")
    }
    setLoading(false)
  }, [user, navigate])
  
  const fav = [
    "Real life experiences",
    "Fantasy",
    "Magical realism",
    "Mythology",
    "Short stories",
    "Poems",
    "Young adult",
    "Erotica",
    "Romance",
    "Mystery",
    "Fanfiction",
    "Everyday memoir",
    "Daily frustration",
    "Comedy",
    "Sci-fiction",
    "Literary fiction",
    "Adventure",
    "Essay",
    "Flash fiction",
    "Hear-me-out",
    "Thriller",
    "Exposé",
    "Confessions",
    "Self-help",
    "Tech",
    "Historical fiction",
    "Autobiography",
    "Psychology", 
    "Satire"
  ];

  const { register, handleSubmit, formState: { errors } } = useForm<UserCredentialFormDetails>();

  const handleOptionClick = (value: string) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter(option => option !== value));
    } else {
      if (selectedOptions.length < 7) {
        setSelectedOptions(prevOptions => [...prevOptions, value]);
        // Use the callback function to log the updated state
        setSelectedOptions(updatedOptions => {
          console.log(updatedOptions);
          return updatedOptions; // Ensure the state is still updated
        });
      }
    }
  };

  const handleFormSubmit = async (data: UserCredentialFormDetails) => {
    setLoading(true);
    setErrorMessage(null);

    const { name, birthdate, gender, countryOrigin, policy } = data;
    const favoriteOptions = selectedOptions.slice(0, 6);

    try {
      if (user && user.email) {
        if (favoriteOptions.length <5) {
        setLoading(false);
        // Display an alert or toast message
        toast.error('Please select at least Five favorite option');
        return; // Stop form submission
      }
        const userData = {
          email: user.email,
          id: user.id,
          photoUrl: user.photoUrl || null,
          isAuther: user.isAuther || false,
          userData: user.userData || null,
        };

        const userDetailsRef = ref(db, `users/${user.id}/userData`);
        const autherDetailsRef = ref(db, `authers/${user.id}/`);
        const userSnapshot = await get(userDetailsRef);

        if (userSnapshot.exists()) {
          await update(userDetailsRef, { photoUrl: userData.photoUrl });
        } else {
          await Promise.all([
            set(userDetailsRef, userData),
            set(userDetailsRef, {
              name,
              birthdate,
              gender,
              countryOrigin,
              favoriteOptions,
              policy
            }),
            set(autherDetailsRef, {
              autherName:name,
              email:userData.email,
              id:user.id
            }),
          ]);

          await dispatch(
            login({
              email: user.email,
              id: user.id,
              isAuther: user.isAuther,
              photoUrl: user.photoUrl,
              userData: {
                name,
                birthdate,
                gender,
                countryOrigin,
                favoriteOptions,
                policy
              },
              bookshelf:user.bookshelf,
              suspension:user.suspension
            })
          );
        }

        toast.success('Thank you for your Information');
        navigate("/")
        setSuccess(true);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      const errorCode = error.code;
      setErrorMessage(errorCode);
    }
  };

  return (
    <div className='select-none'>
      {/* <Header /> */}
      <ToastContainer/>
      {
      // loading?(<><Loading/></>):
    errorMessage?(<>{errorMessage}</>):
     
<div className=" lg:p-24 bg-white dark:bg-slate-800">
        <div className="shadow-2xl w-full p-2 lg:p-10">
        <div className="text-3xl font-bold text-center text-gray-900 dark:text-gray-100">- Personal Details -</div>
<div className="px-8">

        <div className="h-[0.5px] bg-gray-300 w-full mt-4 "></div>
</div>
        <form className={form} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="md:flex w-full gap-8">

          
          <div className="grid w-full gap-y-3">
            <label htmlFor="name" className="block text-gray-700 dark:text-gray-200 text-lg font-bold mt-2 lg:mt-0 lg:mb-2 font-1">Name:</label>
            <input
            required
              type="text"
              placeholder='Your Name'
              id="name"
              className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"}
              {...register('name', { required: 'Name is required' })}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>
         
          <div className="grid w-full gap-y-3">
            <label htmlFor="birthdate" className="block text-gray-700 dark:text-gray-200 text-lg font-bold mt-2 lg:mt-0 lg:mb-2 font-1">Birthdate:</label>
            <input
            required
              type="date"
              max={new Date().toISOString().split('T')[0]}
              id="birthdate"
              className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"}
              {...register('birthdate', { required: 'Birthdate is required' })}
            />
            {errors.birthdate && <p>{errors.birthdate.message}</p>}
          </div>

          </div>
          <div className="md:flex w-full mt-4 gap-8">
          <div className="grid w-full gap-y-3">
          <label htmlFor="gender" className="block text-gray-700 dark:text-gray-200 text-lg font-bold mt-2 lg:mt-0 lg:mb-2 font-1">Gender:</label>
            <select
            required
              id="gender"
              className={"shadow appearance-non border text-center rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"}
              {...register('gender', { required: 'Gender is required' })}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {errors.gender && <p>{errors.gender.message}</p>}
          </div>

          <div className="grid w-full gap-y-3">
            <label htmlFor="countryOrigin" className="block text-gray-700 dark:text-gray-200 text-lg font-bold mt-2 lg:mt-0 lg:mb-2 font-1">Country of Origin:</label>
            <select
            required id="countryOrigin" className={"shadow appearance-non border text-center rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"} {...register('countryOrigin', { required: 'Country of Origin is required' })}>
        <option value="">Select a country</option>
        {Object.keys(threeToTwoLetterMap).map((countryName) => (
          <option key={threeToTwoLetterMap[countryName]} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
            {errors.countryOrigin && <p>{errors.countryOrigin.message}</p>}
          </div>
          </div>
          <div className="grid  gap-y-3">
            
         

          

          
          <div className="grid gap-y-3 mt-4">
            <label htmlFor="favoriteOptions" className="block text-gray-700 dark:text-gray-200 text-lg font-bold mt-2 lg:mt-0 lg:mb-2 font-1">Favorite Options:</label>
            {selectedOptions.slice(0, 5).map((option, index) => (
              <input
              required
              key={index}
              type="hidden"
              name={`favoriteOptions[${index}]`}
              value={option}
              />
              ))}
          </div>
          
        <div>
         
    <div className="flex flex-wrap gap-4">
      {fav.map((option, index) => (
        <div
          key={index}
          className={`rounded-md font-1 font-bold p-2 m-1 cursor-pointer ${
            selectedOptions.includes(option) ? ' rounded-md bg-blue-900 text-white px-4 py-2.5 ' : 'bg-gray-500 text-white'
          }`}
          onClick={() => handleOptionClick(option)}
        >
          {option}
          {selectedOptions.includes(option) && (
            <span
              className="ml-6 text-red-500 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleOptionClick(option);
              }}
            >
             <i className="fa-sharp fa-solid fa-trash "></i>
            </span>
          )}
        </div>
      ))}
    </div>
  
        </div>
            {errorMessage && <div>{errorMessage}</div>}


            <div className="px-8">

<div className="h-[0.5px] bg-gray-300 w-full mt-4 "></div>
</div>
            <div className="lg:flex">
        

        <div className="flex w-full items-center lg:items-start mt-12">
            <input
            required id="checkbox-1" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-5 w-5 rounded" {...register('policy')}/>
            <label htmlFor="checkbox-1" className="text-[10px] lg:text-sm  ml-3 font-medium text-gray-700 dark:text-gray-200">I agree to the {" "}
            <Link to="/privacypolicy">
        <label htmlFor="policy" className='underline'>Privacy Policy </label>
        </Link>
        {" "}
        and
        <Link to="/terms&conditions">{" "}
        <label htmlFor="policy" className='underline'>Terms and Conditions</label>
        </Link>      
            </label>
        </div>

        <div className='flex justify-center'>

<button type="submit" className={"mt-8 rounded-md bg-blue-900 text-white px-4 py-2.5"} disabled={loading}>
  Submit
</button>
</div>
          </div>
          
         
          </div>
        </form>
        </div>
      </div>}
      <Footer/>
    </div>
  );
};

export default UserCredentialForm;