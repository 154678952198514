import React, { useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import { ref, onValue, off, update } from "firebase/database";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAppSelector } from "../../hooks/storeHook";
import Like from "./Like";
import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";

interface ChapterFetchProps {
  storyID?: string;
  chapterID?: string;
  autherID?: string;
  autherName?: string;
  title?: string;
}

const ChapterFetch: React.FC<ChapterFetchProps> = ({
  autherID,
  storyID,
  chapterID,
  autherName,
  title,
}) => {
  const [chapterContent, setChapterContent] = useState<string | null>(null);
  const [editedContent, setEditedContent] = useState<string | null>(null);

  const [chapterNum, setChapterNum] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [chapterTitle, setChapterTitle] = useState<string | null>(null);
  const { user } = useAppSelector((state) => state.auth);

  const [imgexists, setimgexists] = useState(false);
  useEffect(() => {
    if (!storyID || !chapterID) {
      return;
    }

    const chapterRef = ref(db, `allStories/${storyID}/chapters/${chapterID}`);

    const chapterListener = onValue(
      chapterRef,
      (snapshot) => {
        const chapterData = snapshot.val();
        if (chapterData && chapterData.content) {
          setChapterNum(chapterData.chapterNo);
          setChapterContent(chapterData.content);
          setEditedContent(chapterData.content);
          setChapterTitle(chapterData.title); // Initialize edited content with fetched content
        }
      },
      (error) => {
        console.error("Error fetching chapter content:", error);
      }
    );

    return () => {
      // Clean up the listener when the component unmounts
      off(chapterRef, "value", chapterListener);
    };
  }, [storyID, chapterID]);
  const handleContentChange = (newContent: string) => {
    setEditedContent(newContent);
  };

  const shareRef = useRef<HTMLInputElement | null>(null);

  const [wordCount, setWordCount] = useState(0);

  const navigate = useNavigate();

  const copyStringToClipboard = function (str: any) {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";

    document.body.appendChild(el);

    // Select text inside the textarea
    el.select();

    // Create a range and select the text
    const range = document.createRange();
    range.selectNodeContents(el);

    // Create a selection with the range
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea
    document.body.removeChild(el);
  };

  const extractWordCount = () => {
    const wordsSpan = Array.from(
      document.querySelectorAll(".jodit-status-bar__item-right span")
    ).find((span) =>
      span.textContent?.includes("Words:")
    ) as HTMLElement | null;

    if (wordsSpan) {
      const wordsText = wordsSpan.textContent || "";
      const wordsMatch = wordsText.match(/\d+/);

      if (wordsMatch && wordsMatch.length > 0) {
        const extractedWordCount = parseInt(wordsMatch[0], 10);
        setWordCount(extractedWordCount);
      }
    }
  };

  const facilityMergeFields = [
    "FacilityNumber",
    "FacilityName",
    "Address",
    "MapCategory",
    "Latitude",
    "Longitude",
    "ReceivingPlant",
    "TrunkLine",
    "SiteElevation",
  ];
  const inspectionMergeFields = [
    "InspectionCompleteDate",
    "InspectionEventType",
  ];
  const createOptionGroupElement = (
    mergeFields: any,
    optionGrouplabel: any
  ) => {
    let optionGroupElement = document.createElement("optgroup");
    optionGroupElement.setAttribute("label", optionGrouplabel);
    for (let index = 0; index < mergeFields.length; index++) {
      let optionElement = document.createElement("option");
      optionElement.setAttribute("class", "merge-field-select-option");
      optionElement.setAttribute("value", mergeFields[index]);
      optionElement.text = mergeFields[index];
      optionGroupElement.appendChild(optionElement);
    }
    return optionGroupElement;
  };

  const buttons = [
    "bold",
    "underline",
    "italic",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "paragraph",
    "undo",
    "redo",

    {
      name: "insertMergeField",
      tooltip: "Insert Merge Field",
      iconURL: "images/merge.png",
      popup: (editor: any, current: any, self: any, close: any) => {
        function onSelected(e: any) {
          let mergeField = e.target.value;
          if (mergeField) {
            console.log(mergeField);
            editor.selection.insertNode(
              editor.create.inside.fromHTML("{{" + mergeField + "}}")
            );
          }
        }
        let divElement = editor.create.div("merge-field-popup");

        let labelElement = document.createElement("label");
        labelElement.setAttribute("class", "merge-field-label");
        // labelElement.text = "Merge field: ";
        divElement.appendChild(labelElement);

        let selectElement = document.createElement("select");
        selectElement.setAttribute("class", "merge-field-select");
        selectElement.appendChild(
          createOptionGroupElement(facilityMergeFields, "Facility")
        );
        selectElement.appendChild(
          createOptionGroupElement(inspectionMergeFields, "Inspection")
        );
        selectElement.onchange = onSelected;
        divElement.appendChild(selectElement);

        console.log(divElement);
        return divElement;
      },
    },
    {
      name: "copyContent",
      tooltip: "Copy HTML to Clipboard",
      iconURL: "images/copy.png",
      exec: function (editor: any) {
        let html = editor.value;
        copyStringToClipboard(html);
      },
    },
  ];

  const handleBeforePaste = async (event: any) => {
    await extractWordCount();

    const data = (
      event.clipboardData || event.originalEvent.clipboardData
    ).getData("text/html");
    if (!data) return;
    const doc = new DOMParser().parseFromString(data, "text/html");
    const plainTextContent = doc.body.textContent || "";
    const wordRegex = /\b\w+(?:[-']\w+)?\b/g;
    const words = plainTextContent.match(wordRegex);
    const wordCount2 = words ? words.length : 0;
    console.log(words);
    const wordLimit = 3000;
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    let hasImage = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === 0) {
        hasImage = true;
        break;
      }
    }
    if (hasImage) {
      toast.warn("files paste not allowed");

      return false;
    }

    // If the word count exceeds the limit, display an toast.warn
    if (wordCount2 > wordLimit) {
      toast.warn(`Word limit exceeded (${wordLimit} words maximum).`);
      event.preventDefault(); // Prevent the default paste behavior
    }
    const images = doc.querySelectorAll("img");
    if (!images.length) return;

    toast.warn("files paste not allowed");
    setimgexists(true);
    return false;
  };

  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    limitWords: 3000,
    // toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    uploader: { url: "none" },
    processPasteHTML: true,
    //defaultActionOnPaste: "insert_clear_html",
    events: { beforePaste: handleBeforePaste },
    buttons: buttons,
    height: 850,
  };

  const shareStory = () => {
    if (navigator.share) {
      navigator
        .share({
          url: `${window.location.origin}/story/${storyID}/chapter/${chapterID}`,
        })
        .then(() => console.log("Story shared successfully!"))
        .catch((error) => console.error("Error sharing story:", error));
    } else {
      // If Web Share API is not supported, open a share dialog manually
      if (shareRef.current) {
        shareRef.current.click();
      }
    }
  };

  const saveContent = async () => {
    await extractWordCount();
    // Ensure wordCount is defined, use 0 as the default value
    const validWordCount = wordCount;

    if (wordCount < 50) {
      // If word count is less than 50, prevent form submission
      toast.warning("Chapter content must have a minimum of 50 words.");
      return;
    }
    // Save the edited content to the database
    if (validWordCount < 3001) {
      if (storyID && chapterID) {
        let editedContentWithClass = editedContent;
        if (editedContentWithClass) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(
            editedContentWithClass,
            "text/html"
          );

          // Add the class to all elements
          const allElements = doc.body.getElementsByTagName("*");
          for (let i = 0; i < allElements.length; i++) {
            const element = allElements[i] as HTMLElement;
            if (element.style) {
              element.style.color = ""; // Remove color property
            }
          }

          // Add the class to all elements
          for (let i = 0; i < allElements.length; i++) {
            allElements[i].classList.add("text-gray-900", "dark:text-gray-100");
          }

          editedContentWithClass = doc.body.innerHTML;
        }

        const chapterRef = ref(
          db,
          `allStories/${storyID}/chapters/${chapterID}`
        );
        await update(chapterRef, { content: editedContentWithClass });
        toast.success("Content saved successfully.");
        setIsEditing(false);
        // Switch back to read-only mode after saving
      } else {
        toast.warn("Invalid storyID or chapterID");
      }
    } else {
      toast.warn("Content must have a max. 3000 words.");
    }
  };

  if (!chapterContent) {
    return <div>Loading chapter content...</div>;
  }
  window.addEventListener("contextmenu", (e) => {
    e.preventDefault();
  });

  return (
    <div className="select-none w-full ">
      <ToastContainer />
      <div className="flex w-full ">
        <div className="flex  w-full justify-center lg:justify-end items-center gap-2">
          <div className=" font-semibold font-1 text-xs lg:text-lg text-gray-400">
            - By
          </div>
          {/* <div className='rounded-full  text-center h-8 w-8 m-1 text-xl mr-2  bg-blue-900 text-white'>{autherName?.slice(0,1).toUpperCase()}</div> */}

          <div className=" font-semibold font-1 text-xs lg:text-lg text-black dark:text-white">
            {" "}
            {autherName}
          </div>
        </div>
      </div>
      <div className="font-bold py-4 text-center text-xs lg:text-3xl font-2 text-black dark:text-white">
        - Chapter {chapterNum} -
      </div>
      <div className="w-full text-white flex lg:text-2xl justify-end lg:p-4">
        <div className="flex gap-2 px-2 lg:px-0">
          <div>
            <Like
              storyID={storyID}
              storyName={title}
              autherID={autherID}
              class="lg:text-2xl text-white "
            />
          </div>
          <div
            className="text-white cursor-pointer bg-blue-700 rounded-md p-1 flex gap-2"
            onClick={shareStory}
          >
            <div className="text-white font-semibold">Share</div>
            <div>
              <i className="fa-sharp fa-solid fa-share-alt "></i>
            </div>
          </div>
          <div
            className="text-white bg-blue-700 rounded-md p-1 cursor-pointer flex gap-2"
            onClick={() => navigate(`/report/${storyID}`)}
          >
            <div>Report</div>
            <div>
              <i className="fa-sharp fa-solid fa-flag "></i>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-300 w-full  h-[0.5px]  mt-3"></div>
      <div className="flex justify-between w-full py-5">
        <div className=" font-semibold px-2  lg:px-0   lg:text-2xl w-full text-center text-black dark:text-white">
          <span className="lg:ml-24">{chapterTitle}</span>
        </div>
        {user?.id === autherID && (
          <div className="font-bold ml-auto lg:text-lg lg:w-1/7 ">
            <div></div>

            {isEditing ? (
              <div>
                {imgexists ? (
                  <div
                    className="cursor-pointer text-gray-700 dark:text-gray-100 inline-flex gap-4 px-4"
                    onClick={() => window.location.reload()}
                  >
                    Reload
                  </div>
                ) : (
                  <button
                    disabled={imgexists}
                    className="cursor-pointer text-black dark:text-white inline-flex gap-4 "
                    onClick={saveContent}
                  >
                    <div>save</div>
                    <div className="">
                      <i className="fa-sharp fa-solid fa-floppy-disk "></i>
                    </div>
                  </button>
                )}
              </div>
            ) : (
              <div
                className="cursor-pointer text-gray-700 dark:text-gray-100 inline-flex gap-4 px-4"
                onClick={() => setIsEditing(true)}
              >
                <div>Edit</div>
                <div className="">
                  <i className="fa-sharp fa-solid fa-pen-to-square "></i>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {isEditing ? (
        <div className="text-gray-900 dark:text-gray-100  bg-white dark:bg-slate-800">
          <JoditEditor
            value={editedContent || ""}
            config={editorConfig}
            onBlur={async (newContent) => {
              await extractWordCount();
              handleContentChange(newContent);
            }}
            // onChange={async(newContent) =>{await extractWordCount(); handleContentChange(newContent)}}
            className={
              "shadow appearance-none border rounded w-full p-1 text-gray-900 dark:text-gray-900 bg-white dark:bg-slate-800 focus:outline-none focus:shadow-outline"
            }
          />
        </div>
      ) : (
        <div
          className="font-2 text-xs lg:text-lg text-gray-900 dark:text-gray-100  bg-white dark:bg-slate-800 overflow-x-hidden"
          dangerouslySetInnerHTML={{ __html: chapterContent }}
        />
      )}
    </div>
  );
};

export default ChapterFetch;
