import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/storeHook';
import { ref, set, get, push, runTransaction } from 'firebase/database';
import { db } from '../../firebase';
import { CommentForm } from '../../models/story/comment/CommentForm';
import { Comment } from '../../models/story/comment/Comment';
import Info from '../../pages/Story/Info';
import { toast } from 'react-toastify';

interface CommentwriteProps {
  storyID: string;
  autherID: string;
  storyName: string;
}

const Commentwrite: React.FC<CommentwriteProps> = ({ storyID, autherID, storyName }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [CommentStatus, setCommentStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    // Fetch the comments from the database and check if the user's email exists in the comments data
    const checkCommentStatus = async () => {
      if (user && user.email) {
        try {
          const commentRef = `allStories/${storyID}/comment/`;
          const snapshot = await get(ref(db, commentRef));
          
          const autherViewCountRef = ref(db, `authers/${autherID}/viewCount`);
          runTransaction(autherViewCountRef, (currentData) => {
            return (currentData || 0) + 1;
          });
          const commentsData: Record<string, CommentForm> = snapshot.val();

          if (commentsData) {
            const userHasCommented = Object.values(commentsData).some((comment) => comment.email === user.email);
            setCommentStatus(userHasCommented);
          } else {
            setCommentStatus(false);
          }
        } catch (error) {
          console.error('Error fetching comments:', error);
        }
      }
    };

    checkCommentStatus();
  }, [user, storyID]);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (user && user.email) {
        const currentUser = user.userData?.name
        const data: CommentForm = {
          email: user.email,
          name:currentUser || '',
          comment: e.currentTarget.comment.value,
          
        };
        
        const commentRef = `allStories/${storyID}/comment/`;
        const CommentAutherRef = `authers/${autherID}/storiesArchive/${storyID}/comment/`;
        const NotifyAutherRef = `authers/${autherID}/notification/`; // Use 'autherID' instead of 'user.id' for notification path
        const commonId = push(ref(db, commentRef)).key;
        const newCommentStoryPathRef = `${commentRef}${commonId}`;
        const newAutherStoryPathRef = `${CommentAutherRef}${commonId}`;
        const notification = `${NotifyAutherRef}${commonId}`;
        const autherCommentCountRef = ref(db, `authers/${autherID}/commentCount`);
        const summary2 = data.comment

        const wordCount = summary2?.split(/\s+/).filter(Boolean).length; 
        if (wordCount > 151 ) {
          // toast.warning(`Summary cannot exceed 200 words. current word Count ${wordCount}`);
          toast.warning(wordCount >151 && `Comment cannot exceed 150 words. current word Count ${wordCount}`);
          return;
        }
        runTransaction(autherCommentCountRef, (currentData) => {
          return (currentData || 0) + 1;
        });
        setLoading(true)
        await set(ref(db, newCommentStoryPathRef), data);
        await set(ref(db, newAutherStoryPathRef), data);

        // Use 'autherID' instead of 'user.id' for notification data
        await set(ref(db, notification), {
          userName: user.userData?.name,
          type: "comment",
          isread: false,
          storyTitle: storyName,
        });

        setSuccess(true);
        setLoading(false);
        setCommentStatus(true); // Set the CommentStatus to true as the user has now posted a comment
      }
    } catch (error: any) {
      setLoading(false);
      const errorCode = error.code;
      setErrorMessage(errorCode);
    }
  };

  return (
    <div className='w-full select-none'>
      {autherID === user?.id ? (
        <div className=''>
        </div>
      ) : (
        <>
          {CommentStatus ? (
            <div>
        <textarea name="" className='w-full h-36 resize-none my-4 p-3 rounded-lg border' placeholder="You already shared your thoughts!" disabled />

            </div>
          ) : (
            <form className='w-full flex flex-col'  onSubmit={handleFormSubmit}>
              <div className="w-full flex">

              <textarea name="comment" className='w-full h-36 bg-white dark:bg-slate-900 text-black dark:text-white resize-none my-4 p-3 rounded-lg border focus:border-gray-500 focus:outline-none' placeholder="Leave your thoughts" required />
              </div>
           <div className='mx-auto lg:ml-auto'>

              <button className={"bg-blue-900  text-white rounded-full py-1.5 px-4 text-lg hover:bg-blue-700 cursor-pointer" } disabled={loading} type="submit">{loading ? 'Submitting...' : 'Submit'}</button>
           </div>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default Commentwrite;