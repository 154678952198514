import React, { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook';
import { get, push, ref, set } from 'firebase/database';
import { db } from '../../firebase';
import { BookShelf } from '../../models/story/Bookshelf';
import { login } from '../../features/authSlice';
import { Tooltip } from 'react-tooltip';

interface AddtoBookshelfProps {
  storyID: string;
}

const AddtoBookshelf: React.FC<AddtoBookshelfProps> = ({ storyID }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { user } = useAppSelector((state) => state.auth);
  const bookshelfRef = user ? `users/${user.id}/bookshelf` : null;
  const dispatch = useAppDispatch();

  const checkLikeStatus = useCallback(async () => {
    if (!bookshelfRef) return;

    try {
      const snapshot = await get(ref(db, bookshelfRef));
      const bookshelfData: Record<string, BookShelf> = snapshot.val();
      if (bookshelfData) {
        setSuccess(Object.values(bookshelfData).some((item) => item.bookid === storyID));
      } else {
        setSuccess(false);
      }
    } catch (error: any) {
      setSuccess(false);
      console.error(error);
    }
  }, [bookshelfRef, storyID]);

  useEffect(() => {
    checkLikeStatus();
  }, [checkLikeStatus]);

  const handleFormSubmit = async (e:any) => {
    e.preventDefault();

    if (!bookshelfRef) return;

    setLoading(true);

    try {
      const snapshot = await get(ref(db, bookshelfRef));
      const bookshelfData: Record<string, BookShelf> = snapshot.val();

      const storyIdToDelete = Object.keys(bookshelfData || {}).find(
        (key) => bookshelfData[key].bookid === storyID
      );

      if (storyIdToDelete) {
        await set(ref(db, `${bookshelfRef}/${storyIdToDelete}`), null);
      } else {
        const data: BookShelf = { bookid: storyID };
        await set(push(ref(db, bookshelfRef)), data);
      }

      const updatedUserData = {
        ...user,
        bookshelf: bookshelfData ? { ...bookshelfData } : {},
      };

      // await dispatch(login(updatedUserData));

      setSuccess(!storyIdToDelete);
setLoading(false)
      // Use a more user-friendly notification system here
      // alert(storyIdToDelete ? 'Removed from Bookshelf' : 'Added to Bookshelf');
    } catch (error: any) {
      console.error(error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      
        <div onClick={handleFormSubmit} className="font-bold bg-blue-900 p-3 my-anchor-element3 mt-4 lg:mt-0 text-md rounded-md hover:bg-transparent hover:border-[1px] hover:border-solid hover:border-blue-900 select-none cursor-pointer">
          {success ? 
          
          <div className="flex gap-2 "
        // data-tooltip-id="my-tooltip"
        // data-tooltip-content={"Bookshelf your favourites. "}
        // data-tooltip-place="top"
          >
           <div>
             <i className="fa-sharp fa-solid fa-bookmark "></i>
           </div>
           <div>Remove to your BookShelf</div>
         </div> : <div className="flex gap-2"
        // data-tooltip-id="my-tooltip"
        // data-tooltip-content={"Bookshelf your favourites. "}
        // data-tooltip-place="top"
        >
           <div>
             <i className="far fa-bookmark"></i>
           </div>
           <div>Add to your BookShelf</div>
         </div>}
        </div>
        <Tooltip  anchorSelect=".my-anchor-element3" className=''  place="top" style={{ backgroundColor: 'white', color: 'black' }}>
        <div style={{ whiteSpace: "normal" }}>{"Bookshelf your favourites. "}</div>
      </Tooltip>
        
    </div>
  );
};

export default AddtoBookshelf;