import React, { FC } from "react";

interface ResetPasswordProps {
  isOpen: boolean;
  onClose: () => void;
  handlePasswordReset: () => Promise<void>;
  resetPasswordEmail: string;
  resetPasswordSuccess: string | null;
  resetPasswordError: string | null;
  setResetPasswordEmail: (value: string) => void;
}

const ResetPassword: FC<ResetPasswordProps> = (props) => {
  const {
    isOpen,
    onClose,
    handlePasswordReset,
    resetPasswordEmail,
    resetPasswordSuccess,
    resetPasswordError,
    setResetPasswordEmail,
  } = props;

  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 z-10 flex justify-center items-center bg-black bg-opacity-90`}
      style={{ transform: `translateY(${isOpen ? "0%" : "-100%"})` }}
    >
      <div className="bg-[#002e7266] rounded-lg px-8 py-4 w-96 text-center">
        <div className="text-start flex mb-4">
<div>
      <button
          onClick={onClose}
          className="text-gray-500 mt-4 text-sm underline hover:text-gray-700"
          >
         <svg className="h-3" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 34.075 34.075" xmlSpace="preserve">
<g>
	<g>
		<path style={{fill:"#ffffff"}} d="M24.57,34.075c-0.505,0-1.011-0.191-1.396-0.577L8.11,18.432c-0.771-0.771-0.771-2.019,0-2.79
			L23.174,0.578c0.771-0.771,2.02-0.771,2.791,0s0.771,2.02,0,2.79l-13.67,13.669l13.67,13.669c0.771,0.771,0.771,2.021,0,2.792
			C25.58,33.883,25.075,34.075,24.57,34.075z"/>
	</g>
</g>
</svg>
        </button>
        </div>

        <div className="text-xl font-1 mt-3 ml-16 text-white">Password Reset</div>
          </div>

        <input
          type="email"
          value={resetPasswordEmail}
          onChange={(e) => setResetPasswordEmail(e.target.value)}
          placeholder="Email"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg mb-4"
        />
        <button
          onClick={handlePasswordReset}
          className="w-full bg-blue-900 text-white py-2 px-4 rounded-lg"
        >
          Reset Password
        </button>
        {resetPasswordSuccess ? (
          <p className="text-green-500 mt-2">{resetPasswordSuccess}</p>
        ) : null}
        {resetPasswordError ? (
          <p className="text-red-500 mt-2">{resetPasswordError}</p>
        ) : null}
        
      </div>
    </div>
  );
};

export default ResetPassword;
