import React from 'react';

interface AgeVerificationModalProps {
  onClose: () => void;
}

const AgeVerificationModal: React.FC<AgeVerificationModalProps> = ({ onClose }) => {
  
  return (
    <div className="flex select-none fixed top-0 right-0 z-50 justify-center items-center content-center w-full h-full p-4 overflow-x-hidden overflow-y-scroll bg-black bg-opacity-70 md:inset-0 md:h-full ">

 
    <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-opacity-900 backdrop-filter backdrop-blur-lg">
        <div className='bg-white px-10 pb-4 rounded-lg overflow-y-auto'>
            <div className='text-9xl text-yellow-500 text-center'>
            <i className="fa-solid fa-triangle-exclamation"></i>
            </div>

        <div  className="block text-gray-700 text-lg font-bold mt-4 font-1">The content available on Likhasi may contain Matured materials.

Likhasi is strictly limited to those over 18 or of legal age in your jurisdiction, whichever is greater.

One of our core goals is to help parents restrict access to Likhasi for minors, so we have ensured that Likhasi is, and remains, fully compliant with the RTA (Restricted to Adults) code. This means that all access to the site can be blocked by simple parental control tools. It is important that responsible parents and guardians take the necessary steps to prevent minors from accessing unsuitable content online, especially age-restricted content.

Anyone with a minor in their household or under their supervision should implement basic parental control protections, including computer hardware and device settings, software installation, or ISP filtering services, to block your minors from accessing inappropriate content. </div>
        
        <div className="flex justify-center mt-8">

        <button  className={" rounded-md bg-blue-900 text-white px-4 py-2.5"} onClick={onClose}>Ok</button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default AgeVerificationModal;