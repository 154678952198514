import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ref, get, set, runTransaction } from "firebase/database";
import { db } from "../../firebase";
import { useAppSelector } from "../../hooks/storeHook";

interface ChapterData {
  chapterId: string;
  chapterNo: number;
}

interface ChapterPagingProps {
  storyId: string;
  autherId: string;
}

const ChapterPaging: React.FC<ChapterPagingProps> = ({storyId, autherId }) => {
  const [chapters, setChapters] = useState<ChapterData[]>([]);
  const { user } = useAppSelector((state) => state.auth);
  const [currentChapterIndex, setCurrentChapterIndex] = useState<number>(0);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const chaptersRef = ref(db, `allStories/${storyId}/chapters`);
        const snapshot = await get(chaptersRef);
        const chaptersData = snapshot.val();

        if (chaptersData) {
          const chaptersArray: ChapterData[] = Object.keys(chaptersData).map((chapterId) => ({
            chapterId,
            chapterNo: chaptersData[chapterId].chapterNo,
          }));
          setChapters(chaptersArray);
        }
      } catch (error) {
        console.error("Error fetching chapters:", error);
      }
    };

    fetchChapters();
  }, [storyId]);

  const handleChapterClick = async (chapterId: string, index: number) => {
    if (user) {
      try {
        const userBookmarksRef = ref(db, `users/${user.id}/bookmarks`);
        const snapshot = await get(userBookmarksRef);
        const existingBookmarks = snapshot.val() || {};

        const newBookmark = { [storyId]: chapterId };

        const updatedBookmarks = { ...existingBookmarks, ...newBookmark };

        await set(userBookmarksRef, updatedBookmarks);

        // Increment the total view count for the story
        const viewCountRef = ref(db, `allStories/${storyId}/viewCount`);
        const autherViewCountRef = ref(db, `authers/${autherId}/viewCount`);
        runTransaction(viewCountRef, (currentData) => {
          return (currentData || 0) + 1;
        });
        runTransaction(autherViewCountRef, (currentData) => {
          return (currentData || 0) + 1;
        });
        setCurrentChapterIndex(index)
        window.scrollTo({top:0,  })
        // alert("Bookmark added successfully!");
      } catch (error) {
        console.error("Error adding bookmark:", error);
      }
    } else {
      console.log("User not logged in. Cannot add bookmark.");
    }
  };

  const handleNextChapter = () => {
    if (currentChapterIndex < chapters.length - 1) {
      const nextChapterIndex = currentChapterIndex + 1;
      const nextChapterId = chapters[nextChapterIndex].chapterId;
      setCurrentChapterIndex(nextChapterIndex);
      handleChapterClick(nextChapterId, nextChapterIndex);
       window.scrollTo({top:0,  })
      navigate(`/story/${storyId}/chapter/${nextChapterId}`);
    
    }
  };
  
  const handlePrevChapter = () => {
    if (currentChapterIndex > 0) {
      const prevChapterIndex = currentChapterIndex - 1;
      const prevChapterId = chapters[prevChapterIndex].chapterId;
      setCurrentChapterIndex(prevChapterIndex);
      
      handleChapterClick(prevChapterId, prevChapterIndex);
       window.scrollTo({top:0,  })
      navigate(`/story/${storyId}/chapter/${prevChapterId}`);
     
    }
  };

  return (
    <div className="select-none">
      <div className="flex items-center justify-center  text-xs lg:text-lg mt-4 px-2 lg:mt-0 lg:px-0">
        <div className="lg:w-3/5 w-full  flex items-center justify-between border-t pt-8 border-gray-200">
          <button className="flex bg-blue-900 w-24 mt-2 lg:mt-0 lg:w-40 rounded-md items-center justify-between px-3 py-1.5 text-gray-100 hover:text-indigo-700 cursor-pointer" onClick={handlePrevChapter} disabled={currentChapterIndex === 0}>
            <svg className={`h-2 lg:h-3 ${currentChapterIndex === 0 ? 'text-gray-300' : 'text-current'}`} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.1665 4H12.8332" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M1.1665 4L4.49984 7.33333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M1.1665 4.00002L4.49984 0.666687" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p className={`text-xs lg:text-lg font-medium leading-none ml-3 ${currentChapterIndex === 0 ? 'text-gray-300' : 'text-current'}`}>Previous</p>
          </button>
          <div className="flex items-center">
        {chapters.map((chapter, index) => (
          <div
            className="pt-2"
            onClick={() => {
              handleChapterClick(chapter.chapterId, index); // Pass the index to the handler
              navigate(`/story/${storyId}/chapter/${chapter.chapterId}`);
            }}
            key={chapter.chapterId}
          >
            <p
              className={`text-xs lg:text-lg font-medium leading-none cursor-pointer ${
                index === currentChapterIndex
                  ? "text-white bg-blue-900 "
                  : "text-gray-600"
              } border-t border-transparent hover:text-indigo-400 rounded-md text-sm mr-2 px-2 py-1.5`}
            >
              {chapter.chapterNo}
            </p>
          </div>
        ))}
      </div>
          <button className="bg-blue-900 w-24 mt-2 lg:mt-0 lg:w-40 rounded-md flex justify-between items-center px-3 py-1.5 text-gray-100 hover:text-indigo-700 cursor-pointer" onClick={handleNextChapter} disabled={currentChapterIndex === chapters.length - 1}>
            <p className={`text-xs lg:text-lg font-medium leading-none mr-3 ${currentChapterIndex === chapters.length - 1 ? 'text-gray-300' : 'text-current'}`}>Next</p>
            <svg className={`h-2 lg:h-3 ${currentChapterIndex === chapters.length - 1 ? 'text-gray-300' : 'text-current'}`} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.1665 4H12.8332" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.5 7.33333L12.8333 4" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.5 0.666687L12.8333 4.00002" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChapterPaging;