import React, { useState, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ref, set, get, push, update } from 'firebase/database';
import { auth, db } from '../../firebase';
import { useAppSelector } from '../../hooks/storeHook';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { authClasses } from '../Auth/authClasses';
import { ChapterForm, chapterFormSchema } from '../../models/story/chapter/ChapterForm';
import { ToastContainer, toast } from 'react-toastify';
import Footer from '../../components/Footer';
import Loading from '../Home/Loading';
import Preview from './StoryPreview';
import JoditEditor from "jodit-react"; // Import JoditEditor

// import '../../../node_modules/jodit-react/build/';
const ChapterAdd = () => {
  const { storyId } = useParams();
  const {
    container,
    form,
    button,
    input,
    text,
    link,
    hr,
    forgotPasswordButton,
  } = authClasses;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedChapters, setSubmittedChapters] = useState<number[]>([]);
  const [chapterOptions, setChapterOptions] = useState<{ value: number; disabled: boolean }[]>([]);
  const [selectedChapter, setSelectedChapter] = useState<number | null>(null);
  const [formDataFinal, setformDataFinal] = useState<any>()
  const navigate = useNavigate();
  const [imgexists, setimgexists] = useState(false)
  const userDetails = useAppSelector((state) => state.auth.user?.userData);
  
  const [showConfirmation, setShowConfirmation] = useState(false);
  useEffect(() => {
    const user = auth.currentUser;
    if (!user?.emailVerified) {
      navigate(`/verification-pending/${user?.uid}`);
   
    }else
    { if(user && !userDetails) {
         navigate(`/credform/${user?.uid}`);
       }
      }
    
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event:any) => {
      const message = 'Are you sure you want to leave? Your changes may not be saved.';
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  
  const { auther } = useAppSelector((state) => state.auther);
  const { user } = useAppSelector((state) => state.auth);

  // Function to fetch submitted chapters from the database
  const loadSubmittedChapters = async () => {
    try {
      const chaptersRef = ref(db, `allStories/${storyId}/chapters`);
      const chaptersSnapshot = await get(chaptersRef);
      const chapters = chaptersSnapshot.val();
  
      if (chapters) {
        const submittedChapterNumbers = Object.values(chapters).map((chapter: any) => chapter.chapterNo);
        setSubmittedChapters(submittedChapterNumbers.filter(Number.isFinite));
      }
    } catch (error) {
      console.error('Error loading submitted chapters:', error);
    }
  };

  useEffect(() => {
    loadSubmittedChapters();
  }, [storyId]);


  const copyStringToClipboard = function (str:any) {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
  
    document.body.appendChild(el);
  
    // Select text inside the textarea
    el.select();
  
    // Create a range and select the text
    const range = document.createRange();
    range.selectNodeContents(el);
  
    // Create a selection with the range
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  
    // Execute the copy command
    document.execCommand("copy");
  
    // Remove the textarea
    document.body.removeChild(el);
  };
  
  const facilityMergeFields = [
    "FacilityNumber",
    "FacilityName",
    "Address",
    "MapCategory",
    "Latitude",
    "Longitude",
    "ReceivingPlant",
    "TrunkLine",
    "SiteElevation",
  ];
  const inspectionMergeFields = ["InspectionCompleteDate", "InspectionEventType"];
  const createOptionGroupElement = (mergeFields :any, optionGrouplabel:any) => {
    let optionGroupElement = document.createElement("optgroup");
    optionGroupElement.setAttribute("label", optionGrouplabel);
    for (let index = 0; index < mergeFields.length; index++) {
      let optionElement = document.createElement("option");
      optionElement.setAttribute("class", "merge-field-select-option");
      optionElement.setAttribute("value", mergeFields[index]);
      optionElement.text = mergeFields[index];
      optionGroupElement.appendChild(optionElement);
    }
    return optionGroupElement;
  };

  const buttons = [
    "bold",
    "underline",
    "italic",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "paragraph",
    "undo",
    "redo",
    
    {
      name: "insertMergeField",
      tooltip: "Insert Merge Field",
      iconURL: "images/merge.png",
      popup: (editor:any, current:any, self:any, close:any) => {
        function onSelected(e:any) {
          let mergeField = e.target.value;
          if (mergeField) {
            console.log(mergeField);
            editor.selection.insertNode(
              editor.create.inside.fromHTML("{{" + mergeField + "}}")
            );
          }
        }
        let divElement = editor.create.div("merge-field-popup");
  
        let labelElement = document.createElement("label");
        labelElement.setAttribute("class", "merge-field-label");
        // labelElement.text = "Merge field: ";
        divElement.appendChild(labelElement);
  
        let selectElement = document.createElement("select");
        selectElement.setAttribute("class", "merge-field-select");
        selectElement.appendChild(
          createOptionGroupElement(facilityMergeFields, "Facility")
        );
        selectElement.appendChild(
          createOptionGroupElement(inspectionMergeFields, "Inspection")
        );
        selectElement.onchange = onSelected;
        divElement.appendChild(selectElement);
  
        console.log(divElement);
        return divElement;
      },
    },
    {
      name: "copyContent",
      tooltip: "Copy HTML to Clipboard",
      iconURL: "images/copy.png",
      exec: function (editor:any) {
        let html = editor.value;
        copyStringToClipboard(html);
      },
    },
  ];
  
  const handleBeforePaste =async (event:any) => {
    await extractWordCount();
    
    const data = (
      event.clipboardData || event.originalEvent.clipboardData
    ).getData('text/html');
    if (!data) return;
    const doc = new DOMParser().parseFromString(data, 'text/html');
    const plainTextContent = doc.body.textContent || '';
    const wordRegex = /\b\w+(?:[-']\w+)?\b/g;
    const words = plainTextContent.match(wordRegex);
    const wordCount2 = words ? words.length : 0;
    console.log(words)
    const wordLimit = 3000;
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    let hasImage = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === 0) {
        hasImage = true;
        break;
      }
    }
    if (hasImage) {
      toast.warn('files paste not allowed')
      
      return false;
    }
    
    // If the word count exceeds the limit, display an toast.warn
    if (wordCount2 > wordLimit) {
      toast.warn(`Word limit exceeded (${wordLimit} words maximum).`);
      event.preventDefault(); // Prevent the default paste behavior
    }
    const images = doc.querySelectorAll('img');
    if (!images.length) return;
    
    toast.warn('files paste not allowed')
    setimgexists(true)
    return false;
  };

  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    limitWords: 3000,
    // toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    uploader: { url: 'none' },
     processPasteHTML: true,
    //defaultActionOnPaste: "insert_clear_html",
    events:{ beforePaste: handleBeforePaste},
    buttons: buttons,
    height: 850,
    

  };
  
  // Update the chapterOptions state whenever submittedChapters is updated
  useEffect(() => {
    // console.log('Submitted chapters:', submittedChapters);
    const options = Array.from({ length: 15 }, (_, index) => index + 1);
    const disabledOptions = submittedChapters;
    // console.log('Disabled options:', disabledOptions);
    setChapterOptions(options.map(chapterNumber => ({
      value: chapterNumber,
      disabled: disabledOptions.includes(chapterNumber),
    })));
  }, [submittedChapters]);


  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  // const timestamp = Date()
  // const onSubmit = (data: ChapterForm) => {
  //   const { title, chapterNo, content, timestamp } = data;
  // };

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<ChapterForm>({
    resolver: yupResolver(chapterFormSchema),
  });

  const [wordCount, setWordCount] = useState(0);


  const validatetitle = (value: string) => {
    const wordCount = value.split(/\s+/).filter(Boolean).length; // Count words by splitting on spaces
  
    if (wordCount > 15) {
      toast.warning('Title cannot exceed 15 words.');
      return false; // Return false to indicate validation failure and prevent form submission
    }
  
    return true; // Return true to indicate validation success
  };

  const extractWordCount = () => {
    const wordsSpan = Array.from(
      document.querySelectorAll('.jodit-status-bar__item-right span')
    ).find((span) => span.textContent?.includes('Words:')) as HTMLElement | null;
  
    if (wordsSpan) {
      const wordsText = wordsSpan.textContent || '';
      const wordsMatch = wordsText.match(/\d+/);
  
      if (wordsMatch && wordsMatch.length > 0) {
        const extractedWordCount = parseInt(wordsMatch[0], 10);
        setWordCount(extractedWordCount);
      }
    }
  };
  

  const handleFormSubmit: SubmitHandler<ChapterForm> = async (data: ChapterForm) => {

    await extractWordCount();
    
    setIsSubmitting(true)
    try {
    
      if (wordCount < 50) {
        // If word count is less than 50, prevent form submission
        toast.warning('Chapter content must have a minimum of 50 words.');
        setIsSubmitting(false)
        return;
      } else if (wordCount > 3001) {
        toast.warning('Word limit exceeded (3000 words maximum).');
        setIsSubmitting(false)
        return;
      }
else{
        const titleWordCount = data.title.split(/\s+/).filter(Boolean).length;
        if (titleWordCount > 15) {
          setLoading(false);

          toast.warning('Title cannot exceed 15 words.');
          setIsSubmitting(false); 
          return
        } else {
        setIsSubmitting(true);   
        if (data.chapterNo === 16 && data.chapterNo <= 16) {
          console.log("Error: Cannot post Chapter 16");
        } else {
          if (submittedChapters.includes(data.chapterNo)) {
            toast.warning('Chapter already exists. Please select a different chapter number.');
            setLoading(false);
            setIsSubmitting(false); 
            return;
          }else{
            const parser = new DOMParser();
            const doc = parser.parseFromString(data.content, 'text/html');

            const allElements = doc.body.getElementsByTagName('*');
            for (let i = 0; i < allElements.length; i++) {
              const element = allElements[i] as HTMLElement;
              if (element.style) {
                element.style.color = ''; // Remove color property
              }
            }
            for (let i = 0; i < allElements.length; i++) {
              allElements[i].classList.add('text-gray-900', 'dark:text-gray-100');
            }
            const sanitizedContent = doc.body.innerHTML;
            data.content = sanitizedContent;
            setformDataFinal(data)
            setLoading(false);
            setSuccess(true);
            setIsSubmitting(false); 
            setShowConfirmation(true)  
            
          }
          }
        }
      }
      } catch (error: any) {
        setLoading(false);
        const errorCode = error.code;
        setErrorMessage(errorCode);
      }
    
    // onSubmit(data);
  };
  
  

  return (
    <>
     {showConfirmation && (
        <Preview
          formData={formDataFinal}
          storyID={storyId || ""}
          onCancel={() => setShowConfirmation(false)}
          message={"Are you sure you want to proceed? Once you move to the next page, the information entered on the previous page cannot be changed or edited. Please review all details carefully before proceeding."}
        />
      )}
    
      {
      loading?(<><Loading/></>):
    ( 
      <div className={`flex lg:p-24 overflow-y-hidden bg-white dark:bg-slate-800 select-none`}>
        <div className='shadow-2xl w-full p-3 lg:p-10'>
<div className="text-3xl font-bold text-center text-gray-900 dark:text-gray-100">- STEP THREE -</div>
<div className="px-8">

        <div className="h-[0.5px] bg-gray-300 w-full mt-4 "></div>
</div>
  <div className="text-sm font-bold text-start mt-4 text-gray-900 dark:text-gray-100">Please note:  the  Copy-Paste function may not work well with encrypted files.</div>
        <form  onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="lg:flex gap-4 mt-4">

          <div className='w-full'>
            <label htmlFor="title" className="block text-gray-700 dark:text-gray-100 text-lg font-bold mb-2 font-1">Title</label>
            <input type="text" className={"shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700  focus:outline-none focus:shadow-outline"} id="title" {...register('title'
          ,{ validate: validatetitle }
          
          )} required 
          />
            {errors.title && <span>{errors.title.message}</span>}
          </div>
            

          <div className='w-full'>
            <label htmlFor='chapterNo' className="block text-gray-700 dark:text-gray-100 text-lg font-bold mb-2 font-1 mt-4 lg:mt-0">Chapter Number</label>
            <select required className={"shadow appearance-non border text-center text-xl rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"} {...register('chapterNo')} >
              
            <option className='text-xs' value="">Select Chapter No.</option>
              {chapterOptions.map((option) => (
                <option key={option.value}  className={option.disabled ? 'bg-red' : ''} value={option.value} disabled={option.disabled}>
                  {option.value}
                </option>
              ))}
            </select>
          </div>
          </div>

          

          <label htmlFor="content" className="block text-gray-700 dark:text-gray-100 text-lg font-bold mb-2  mt-4  font-1">Content</label>
          {/* <Controller
                name="content"
                control={control}
                defaultValue=""
                render={({ field }) => ( */}
                <div className='text-gray-900'>

                  <JoditEditor
                className={"shadow appearance-none border rounded w-full p-1 text-gray-700  focus:outline-none focus:shadow-outline"}
                value={getValues('content') || ''}
                onBlur={(content) => {
                  extractWordCount()
                  setValue('content', content); // Manually set the value to the form state
                }}
                
                config={editorConfig
                
                }
                />
                {/* // config={
                  askBeforePasteHTML: true,
                  {
                  buttons: ["bold", "italic", "underline", "ul", "ol", "link", "image"],
                  defaultActionOnPaste:"insert_as_html",
                  
                  // defaultActionOnPasteFromWord: 'insert_as_html',
                  // askBeforePasteFromWord: false,
                  events: {
                    paste: () => {}
                  },
                  
                }
              } */}
                {/* )}
              /> */}
          {/* {errors.content && <span>{errors.content.message}</span>} */}

              </div>
          <div className="flex justify-center mt-4">
            {imgexists ? 
          <button
  className={` rounded-md ${imgexists ? "bg-slate-700 cursor-pointer": "bg-blue-900"} text-white px-4 py-2.5`}
  type="submit"
  onClick={()=>window.location.reload()}
>
  
Reload
</button>:
 <button
 className={` rounded-md ${imgexists ? "bg-slate-700 cursor-not-allowed": "bg-blue-900"} text-white px-4 py-2.5`}
 disabled={isSubmitting===true || imgexists}
 type="submit"
 onClick={()=>handleFormSubmit}
>
 
{isSubmitting ? 'Submitting...' : 'Preview'}
</button>
            }
</div>
        </form>
        </div>
      </div>)}
      <Footer/>
    </>
  );
};

export default ChapterAdd;