import React from 'react';

interface DarkModeToggleProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
  customeClass:string
}

const DarkModeToggle: React.FC<DarkModeToggleProps> = ({ darkMode, toggleDarkMode,customeClass }) => {
  return (
    <div>
      
      <button onClick={toggleDarkMode} className='text-black dark:text-white '>
        
        {darkMode ? 
        <div className='flex gap-2 ' >
         <div className=''>
          <i className="fa-sharp fa-solid  fa-sun text-yellow-300 opacity-80 hover:opacity-100"></i>
          </div>
<div className={`text-md ${customeClass}`}>

       Light Mode
</div>
        
        
        </div>
          :
        <div className='flex gap-2 '>
          <div>
          <i className="fa-sharp fa-solid  fa-moon opacity-80 hover:opacity-100 text-black"></i>
          </div>
          <div className='text-md ml-1.5 lg:ml-0'>

          Dark Mode
</div>
        </div>
         }
        
        </button>
    </div>
  );
};

export default DarkModeToggle;