import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/storeHook';
import { LikeForm } from '../../models/story/Like/LikeForm';
import { ref, set, get, push, runTransaction } from 'firebase/database';
import { db } from '../../firebase';
import { toast } from 'react-toastify';

interface Like2Props {
  storyID?: string;
  autherID?: string;
  storyName?: string;
  class?: string;
}

const Like2: React.FC<Like2Props> = ({ class: customClass, storyID, autherID, storyName }) => {
  const [loading, setLoading] = useState(false);
  const [likeStatus, setLikeStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const checkLikeStatus = async () => {
      if (user && user.email) {
        const likeRef = `allStories/${storyID}/Likes/${user?.id}`;
        const snapshot = await get(ref(db, likeRef));
        const likesData: Record<string, string> = snapshot.val();
        if (likesData===null) {
          setLikeStatus(false);
          console.log(likeStatus)
        } else {
          setLikeStatus(true);
        }
      }
    };

    checkLikeStatus();
  }, [user, storyID]);

  const handleFormSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
setLoading(true)
    try {
      if (user && user.email) {
        const data: LikeForm = {
          email: user.email
        };

        const likeRef = `allStories/${storyID}/Likes/`;
        const likeAutherRef = `authers/${autherID}/storiesArchive/${storyID}/Likes/`;
        const likeNotifyAutherRef = `authers/${autherID}/notification/`;
        const commonId = user?.id;
        const newLikeStoryPathRef = `${likeRef}${commonId}`;
        const newAutherLikePathRef = `${likeAutherRef}${commonId}`;
        const notification = `${likeNotifyAutherRef}${commonId}`;
        const autherViewCountRef = ref(db, `authers/${autherID}/viewCount`);
        const autherLikeCountRef = ref(db, `authers/${autherID}/likeCount`);

        runTransaction(autherLikeCountRef, (currentData) => {
          return (currentData || 0) + 1;
        });

        runTransaction(autherViewCountRef, (currentData) => {
          return (currentData || 0) + 1;
        });

        await set(ref(db, newLikeStoryPathRef), data);
        await set(ref(db, newAutherLikePathRef), data);
        await set(ref(db, notification), {
          userName: user.userData?.name,
          type: 'like',
          isread: false,
          storyTitle: storyName
        });

        setLikeStatus(true);
        // alert('Success');
        toast.success("Thank you for your support")
        setLoading(false);
      }
    } catch (error:any) {
      setLoading(false);
      // const errorCode = error.code;
      setErrorMessage(error);
    }
  };

  return (
    <div className='select-none'>
      {/* <i className="fa-sharp fa-solid fa-thumbs-up "></i> */}
      {/* <i onClick={handleFormSubmit} className={`far fa-thumbs-up cursor-pointer`}></i> */}
      {user?.id === autherID ? (
       <div className="text-white cursor-pointer bg-gray-700 rounded-md p-3 flex gap-2 w-full">
       <div>
       
             <i className="fa-sharp fa-solid fa-thumbs-up "></i>
       </div>
       <div className="text-white font-semibold text-md">
       Like
       </div>
       </div>
      ) : (
        <>
          {likeStatus ? (
            <div className={"text-white cursor-pointer bg-gray-700 rounded-md p-3 flex gap-2 w-full"} >
            <div>
            
                  <i className="fa-sharp fa-solid fa-thumbs-up "></i>
            </div>
            <div className="text-white font-semibold text-md">
            Liked
            </div>
            </div>
          ) : (
            <button disabled={loading} className="text-white cursor-pointer bg-blue-900 rounded-md p-3 w-full flex gap-2" onClick={handleFormSubmit}>
<div>

      <i className="fa-sharp fa-solid fa-thumbs-up "></i>
</div>
<div className="text-white font-semibold text-md">
Like
</div>
</button>
          )}
        </>
      )}
    </div>
  );
};

export default Like2;