import React from 'react';
import { Tooltip } from 'react-tooltip';
import { isWhiteSpaceLike } from 'typescript';

const Info = (props: { props: string }) => {
  const tooltipContentStyle = {
    whiteSpace: 'normal', // Set white-space to normal for text wrapping
  };

  return (
    <div className='select-none'>
      <Tooltip anchorSelect=".my-anchor-element" place="top">
        <div>{props.props}</div>
      </Tooltip>
      <div className="my-anchor-element mt-[2px] text "
      // data-tooltip-id="my-tooltip"
      // data-tooltip-content={props.props}
      // data-tooltip-place="top"
      >
        
        <i className="fa-solid fa-circle-info text-gray-900 dark:text-gray-100"></i>
      </div>
    </div>
  );
};

export default Info;
