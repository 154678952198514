import React, { useEffect } from 'react'
import StoryCard from '../../components/Story/StoryCard'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import StoryCard2 from '../../components/Story/StoryCodeviewall';
import { useAppSelector } from '../../hooks/storeHook';
import { auth } from '../../firebase';

const ViewAll = () => {
    
    const { typeOfStory } = useParams();
    const userDetails = useAppSelector((state) => state.auth.user?.userData);
    const navigate = useNavigate()
    useEffect(() => {
      const user = auth.currentUser;
      if (!user?.emailVerified) {
        navigate(`/verification-pending/${user?.uid}`);
     
      }else
      { if(user && !userDetails) {
           navigate(`/credform/${user?.uid}`);
         }
        }
      
    }, []);
    return (
      <div className='select-none'>
        {/* <Header /> */}
        <StoryCard2 storyType={typeOfStory||''} />
      </div>
    );
}

export default ViewAll
