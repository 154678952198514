import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { ref, push, get, set } from 'firebase/database';
import { useAppSelector } from '../../hooks/storeHook';
import { toast } from 'react-toastify';

interface IsExclusiveProps {
  isExclusive: boolean;
  storyId: string;
  authorEmail: string;
  autherId: string;
  storytitle:string
}

const IsExclusiveForm: React.FC<IsExclusiveProps> = ({ isExclusive, storyId, authorEmail, autherId, storytitle }) => {
  const [loading, setLoading] = useState(false);
  const [reqestStatus, setreqestStatus] = useState(false);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const checkExlcusiveStatus = async () => {

      if (user && user.email) {
        setLoading(true)
        const exclusiveRef = ref(db, `Exclusive/${storyId}`);
        const snapshot = await get(exclusiveRef);
        const ExlcusiveData: Record<string, any> = snapshot.val();

        if (ExlcusiveData) {
          Object.values(ExlcusiveData).some((exclusive) => exclusive.autheremail === user.email);
          setreqestStatus(true);
        }
        setLoading(false); // Set loading state to false after fetching exclusive status
      }
    };

    checkExlcusiveStatus();
  }, [user, storyId]);

  const handleRequest = async (e:any) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true while submitting the form

    try {
      if (user && user.email) {
        const data = {
          autheremail: user.email,
          storyid: storyId,
          title:storytitle,
          autherId:autherId
        };

        const commentRef = ref(db, `Exclusive/${storyId}`);
        await set(commentRef, data);

        setreqestStatus(true);
        toast.success("Request sent successfully") // Set the reqestStatus to true as the user has now requested exclusivity
        setLoading(false); // Set loading state to false after successful form submission
      }
    } catch (error: any) {
      setLoading(false); // Set loading state to false in case of an error during form submission
    }
  };

  return (
    <div className='select-none'>
      {loading ? 
      (
        <p>Loading...</p>
      ) :
      <>
      { authorEmail === user?.email ? (
        <>
        {
            isExclusive ? <p></p> : 
            <>
            {
             reqestStatus ?    <div className="bg-blue-700 text-white rounded-md py-1.5 px-4 text-lg hover:bg-blue-900 text-center cursor-pointer" >Request sent</div>  :
             <div className="bg-blue-700 text-white rounded-md py-1.5 px-4 text-lg hover:bg-blue-900 cursor-pointer" onClick={handleRequest}>
          <div>Make it a Likhasi Exclusive </div>
        </div>
            }
            </>
        }
        </>
      ):<></>}
      </>}
      
    </div>
  );
};

export default IsExclusiveForm;