import real from "./RLE.png";
import horror from "./horror.png";
import mystory from "./mystery.png";
import romance from "./romance.png";
import thriller from "./thriller.png";


export const categoryBg = {
    real,
    horror,
    mystory,
    romance,
    thriller

}
