import React, { useState } from 'react';
import { useAppSelector } from '../../hooks/storeHook';

import { ref, push, set } from 'firebase/database';
import { db } from '../../firebase';

interface CommentReportProps {
    storyName: string;
    userName: string;
    isReportSubmitted: boolean,
    storyId:string;
    commentId:string
  onCancel: () => void;
}



const CommentReport: React.FC<CommentReportProps> = ({ storyName, userName, onCancel, isReportSubmitted, commentId,storyId }) => {
  const { user } = useAppSelector((state) => state.auth);
  const [feedback, setFeedback] = useState<any>({
    // storytitle:storyName,
    userId:user?.id,
    userName:userName,
    reason:"",
    storyID:storyId,
    commentID:commentId
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFeedback({
      ...feedback,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit =async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Feedback submitted:', feedback);
    try {
        setLoading(true);
        const commonFeedbackIdRef = ref(db, `commentReports/${storyName}/`);
        const commonFeedbackId = push(commonFeedbackIdRef).key;
  
        if (isReportSubmitted) {
            console.log('isReportSubmitted:', isReportSubmitted)

        } else {
            if (commonFeedbackId) {
                await set(ref(db, `commentReports/${storyName}/${commonFeedbackId}`), feedback);
                setLoading(false);
                setSuccess(true);
                window.location.reload()
      
              }
        }
      } catch (error: any) {
        setLoading(false);
        const errorCode = error.code;
        setErrorMessage(errorCode);
      }
    // Add logic to submit the feedback to your backend or perform other actions
  };

  return (
    <div className="flex fixed top-0 right-0 z-50 justify-center items-center content-center w-full h-full p-4 overflow-x-hidden overflow-y-scroll  md:inset-0 select-none md:h-full">
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-900 backdrop-filter backdrop-blur-sm">
      <div className="bg-white dark:bg-slate-800 p-8 rounded-md shadow-2xl">
        <h2 className="text-2xl font-semibold mb-4 text-black dark:text-gray-100">Report Comment</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="userName" className="block mb-2 text-black dark:text-gray-100">
            Report For:
            <input
              type="text"
              id="userName"
              name="userName"
              value={feedback.userName}
              onChange={handleChange}
              className="w-full border rounded-md p-2 text-black"
              required
            />
          </label>

          <label htmlFor="reason" className="block mb-2 text-black dark:text-gray-100">
            Reason for Report:
            <textarea
              id="reason"
              name="reason"
              value={feedback.reason}
              onChange={handleChange}
              className="w-full border rounded-md p-2 resize-none text-black"
              rows={4}
              required
            />
          </label>
          <div className="flex gap-4">

          <button
            type="submit"
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            
            disabled={loading}
            >
            {loading ? 'Submitting...' : 'Submit Report'}
          </button>
          <button
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            onClick={onCancel}
            >
            Cancel
          </button>
              </div>
        

          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
          {success && <p className="text-green-500 mt-2">Report submitted successfully!</p>}
        </form>
      </div>
    </div>
  </div>
  );
};

export default CommentReport;