import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { get, ref } from 'firebase/database';
import { GlobalNotificationModel } from '../../models/other/Notifications';
import Marquee from 'react-fast-marquee';

const GlobalNotification = () => {
  const [notifications, setNotifications] = useState<GlobalNotificationModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const collectionName = 'globalNotifications';
        const docRef = ref(db, collectionName);

        const docSnap = await get(docRef);

        if (docSnap.exists()) {
          const data = docSnap.val();
          const notificationsArray: GlobalNotificationModel[] = Object.values(data);
          setNotifications(notificationsArray);
        } else {
          console.log('Document does not exist!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
        setError('An error occurred while fetching notifications.');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNotificationIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 5000); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, [notifications]);

  if (loading) {
    return <p className='p-3'></p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="flex gap-2 select-none">
      <i className="fa-sharp fa-solid py-1 fa-bullhorn text-teal-500"></i>
      {notifications.length === 0 ? (
        <p>No notifications found.</p>
      ) : (
        <div className="text-sm text-gray-900 dark:text-gray-100">
          <Marquee speed={25}>
            <div key={notifications[currentNotificationIndex].id} className="flex gap-2">
              <div className='font-1 font-bold '>{notifications[currentNotificationIndex].title.toUpperCase()} :</div>
              <div className='font-2 '>{notifications[currentNotificationIndex].body.toUpperCase()}</div>
            </div>
          </Marquee>
        </div>
      )}
    </div>
  );
};

export default GlobalNotification;