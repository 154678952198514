import React, { useEffect, useState } from 'react';
import { off, onValue, ref, DataSnapshot } from 'firebase/database';
import { db } from '../../firebase';

interface Advertisement {
  id: string;
  title: string;
  img: string;
}

const AdvertisementComponent: React.FC = () => {
  const [advertisement, setAdvertisement] = useState<Advertisement[]>([]);
  const [advertisementIndex, setAdvertisementIndex] = useState<number | null>(null);

  useEffect(() => {
    const adRef = ref(db, 'advertisement');
    const unsubscribe = onValue(adRef, (snapshot: DataSnapshot) => {
      const data = snapshot.val();
      if (!data) {
        setAdvertisement([]);
        return;
      }

      const advertisementArray: Advertisement[] = Object.keys(data).map((key) => ({
        id: key,
        title: data[key].title,
        img: data[key].img,
      }));

      // console.log('advertisementArray:', advertisementArray);
      setAdvertisement(advertisementArray);
      setAdvertisementIndex(0); // Reset the index when data updates
    });

    return () => {
      off(adRef, 'value', unsubscribe);
    };
  }, []);

  useEffect(() => {
    if (advertisement.length > 0) {
      const newIndex = Math.floor(Math.random() * advertisement.length);
      setAdvertisementIndex(newIndex);

      const interval = setInterval(() => {
        const newIndex = Math.floor(Math.random() * advertisement.length);
        setAdvertisementIndex(newIndex);
      }, 5000); // Change the advertisement every 5 seconds

      return () => {
        clearInterval(interval);
      };
    }
  }, [advertisement]);

  const selectedAdvertisement = advertisement[advertisementIndex || 0];



  return (
    <div className="h-full select-none">
      {
        selectedAdvertisement &&
      <div key={selectedAdvertisement?.id} className='h-screen w-full flex items-center justify-center'> {/* Updated this line */}
        <img className='w-full h-full object-cover' src={selectedAdvertisement?.img} alt={`Advertisement ${selectedAdvertisement?.id}`} />
      </div>
      }
    </div>

  );
};

export default AdvertisementComponent;