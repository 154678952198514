import React, { useState, useEffect } from "react";
// import { db } from "./firebase"; // Update the path to your Firebase configuration
import { DataSnapshot, DatabaseReference, off, onValue, ref } from "firebase/database";
import { db } from "../firebase";
import Header from "../components/Header/Header";
import ReactQuill from "react-quill";
import Footer from "../components/Footer";
import {  useNavigate } from "react-router-dom";

const PrivacyPolicies = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState("");
const navigate =useNavigate()
  useEffect(() => {
    // Fetch the privacy policy data from the Realtime Database
    const policyRef = ref(db, "privacypolicy");

    const onDataCallback = (snapshot: DataSnapshot) => {
      const policyData = snapshot.val();
      if (policyData) {
        setPrivacyPolicy(policyData);
      }
    };

    const policyListener = onValue(policyRef, onDataCallback);

    return () => {
      // Clean up the Firebase listener when the component unmounts
      off(policyRef, "value", policyListener);
    };
  }, []);

  return (
    <div className="select-none">
      {/* <Header/> */}
      <div className="p-10 bg-white dark:bg-slate-800">
<div className="flex gap-4 my-4">
  <div onClick={()=>navigate(-1)} className="text-xl font-bold cursor-pointer font-1 mt-3  text-gray-700 dark:text-gray-100">
  &#8592;
  </div>
      <div className="text-xl font-1 mt-3  text-gray-700 dark:text-gray-100">Privacy Policies</div>
</div>
      {/* <ReactQuill
        value={privacyPolicy}
        readOnly
        theme="snow"
        modules={{
          toolbar: false, // Disable the toolbar for read-only mode
        }}
      /> */}
      <div className="font-2 text-lg text-gray-700 dark:text-gray-200"  dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
      </div>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicies;