import React, { useState, useRef } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'
import { useNavigate, useParams } from 'react-router-dom'
import { getStorage, ref, uploadBytes } from 'firebase/storage'

import { getDatabase, ref as dbRef, push, set } from 'firebase/database';
import { useAppSelector } from '../../hooks/storeHook'
import { app, db } from '../../firebase';

import watermark from "../../assets/watermark.png"

import ConfirmationDialog from './CoverConfirmation';
import { toast } from 'react-toastify'
import Footer from '../Footer'
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        height:80,
        width: 30,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export default function StoryCover() {
  
  const { storyId } = useParams();
  const storageRef = getStorage();

  const navigate = useNavigate();
  const [firststep, setfirststep] = useState(false)
  const { user } = useAppSelector((state) => state.auth);
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [w, setw] = useState(0)
  const [h, seth] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(4/ 13)
  const [loading, setloading] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false);
  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }
  function onSelectFile2(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const image = new Image();
        image.src = reader.result?.toString() || '';
        image.onload = () => {
          if (image.width < 450 || image.height < 750
             || image.width > 1000 || image.height > 1000
             ) {
            // Show alert if image dimensions are below the required minimums
            toast.warning('Please select an image between min. height of 750px and  min. width of 450px');
            // Clear the file input
            e.target.value = '';
          } else {
            // Set the image source if it meets the minimum dimensions
            setImgSrc(reader.result?.toString() || '');
          }
        };
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onSelectFile3(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const image = new Image();
        image.src = reader.result?.toString() || '';
        image.onload = () => {
          if (image.width !== 450 || image.height !== 750) {
            // Show alert if image dimensions are below the required minimums
            toast.warning('Please select an image with min. height of 750px and  min. width of 450px');
            // Clear the file input
            e.target.value = '';
          } else {
            // Set the image source if it meets the minimum dimensions
            setImgSrc(reader.result?.toString() || '');
          }
        };
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }


  async function onDownloadCropClick() {
    setloading(true)
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size

    const watermarkImage = new Image();
  watermarkImage.src = watermark;
  ctx.drawImage(watermarkImage, offscreen.width - watermarkImage.width, 0);

     await new Promise((resolve) => {
    watermarkImage.onload = resolve;
  });

  const watermarkHeight = 30;
  const newWatermarkWidth = (watermarkHeight / watermarkImage.height) * watermarkImage.width;

  // Draw the watermark on the canvas
  ctx.drawImage(
    watermarkImage,
    offscreen.width - newWatermarkWidth - 10, // Adjusted margin
    10,
    newWatermarkWidth,
    watermarkHeight
  );
  ctx.imageSmoothingEnabled = false;
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
      quality:1
    })

    const storagePath = `authersImageData/${user && user.email}/storiesArchive/${storyId}/cover/${storyId}_cover.jpeg`;

    // Get a reference to the Firebase Storage
    const storage = ref(storageRef, storagePath);

    await uploadBytes(storage, blob);

  // Update the story and allStories database references
  const storyRef = dbRef(
    db,
    `authers/${user && user.id}/storiesArchive/${storyId}/cover`
  );
  const allStoryRef = dbRef(db, `allStories/${storyId}/cover`);

  // Set the cover image URL in the story and allStories nodes
  await set(storyRef, `${storyId}_cover.jpeg`);
  await set(allStoryRef, `${storyId}_cover.jpeg`);
  await setloading(false)
  navigate(`/addchapter/${storyId}`);
  // alert('Image uploaded to Firebase successfully!');
  
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else {
      setAspect(16 / 9)

      if (imgRef.current) {
        const { width, height } = imgRef.current
        const newCrop = centerAspectCrop(width, height, 16 / 9)
        setCrop(newCrop)
        // Updates the preview
        setCompletedCrop(convertToPixelCrop(newCrop, width, height))
      }
    }
  }

  return (
    <div className='select-none'>
   

    {showConfirmation && (
        <ConfirmationDialog
          onOk={onDownloadCropClick}
          onCancel={() => setShowConfirmation(false)}
          loadingStat={loading}
          message={"Are you sure you want to proceed? Once you move to the next page, the information entered on the previous page cannot be changed or edited. Please review all details carefully before proceeding."}
        />
      )}

{!!completedCrop && (
        
          <div className='hidden'>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>

      )}
    <div className='flex lg:p-24'>
        <div className='shadow-2xl w-full p-10'>
          <div className="text-3xl font-bold text-gray-900 dark:text-gray-100 text-center">- STEP TWO -</div>
          <div className="px-8">
            <div className="h-[0.5px] bg-gray-300 w-full mt-4 "></div>
          </div>
          {!imgSrc &&
          <div className='mt-4'>
            <label className="block text-gray-700 dark:text-gray-100 mb-4 text-lg font-bold font-1" htmlFor="file_input">Upload Cover Image:
             <span className='text-sm'>
             {" "}
             ( Format: .jpg, Width:450px, Height:750px )
             </span>
             </label>
          </div>}
          {/* {!file && ( */}
          {!imgSrc &&
            <div className="hidden lg:block">
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                  <div className="flex flex-col items-center justify-center pt-7">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                      fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                        clip-rule="evenodd" />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Select a photo</p>
                  </div>
                  <input className="opacity-0" id="file_input" type="file" accept="image/jpeg" onChange={onSelectFile2} />
                </label>
              </div>
            </div>
}

{!imgSrc &&
            <div className="block lg:hidden">
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                  <div className="flex flex-col items-center justify-center pt-7">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                      fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                        clip-rule="evenodd" />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Select a photo</p>
                  </div>
                  <input className="opacity-0" id="file_input" type="file" accept="image/jpeg" onChange={onSelectFile3} />
                </label>
              </div>
            </div>
}
            {/* // )} */}

          {/* {file && ( */}
          {imgSrc && (
            <div className='mt-4'>
              <div className='flex justify-end'>
                <button className={"rounded-md bg-red-700 text-white px-2 py-2 flex gap-2"} onClick={() => {
                  // setFile(null);
                  // setUploadedImageUrl('');
                  // setCanvasVisible(false);
                  setImgSrc("");
                  // setCompletedCrop(null);
                  console.log("hi")
                }}>
                  <div>
                    Re-upload the picture
                  </div>
                  <div>
                    <i className="fa-sharp fa-solid fa-trash "></i>
                  </div>
                </button>
              </div>
             
            </div>)}
          {/* )} */}
          <div>
          {imgSrc && (
<label className="block text-gray-700 dark:text-gray-200 text-lg font-bold font-1" htmlFor="file_input">Format:Jpg/JPEG</label>
)}          {imgSrc && (
            <div className='w-full flex justify-center'>

  <ReactCrop
    crop={crop}
    onChange={(_, percentCrop) => {setCrop(percentCrop);  setfirststep(true)}}
    onComplete={(c) => {console.log("crop",c);setCompletedCrop(c)}}
    minHeight={750}
    minWidth={450}
    maxHeight={750}
    maxWidth={450}
    // aspect={2/3}
    className=' flex justify-center h-full mt-4'
    >
    <img
   
   ref={imgRef}
   alt="Crop me"
   src={imgSrc}
   style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
   onLoad={onImageLoad}
   />
  </ReactCrop>
   </div>
)}

</div>



  {/* {canvasVisible && <canvas ref={canvasRef} style={{ display: 'none' }} />} */}
          <div className='hidden lg:flex justify-center mt-4'>
            <button className={` rounded-md ${(!imgSrc || completedCrop && completedCrop.width < 450) ? "bg-gray-700 cursor-no-drop":"bg-blue-900"} text-white px-4 py-2.5`} onClick={()=>{setShowConfirmation(true)}} disabled={(!imgSrc || completedCrop && completedCrop.width < 450)  }>
              Upload
            </button>
          </div>


          <div className='flex lg:hidden justify-center mt-4'>
            <button className={` rounded-md ${(!imgSrc || (!!completedCrop?.y) ) ? "bg-gray-700 cursor-no-drop":"bg-blue-900"} text-white px-4 py-2.5`} onClick={()=>{setShowConfirmation(true)}} disabled={(!imgSrc || (!!completedCrop?.y ) )  }>
              Upload
            </button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}
