import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer'
import not from "../assets/notfound.jpg"
const NotFoundPage = () => {
  return (
    <div className='select-none'>
      {/* <Header/> */}
      <img src={not} alt="" srcSet="" />
      <Footer/>
    </div>
  )
}

export default NotFoundPage
