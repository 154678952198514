import { endAt, get, orderByChild, push, query, ref, set, startAt } from "firebase/database";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Header from "../../components/Header/Header";
import { auth, db } from "../../firebase";
import { useAppSelector } from "../../hooks/storeHook";
import { StoryForm } from "../../models/story/StoryForm";
import { authClasses } from "../Auth/authClasses";
import ChapterAdd from "./ChapterAdd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import React from "react";
import Footer from "../../components/Footer";
import Info from "./Info";
import ConfirmationDialog from "./Confirmation";
import { ToastContainer, toast } from "react-toastify";
import { useKeyPress } from '@custom-react-hooks/all';
import { Tooltip } from "react-tooltip";
const StoryAdd = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { auther } = useAppSelector((state) => state.auther);
  const [formData, setformData] = useState<any>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const isPressed = useKeyPress('Enter');
  const [currentTitle, setCurrentTitle] = useState('');
  const [storyTitles, setStoryTitles] = useState<string[]>([]);
  const {
    container,
    form,
    button,
    input,
    text,
    link,
    hr,
    forgotPasswordButton,
  } = authClasses;
  const userDetails = useAppSelector((state) => state.auth.user?.userData);
  useEffect(() => {
    const user = auth.currentUser;
    if (!user?.emailVerified) {
      navigate(`/verification-pending/${user?.uid}`);
   
    }else
    { if(user && !userDetails) {
         navigate(`/credform/${user?.uid}`);
       }
      }
    
  }, []);
  const { handleSubmit, register } = useForm<StoryForm>();
  const { user } = useAppSelector((state) => state.auth);
  // const [tags, setTags] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  // const [tags, setTags] = useState<{ id: number; value: string }[]>([]);
  const [newTag, setNewTag] = useState("");
const [titles, settitles] = useState<[]>()
const [tagloading, settagloading] = useState(false)
  const [hasTags, setHasTags] = useState(false);
  // const handleTagChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   id: number
  // ) => {
  //   const updatedTags = { ...tags };
  //   updatedTags[id] = event.target.value;
  //   setTags(updatedTags);
  // };

  const handleNewTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 15);
    setNewTag(newValue);
  };

  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedTags = [...tags];
    updatedTags[index] = event.target.value;
    setTags(updatedTags);
  };

  const addTag = () => {
    if (newTag.trim() !== "" && tags.length < 5) {
      setTags((prevTags) => [...prevTags, newTag.toLowerCase()]);
      setNewTag("");
      setHasTags(true);
    }
  };

  const removeTag = (index: number) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };
  const handleTitleChange = (e:any) => {
    const newTitle = e.target.value;
    setCurrentTitle(newTitle);
    // console.log('Title:', newTitle);
  };

  const searchStories = async (title2: string) => {
    try {
      const storiesRef = ref(db, "allStories");
      const storyQuery = query(
        storiesRef,
        orderByChild("title"),
        startAt(title2.toLowerCase()),
        endAt(title2 + "\uf8ff"),
      );
      if (title2 !== "") {
        const snapshot = await get(query(storyQuery));
        const data = snapshot.val();
        if (data) {
          // Convert the object into an array of stories
          const storiesArray = Object.keys(data).map((key) => data[key]);
          
          // Extract and log the titles
          const titles = storiesArray.map((story) => story.title);
          setStoryTitles(titles);
          // console.log("Titles:", storyTitles);
        } else {
          // console.log("No data found");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    searchStories(currentTitle);
  }, [currentTitle]);


  const handleFormSubmit = async (data: StoryForm) => {
    try {
      setLoading(true);
      const summary2 = data.summary
      const wordCount = summary2?.split(/\s+/).filter(Boolean).length; 
      // console.log(wordCount)
      if (storyTitles.includes(data.title.toLowerCase())) {
        setLoading(false);
        toast.warning("Title already in use");
        return;
      }
        if (wordCount > 200 || wordCount<50) {
          // toast.warning(`Summary cannot exceed 200 words. current word Count ${wordCount}`);
          toast.warning(wordCount >200 ? `Summary cannot exceed 200 words. current word Count ${wordCount}`:`Summary cannot be less than 50 words. current word Count ${wordCount}`);
          return;
        }
        if (!hasTags || Object.keys(tags).length < 3) {
        setLoading(false);
        toast.warning("At least Three tag is required");
        return;
      }
      if (!hasTags || Object.keys(tags).length >5  ) {
        setLoading(false);
        toast.warning("At max Five tags are allowed.");
        return;
      }
      const tagsAsString = Object.values(tags).join(' ');
      const newData = {
        ...data,
        title:data.title.toLowerCase(),
        autherName: user?.userData?.name.toLowerCase(),
        autherId: user?.id,
        autherEmail: user?.email,
        tags: tagsAsString.toLowerCase(),
      };
      console.log(newData)
      await setformData(newData);
      await setShowConfirmation(true);
    } catch (error: any) {
      setLoading(false);
      const errorCode = error.code;
      setErrorMessage(errorCode);
    }
  };


  useEffect(() => {
    if (isPressed) {
      document.getElementById('tag')?.click();
    }
  }, [isPressed]);
  
  
  
  // const validateSummary = (value: string) => {
  //   const wordCount = value.split(/\s+/).filter(Boolean).length;

  //   if (wordCount > 200 || wordCount<50) {
  //     toast.warning(`Summary cannot exceed 200 words. current word Count ${wordCount}`);
  //     return false;
  //   }

  //   return true; 
  // };

  const validatetitle = (value: string) => {
    const wordCount = value.split(/\s+/).filter(Boolean).length; 

    if (wordCount > 15) {
      toast.warning("title cannot exceed 15 words.");
      return false; 
    }

    return true; 
  };
  const preventFormSubmissionOnEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  return (
    <div className="select-none">
      {showConfirmation && (
        <ConfirmationDialog
          formData={formData}
          onCancel={() => setShowConfirmation(false)}
          message={"Are you sure you want to proceed? Once you move to the next page, the information entered on the previous page cannot be changed or edited. Please review all details carefully before proceeding."}
        />
      )}
      {/* <Header /> */}
      <ToastContainer/>
      <div className='flex lg:p-24 bg-white dark:bg-slate-800'>
        <div className='shadow-2xl w-full p-4 lg:p-10'>
          <div className="text-3xl font-bold text-center text-gray-900 dark:text-gray-100">- STEP ONE -</div>

          <div className="px-8">
            <div className="h-[0.5px] bg-gray-300 w-full mt-4 "></div>
          </div>
          <form action="submit" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="lg:flex gap-4 mt-4">
              <div className="w-full">
                <div className="flex gap-4">
                  <label
                    htmlFor="title "
                    className="block text-gray-900 dark:text-gray-100 text-lg font-bold mb-2 font-1"
                  >
                    Title:
                  </label>
                  <div className='select-none'>
      <Tooltip anchorSelect=".my-anchor-element" place="top">
        <div>{"Capture the essence of your writing"}</div>
      </Tooltip>
      <div className="my-anchor-element mt-[2px] text "
      // data-tooltip-id="my-tooltip"
      // data-tooltip-content={props.props}
      // data-tooltip-place="top"
      >
        <i className="fa-solid fa-circle-info text-gray-900 dark:text-gray-100"></i>
      </div>
    </div>
                  
                </div>
                <input
                  type="text"
                  placeholder="Title "
                  className={
                    "shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 dark:text-gray-900  focus:outline-none focus:shadow-outline"
                  }
                  
                  id="title"
                  {...register("title", { validate: validatetitle })}
                  onChange={handleTitleChange}
                  required
                  onKeyPress={preventFormSubmissionOnEnter}
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="category"
                  className="block text-gray-900 dark:text-gray-100 text-lg font-bold mt-4 lg:mt-0 mb-2 font-1"
                >
                  Category:
                </label>
                <select
                  className={
                    "shadow appearance-non border text-center rounded w-full py-2 px-3 text-gray-900 dark:text-gray-800  focus:outline-none focus:shadow-outline"
                  }
                  id="category"
                  {...register("category")}
                  onKeyPress={preventFormSubmissionOnEnter}
                  required
                >
                  <option className=" text-lg " value="">
                    {" "}
                    Select Genre{" "}
                  </option>
                  <option className=" text-lg " value="Real life experiences">
                    Real life experiences
                  </option>
                  <option className=" text-lg " value="Fantasy">
                    Fantasy
                  </option>
                  <option className=" text-lg " value="Magical realism">
                    Magical realism
                  </option>
                  <option className=" text-lg " value="Mythology">
                    Mythology
                  </option>
                  <option className=" text-lg " value="Short stories">
                    Short stories
                  </option>
                  <option className=" text-lg " value="Poems">
                    Poems
                  </option>
                  <option className=" text-lg " value="Young adult">
                    Young adult
                  </option>
                  <option className=" text-lg " value="Erotica">
                    Erotica
                  </option>
                  <option className=" text-lg " value="Romance">
                    Romance
                  </option>
                  <option className=" text-lg " value="Mystery">
                    Mystery
                  </option>
                  <option className=" text-lg " value="Fanfiction">
                    Fanfiction
                  </option>
                  <option className=" text-lg " value="Everyday memoir">
                    Everyday memoir
                  </option>
                  <option className=" text-lg " value="Daily frustration">
                    Daily frustration
                  </option>
                  <option className=" text-lg " value="Comedy">
                    Comedy
                  </option>
                  <option className=" text-lg " value="Horror">
                    Horror
                  </option>
                  <option className=" text-lg " value="Sci-fiction">
                    Sci-fiction
                  </option>
                  <option className=" text-lg " value="Literary fiction">
                    Literary fiction
                  </option>
                  <option className=" text-lg " value="Adventure">
                    Adventure
                  </option>
                  <option className=" text-lg " value="Essay">
                    Essay
                  </option>
                  <option className=" text-lg " value="Flash fiction">
                    Flash fiction
                  </option>
                  <option className=" text-lg " value="Hear-me-out">
                    Hear-me-out
                  </option>
                  <option className=" text-lg " value="Thriller">
                    Thriller
                  </option>
                  <option className=" text-lg " value="Exposé">
                    Exposé
                  </option>
                  <option className=" text-lg " value="Confessions">
                    Confessions
                  </option>
                  <option className=" text-lg " value="Self-help">
                    Self-help
                  </option>
                  <option className=" text-lg " value="Tech">
                    Tech
                  </option>
                  <option className=" text-lg " value="Historical fiction">
                  Historical fiction
                  </option>
                  <option className=" text-lg " value="Autobiography">
                  Autobiography
                  </option>
                  <option className=" text-lg " value="Psychology">
                  Psychology
                  </option>
                  <option className=" text-lg " value="Satire">
                  Satire
                  </option>
                </select>
              </div>
            </div>

            <div className="mt-4 ">
            <div className="flex gap-4"></div>
              <div className="flex gap-4">
                <label
                  htmlFor="summary"
                  className="block text-gray-900 dark:text-gray-100 text-lg font-bold mb-2 font-1"
                >
                  Summary:
                </label>
                <div className='select-none'>
      <Tooltip anchorSelect=".my-anchor-element2" place="top">
        <div>{"max.200 words"}</div>
      </Tooltip>
      <div className="my-anchor-element2 mt-[2px] text "
      // data-tooltip-id="my-tooltip"
      // data-tooltip-content={props.props}
      // data-tooltip-place="top"
      >
        <i className="fa-solid fa-circle-info text-gray-900 dark:text-gray-100"></i>
      </div>
    </div>
              </div>
              <textarea
                onKeyPress={preventFormSubmissionOnEnter}
                className={
                  "shadow appearance-none border resize-none h-48 rounded w-full py-2 px-3 text-gray-900 dark:text-gray-900  focus:outline-none focus:shadow-outline"
                }
                id="summary"
                {...register("summary")}
                required
              />
            </div>

            <div className="lg:flex mt-4">
              <div className="lg:w-1/2 mt-2 flex">
                <div>
                  <label
                    htmlFor="is18"
                    className="block text-gray-900 dark:text-gray-100 text-lg font-bold mb-2 font-1"
                  >
                    Matured content:
                  </label>
                </div>
                <div>
                  <input
                    onKeyPress={preventFormSubmissionOnEnter}
                    type="checkbox"
                    id="is18"
                    className={" leading-tight h-5 w-5 mt-1 mx-4 "}
                    {...register("is18")}
                  />
                </div>
              </div>
              {/* {isPressed && <p>You are pressing the "Enter" key!</p>} */}
              <div className="w-1/2 mt-2 mb-4 lg:mb-0 flex">
                <div>
                  <label
                    htmlFor="isLGBT"
                    className="block text-gray-900 dark:text-gray-100 text-lg font-bold mb-2 font-1"
                  >
                    LGBT+:
                  </label>
                </div>
                <div>
                  <input
                    onKeyPress={preventFormSubmissionOnEnter}
                    type="checkbox"
                    id="isLGBT"
                    className={" leading-tight h-5 w-5 mt-1 mx-4 "}
                    {...register("islgbt")}
                  />
                </div>
              </div>
              <div className="w-full flex ">
                <select
                  onKeyPress={preventFormSubmissionOnEnter}
                  className={
                    "shadow text-center border rounded w-full mb-4 lg:mb-0 py-2 px-3 text-gray-900 dark:text-gray-900  focus:outline-none focus:shadow-outline"
                  }
                  required
                  {...register("ageGroup")}
                >
                  <option className=" text-lg " value="">
                    Pick your Reading Tribe
                  </option>
                  <option className=" text-lg " value="-18">
                    below 18: Fireflies
                  </option>
                  <option className=" text-lg " value="18-24">
                    18-24 years: Phoenix{" "}
                  </option>
                  <option className=" text-lg " value="25-34">
                    25-34 years: Dragon{" "}
                  </option>
                  <option className=" text-lg " value="35-44">
                    35-44 years: Wolf{" "}
                  </option>
                  <option className=" text-lg " value="45-54">
                    45-59 years: Tiger{" "}
                  </option>
                  <option className=" text-lg " value="60+">
                    Above 60: Elephant Elder
                  </option>
                </select>
              </div>
            </div>

            <div className="mt-4">
            <div className="flex gap-4">
              
              <label
                htmlFor="tags"
                className="block text-gray-900 dark:text-gray-100 text-lg font-bold font-1"
                >
                Tags ( max. 5, min. 3 ):
              </label>
              <div className='select-none'>
      <Tooltip anchorSelect=".my-anchor-element4" place="top">
        <div>{"Highlight the keywords"}</div>
      </Tooltip>
      <div className="my-anchor-element4 mt-[2px] text "
      // data-tooltip-id="my-tooltip"
      // data-tooltip-content={props.props}
      // data-tooltip-place="top"
      >
        <i className="fa-solid fa-circle-info text-gray-900 dark:text-gray-100"></i>
      </div>
    </div>
                {/* <Info props={"Highlight the keywords"} /> */}
                </div>
              <div className="grid grid-cols-1 lg:grid-cols-5 mt-4 lg:mt-0 gap-4 lg:gap-12">
                {Object.entries(tags).map(([id, tag]) => (
                  <div className=" flex gap-1" key={id}>
                    <input
                      onKeyPress={preventFormSubmissionOnEnter}
                      type="text"
                      className="shadow appearance-none border rounded w-full py-1 px-3 text-gray-900 dark:text-gray-900  focus:outline-none focus:shadow-outline"
                      value={tag}
                      onChange={(e) => handleTagChange(e, parseInt(id))}
                      placeholder="Tag"
                      readOnly
                    />
                    <div className="flex justify-center">
                      <button
                        className={`rounded-md ${tagloading? "bg-red-900": "bg-red-700"} text-white px-2`}
                        type="button"
                        disabled={tagloading}
                        onClick={() => removeTag(parseInt(id))}
                      >
                        <i className="fa-sharp fa-solid fa-trash text-gray-50 dark:text-gray-100"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
             <div>
                <div className="flex gap-4 mt-4">
                  <input
                    onKeyPress={preventFormSubmissionOnEnter}
                    className={`${Object.keys(tags).length > 4 ? "hidden" : ""} 
  shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 dark:text-gray-900  focus:outline-none focus:shadow-outline`}
                    readOnly={Object.keys(tags).length > 4}
                    type="text"
                    value={newTag}
                    onChange={handleNewTagChange}
                    placeholder="Separate your tags by pressing the 'add' button"
                  />
                  <button
                    type="button"
                    id="tag"
                    disabled={Object.keys(tags).length > 4 || tagloading}
                    className={`${
                      Object.keys(tags).length > 4 ? "hidden" : ""
                    } rounded-md ${tagloading?"bg-blue-950" :"bg-blue-900"} text-white px-4`}
                    onClick={addTag}
                    
              tabIndex={0}

                    
                  >
                    Add
                  </button>
                </div>
                  {/* {isEnterPressed && <div>hi</div>} */}
              </div>
            </div>
            <div className="flex justify-center mt-8 mb-4 lg:mb-0 lg:mt-4">
              <button
                className={" rounded-md bg-blue-900 text-white px-4 py-2.5"}
                type="submit"
              >
                Create Story
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StoryAdd;
