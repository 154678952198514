import React from 'react';
import { useAppDispatch } from '../../hooks/storeHook';
import { logout } from '../../features/authSlice';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';



const RestrictVerificationModal = () => {
    
  const dispatch = useAppDispatch();
    const handleLogout = async () => {
        await signOut(auth);
        dispatch(logout());
      };
  return (
    <div className="flex  fixed top-0 right-0 z-50 justify-center items-center content-center w-full h-full p-4 overflow-x-hidden overflow-y-scroll bg-black bg-opacity-70 md:inset-0 md:h-full select-none ">

 
    <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-opacity-900 backdrop-filter backdrop-blur-lg">
        <div className='bg-white px-10 pb-4 rounded-lg overflow-y-auto'>
            <div className='text-9xl text-yellow-500 text-center'>
            <i className="fa-solid fa-triangle-exclamation"></i>
            </div>

        <div  className="block text-gray-700 text-lg font-bold mt-4 font-1">Your account is presently restricted due to violations of our terms & conditions or privacy policy.

</div>
        
        <div className="flex justify-center mt-8">

        <button  className={" rounded-md bg-blue-900 text-white px-4 py-2.5"} onClick={handleLogout}>Log Out</button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default RestrictVerificationModal;