import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserAuther,  } from "../models/user/Auther";
import { WritableDraft } from "immer/dist/internal";

export interface AutherState {
  auther: null | WritableDraft<UserAuther>;
}

const initialState: AutherState = {
  auther: null,
};

export const autherSlice = createSlice({
  name: "auther",
  initialState,
  reducers: {
    autherLogin: (state, action: PayloadAction<UserAuther>) => {
      state.auther = action.payload;
    },
    autherLogout: (state) => {
      state.auther = null;
    },
  },
});

export const { autherLogin, autherLogout } = autherSlice.actions;

export default autherSlice.reducer;