import React, { useRef } from "react";

import { Tooltip } from 'react-tooltip';
interface ShareProps {
  storyId: string;
  chapterId?: string;
}

const Share: React.FC<ShareProps> = ({ storyId, chapterId }) => {
  const shareRef = useRef<HTMLInputElement | null>(null);

  const shareStory = () => {
    if (navigator.share) {
      navigator
        .share({
          url: `${window.location.origin}/story/${storyId}/chapter/${chapterId}`,
        })
        .then(() => console.log("Story shared successfully!"))
        .catch((error) => console.error("Error sharing story:", error));
    } else {
      // If Web Share API is not supported, open a share dialog manually
      if (shareRef.current) {
        shareRef.current.click();
      }
    }
  };
  

  return (
    <div className="select-none">
     
      <div className="flex gap-4 font-bold mt-4 my-anchor-element2 lg:mt-0 hover:bg-blue-900 p-3 text-md rounded-md bg-[#002e7200] border-[1px] border-solid border-blue-900  cursor-pointer"  onClick={shareStory}>
           <div className="">
             <i className="fa-sharp fa-solid fa-share "></i>
           </div>
           <div>Share</div>
         </div>

      {/* <input
        type="text"
        ref={shareRef}
        defaultValue={`${window.location.origin}/story/${storyId}/chapter/${chapterId}`}
        style={{ position: "absolute", left: "-9999px" }}
        readOnly
      /> */}
       <Tooltip  anchorSelect=".my-anchor-element2" className=''  place="top" style={{ backgroundColor: 'white', color: 'black' }}>
        <div style={{ whiteSpace: "normal" }}>{"Share"}</div>
      </Tooltip>
    </div>
  );
};

export default Share;