import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail, signOut } from "firebase/auth";
import { auth, db } from "../../firebase";
import CustomCircularProgressbar from "./CustomProgress"
import Header from "../../components/Header/Header";
import ResetPassword from "../../components/ResetPassword/ResetPassword";
import UserSection from "./UserSection";
import AutherSection from "./AutherSection";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHook";
import {  logout } from "../../features/authSlice";
import { get, onValue, ref, update } from "firebase/database";
import WorldMap, { CountryContext } from "react-svg-worldmap";
import { threeToTwoLetterMap } from "../../models/other/Allcountries";
import UserBookmark from "./UserBookmark";
import Footer from "../../components/Footer";
import bg from "../../assets/worldmap.svg"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Info from "../Story/Info";
import { ToastContainer, toast } from "react-toastify";
import InfoMod from "../Story/InfoMod";
import { writeClassMod } from "../Auth/authClasses";
interface AudienceData {
  [key: string]: number;
}
interface Bookmark {
  storyId: string;
  chapterId: string; // Explicitly define chapterId as string
}
const Profile = () => {
  // const widthMap = 100;
  const [showProfile, setshowProfile] = useState(false)
  const stylingFunction = ({
    countryValue,
    minValue,
    maxValue,
    // country,
    color,
  }: CountryContext) => {
   
    
    return {
      
      stroke: "#002D72e2",
      fill:"#002D72",
      strokeWidth: 1,
      strokeOpacity: 0.2,
      cursor: "pointer",
    };
  };

  
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  
  const [userBookmarks, setUserBookmarks] = useState<Bookmark[]>([]);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState<string | null>(
    null
  );
  const [resetPasswordError, setResetPasswordError] = useState<string | null>(
    null
  );
  const { user } = useAppSelector((state) => state.auth);
  const [resetPassword, setResetPassword] = useState(false);
  
  const [audienceData, setAudienceData] = useState<AudienceData>({});
  
  const userDetails = useAppSelector((state) => state.auth.user?.userData);
  useEffect(() => {
    const user = auth.currentUser;
    if (!user?.emailVerified) {
      navigate(`/verification-pending/${user?.uid}`);
   
    }else
    { if(user && !userDetails) {
         navigate(`/credform/${user?.uid}`);
       }
      }
    
  }, []);
  useEffect(() => {
    const audienceRef = ref(db, `authers/${user?.id}/audiance`);

    const fetchData = onValue(audienceRef, (snapshot) => {
      if (snapshot.exists()) {
        setAudienceData(snapshot.val());
      }
    });

    return () => {
      fetchData();
    };
  }, [user?.id]);

  const countryName = Object.keys(audienceData)[0] || "";
  const countryPercentage = Object.values(audienceData)[0] || 0;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const formattedBirthdate = user?.userData?.birthdate
    ? new Date(user.userData.birthdate).toLocaleDateString()
    : "Birthdate not available";

  const [editMode, setEditMode] = useState(false);
  const [editableName, setEditableName] = useState("");
  const [editableGender, setEditableGender] = useState("");
  const [editableBirthdate, setEditableBirthdate] = useState("");

  useEffect(() => {
    if (user) {
      setEditableName(user.userData?.name || "");
      setEditableGender(user.userData?.gender || "");
      setEditableBirthdate(
        user.userData?.birthdate instanceof Date
          ? user.userData.birthdate.toISOString().slice(0, 10)
          : user.userData?.birthdate || ""
      );
    }
  }, [user]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    // Extract form data from state variables
    const formData = {
      name: editableName,
      gender: editableGender,
      birthdate: editableBirthdate,
    };
  
    // Validate name length
    if (formData.name.length > 30) {
      // Display an error message or handle the validation error as needed
      toast.error('Please enter valid name. Must be 30 characters or less');
      return; // Stop further execution if validation fails
    }
  
    // Database references
    const userDetailsRef = ref(db, `users/${user?.id}/userData`);
    const autherDetailsRef = ref(db, `authers/${user?.id}`);
  
    // Update database
    update(userDetailsRef, formData);
    update(autherDetailsRef, { autherName: editableName });
  
    // Reload the page
    window.location.reload();
  
    // Set edit mode to false
    setEditMode(false);
  };

  const handleEditToggle = () => {
    // Toggle edit mode
    setEditMode((prevEditMode) => !prevEditMode);
  };

  useEffect(() => {
    const fetchUserBookmarks = async () => {
      try {
        setLoading(true)
        const userBookmarksRef = ref(db, `users/${user?.id}/bookmarks`);
        const snapshot = await get(userBookmarksRef);
        const bookmarksData = snapshot.val();

        if (bookmarksData) {
          const bookmarksArray: Bookmark[] = Object.entries(bookmarksData).reverse().map(
            ([storyId, chapterId]) => ({
              storyId,
              chapterId: chapterId as string,
            })
          );
          setUserBookmarks(bookmarksArray);
setLoading(false)
        }
      } catch (error) {
        console.error("Error fetching user bookmarks:", error);
      }
    };

    fetchUserBookmarks();
  }, [user]);


  useEffect(() => {
    const audienceRef = ref(db, `authers/${user?.id}/audiance`);

    const fetchData = onValue(audienceRef, (snapshot) => {
      if (snapshot.exists()) {
        setAudienceData(snapshot.val());
        console.log(snapshot.val())
      }
    });
    // Clean up the listener when the component unmounts
    return () => {
      fetchData();
    };
  }, [user?.id]);

 
  const totalAudience = Object.values(audienceData).reduce((acc, value) => acc + value, 0);
  const data = Object.entries(audienceData).map(([country, value]) => ({
    country: country,
    value: Math.round((value / totalAudience) * 100),
  }));
  return (
    <div className="bg-gray-50 select-none">
      {/* <Header /> */}
      <div className="flex flex-col justify-center bg-white dark:bg-slate-800">
      {loading && (<></>)}
    {errorMessage && (<>{errorMessage}</>)}
    
      <div className="flex flex-col ">
      <div className="text-white bg-[#dddcdc] h-[45vh] md:h-[80vh] lg:h-[45rem]  xl::h-[50rem] " style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // backgroundPosition: "center",
        maxWidth:"100%",
        // height: "100%"
      }}>

  <ToastContainer/>
        <div className=" justify-center ">


{showProfile &&
        <div className="flex  fixed top-0 right-0 z-50 justify-center items-center content-center w-full h-full p-4 overflow-x-hidden overflow-y-scroll bg-black bg-opacity-70 md:inset-0 md:h-full ">

 
<div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-opacity-900 backdrop-filter backdrop-blur-lg">
    <div className='bg-white w-4/5 lg:w-1/3  rounded-lg overflow-y-auto'>
      {user && (
        <div className="  rounded-md shadow-2xl w-full flex flex-col items-center justify-center">
          <div className="w-full flex items-center justify-between px-2 lg:px-10">
<button onClick={()=>setshowProfile(false)} className="text-red-800 mt-3 text-3xl">
<i className="fa-solid fa-xmark"></i>
</button>
          <button
            onClick={handleEditToggle}
            className="mt-4 bg-gray-500 text-gray-100 hover:bg-gray-100 hover:text-gray-900  text-sm font-bold py-2 px-4 rounded-full"
            >
            {editMode ? 
            <div className="flex gap-2">
              <div>

            Cancel
              </div>
              <div className="text-xs mt-[1px]">
              <i className="fa-sharp fa-solid fa-trash "></i>
              </div>
            </div> : 
            <div className="flex gap-2">
              <div>

            Edit
              </div>
              <div className="text-xs mt-[1px]">
              <i className="fa-sharp fa-solid fa-pen "></i>
              </div>
            </div>
            }
          </button>
            </div>
          {editMode ? (
            <form className="w-full flex px-10  flex-col justify-center items-center" onSubmit={handleFormSubmit}>
              {/* User Photo */}
              {/* {user.isAuther && <span>A</span>} */}
              <div className=" flex justify-center mt-5">
                {user.photoUrl ? (
                  <img
                    className="w-36 h-36 object-cover rounded-full shadow-lg"
                    src={user.photoUrl}
                    alt="Avatar"
                  />
                ) : (
                  <div className="w-36 h-36 mb-3 text-4xl font-bold grid place-content-center bg-green-200 rounded-full shadow-lg">
                    {user.email[0].toUpperCase()}
                  </div>
                )}
              </div>

              {/* Editable Name */}
              <div className="mb-2 w-full   gap-4 justify-center ">
                <div className="mt-2">

                <label className="text-gray-900 text-lg font-bold">Name:</label>
                </div>
                <div className="w-full ">

                <input
                  type="text"
                  className="shadow appearance-none border rounded w-full py-1 px-3 text-gray-900"
                  value={editableName}
                  onChange={(e) => setEditableName(e.target.value)}
                  />
                  </div>
              </div>

              {/* Editable Gender */}
              <div className="mb-2 w-full  gap-4 justify-center ">
              <div className="">

                <label className="block text-gray-900 text-lg font-bold">Gender:</label>
                </div>
                <div className="mt-1 w-full ">

                    <label className="inline-flex gap-2  items-center  ">
                <input type="radio" className="form-radio h-5 w-5 text-gray-800" 
                value="Male" checked={editableGender === "Male"}
                onChange={() => setEditableGender("Male")}/><span className="mx-2 text-gray-700">Male</span>
            </label>

            <label className="inline-flex gap-2  items-center ">
                <input type="radio" className="form-radio h-5 w-5 text-gray-800" 
                
                value="Female"
                checked={editableGender === "Female"}
                onChange={() => setEditableGender("Female")}
                /><span className="mx-2 text-gray-700">Female</span>
            </label>

            <label className="inline-flex gap-2  items-center ">
                <input type="radio" 
                value="Other" className="form-radio h-5 w-5 text-gray-800" checked={editableGender === "Other"}
                onChange={() => setEditableGender("Other")}/><span className="mx-2 text-gray-700">Other</span>
            </label>

      
                </div>
              </div>

              {/* Editable Birthdate */}
              <div className="mb-4 w-full   gap-4 justify-center ">
<div className="block text-gray-700 text-lg font-bold">

                <label className="block text-gray-900 text-lg font-bold mt-2">
                  Birthdate:
                </label>
</div>
<div className="w-full">

                <input
                  type="date"
                  className="shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-900"
                  value={editableBirthdate}
                  onChange={(e) => setEditableBirthdate(e.target.value)}
                />
              </div>
                  </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 mt-4 mb-8 text-white font-bold py-2 px-4 rounded"
              >
                Submit
              </button>
            </form>
          ) : (
            // Display user information in view mode
            <div className="text-start w-full flex flex-col items-center justify-center py-12">
              <div className="">

              {user.photoUrl ? (
                <img
                className="w-36 h-36 mb-3 object-cover rounded-full shadow-lg"
                src={user.photoUrl}
                alt="Avatar"
                />
                ) : (
                  <div className="w-36 h-36 mb-3 text-4xl font-bold grid place-content-center bg-green-200 rounded-full shadow-lg">
                  {user.email[0].toUpperCase()}
                </div>
              )}

              </div>
              <div className=" flex flex-col justify-center">

              <div className="mb-4 mt-4 w-full flex justify-center items-center text-center">
                <div className="text-lg font-bold font-2 text-gray-900">Name: </div>
               <div className="text-lg font-bold font-1 w-full text-start text-gray-700 ">&nbsp; {user.userData?.name}</div>
              </div>

              <div className="mb-4  w-full flex ">
                <div className="text-lg font-bold font-2 text-gray-900">Gender: </div>
               <div className="text-lg font-bold font-1 w-full text-start text-gray-700 ">&nbsp; {user.userData?.gender}</div>
              </div>

              <div className="mb-4  w-full flex ">
                <div className="text-lg font-bold font-2 text-gray-900">Birthdate: </div>
               <div className="text-lg font-bold font-1 w-full text-start text-gray-700 ">&nbsp; {formattedBirthdate}</div>
              </div>
              </div>
            </div>
          )}

          {/* Edit Button */}
          
        </div>
      )}
    </div>
    </div>
    </div>
}

<div className="flex flex-col">


<div className="flex justify-end w-full px-2 lg:px-4 mt-2 lg:mt-4 cursor-pointer">
  <div className="w-full hidden lg:block"></div>
<div className=" lg:text-3xl w-full text-center lg:text-start mb-4 font-1 text-blue-900 font-bold">
   Active Readers 
  </div>
  <div className="hidden lg:flex justify-end w-full lg:w-1/2">

<div className=" flex gap-2 items-center  bg-blue-900  border-white-2 border-solid px-4 rounded-full button shadow-2xl" onClick={()=>setshowProfile(true)}>
  <div className="font-bold ">
  My Profile </div>
<div className="-mt-0.5">
  <InfoMod props="Manage Profile" />
</div>
</div>
  </div>
  </div>

{data  && 
<div className="flex flex-col w-full text-center">

  <div className="flex flex-wrap justify-evenly w-full   mb-4 lg:mt-12 ">

    {data.filter((data)=>data.country != "undefined").map(({ country, value }) => (
      <div>

      <div className=" flex  flex-col justify-evenly items-center">
        <div>


      <CustomCircularProgressbar key={country} country={country} value={value} />
        </div>
      <div className="lg:mt-4 font-1 text-xs text-blue-900 lg:text-lg font-bold line-clamp-1">
      {`${country}`}
      </div>
      </div>
      

      </div>
    ))}
  </div>
  
</div>
  }
  

    </div>

    </div>  
      </div>
      

      <div className="lg:hidden  flex items-end  justify-end mt-4 w-full lg:w-1/2">

<div className=" flex gap-2 items-center  bg-blue-900  border-white-2 border-solid px-4 py-1 rounded-full button shadow-2xl" onClick={()=>setshowProfile(true)}>
  <div className="font-bold text-white">
  My Profile </div>
<div className="-mt-0.5">
  <InfoMod props="Manage Profile" />
</div>
</div>
  </div>
    
      </div>
      {/* <div className="px-8">

        <div className="h-[0.5px] bg-gray-300 w-full mt-24 "></div>
</div> */}
     
<div className="flex flex-col">
  <div>
    {/* <h1>My Write ups</h1> */}
    <div>
<AutherSection />
{/* <UserSection /> */}
{/* {totalWriteupCount  ?
 <></>
} */}

    </div>
    {user?.bookshelf &&
    <>
    
    <div className="px-8 mb-8">

<div className="h-[0.5px] bg-gray-300 w-full  "></div>
</div>
    <div>
<UserSection />
{/* <UserSection /> */}

    </div>
    </>
  }


{userBookmarks.length>0 &&
<>

    <div className=" mb-8">

        <div className="h-[0.5px] bg-gray-300 w-full  "></div>
</div>
    <div className='lg:text-4xl text-[#0c192eec] select-none text-start px-12 font-bold  font-2 flex justify-between text-gray-900 dark:text-gray-100'>Continue Reading</div>
     <UserBookmark/>
     </>
    }
  </div>
</div>
</div>
<Footer/>
      
    </div>
  );
};

export default Profile;