import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteObject, getDownloadURL, getStorage, ref as sref } from "firebase/storage";
import { auth, db } from "../../firebase";
import { ref, get, update, remove } from "firebase/database";
import { Story } from "../../models/story/Story";
import Header from "../../components/Header/Header";
import Comment from "../../components/Story/Comment";
import AllComments from "../../components/Story/AllComments";
import { useAppSelector } from "../../hooks/storeHook";
import MoreFromAuther from "../../components/Story/MoreFromAuther";
import IsExclusiveForm from "../../pages/Story/IsExclusiveForm";
import ChapterIndex from "./ChapterIndex";
import AddtoBookshelf from "./AddtoBookshelf";
import Share from "./Share";
import Footer from "../Footer";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import 'animate.css';

import Loading from "../../pages/Home/Loading";
import Info from "../../pages/Story/Info";
import StoryCard from "./StoryCard";
import Like2 from "./LIke2";
import Like from "./Like";
import StoryCardForSynopsis from "./StoryCardForSynopsis";
import ConfirmationDialog from "./CoverConfirmation";
import { Tooltip } from "react-tooltip";
import { formatNumber } from "./useDebounceEffect";

interface ChapterData {
  chapterId: string;
  chapterNo: number;
  title: string;
  timestamp?: number;
}
const Synopsis = () => {
  const { id, chapterId } = useParams<{ id: string; chapterId: string }>();
  const [success, setSuccess] = useState(false);
  const [chapters, setChapters] = useState<ChapterData[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [story, setStory] = useState<Story | null>(null);
  const [hasViewed, setHasViewed] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const storageRef = getStorage();
  const { user } = useAppSelector((state) => state.auth);
  const [isComplete, setIsComplete] = useState(false);
  const [commentCounts, setcommentCounts] = useState(0)
  const [loadingstat, setloadingstat] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const chaptersRef = ref(db, `allStories/${story?.id}/chapters`);
        const snapshot = await get(chaptersRef);
        const chaptersData = snapshot.val();

        if (chaptersData) {
          const chaptersArray: ChapterData[] = Object.keys(chaptersData).map((chapterId) => ({
            chapterId,
            chapterNo: chaptersData[chapterId].chapterNo,
            title: chaptersData[chapterId].title,
            timestamp: chaptersData[chapterId].timestamp,
          }));
          setChapters(chaptersArray);
        }
      } catch (error) {
        console.error("Error fetching chapters:", error);
      }
    };

    fetchChapters();
  }, [story?.id]);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  
  const userDetails = useAppSelector((state) => state.auth.user?.userData);
  useEffect(() => {
    const user = auth.currentUser;
    if (!user?.emailVerified) {
      navigate(`/verification-pending/${user?.uid}`);
   
    }else
    { if(user && !userDetails) {
         navigate(`/credform/${user?.uid}`);
       }
      }
    
  }, []);

 

  const handleCheckboxChange = (event:any) => {
    setIsComplete(!isComplete);
  };

  // Step 3: Add an event handler to capture form submission
  const handleSubmit = async (event: any) => {
    event.preventDefault(); 
    setloadingstat(true)
    try {
      const storyRef = ref(db, `allStories/${id}`);
      await update(storyRef, { isComplete: true });
  
      const authorArchiveRef = ref(db, `authers/${story?.autherId}/storiesArchive/${id}`);
      await update(authorArchiveRef, { isComplete: true });
      const editorsChoiceRef = ref(db, `editorsChoice/${id}`);
      const editorsChoiceSnapshot = await get(editorsChoiceRef);

      const bookofMonthRef = ref(db, `bookOfTheMonth/${id}`);
      const bookofMonthSnapshot = await get(bookofMonthRef);

      const mostLikedRef = ref(db, `mostLikedStories/${id}`);
      const mostLikedSnapshot = await get(mostLikedRef);

      const sliderRef = ref(db, `storySlider/${id}`);
      const sliderSnapshot = await get(sliderRef);
      if (editorsChoiceSnapshot.exists()) {
        await update(editorsChoiceRef, { isComplete: true });
      }
      if (bookofMonthSnapshot.exists()) {
        await update(bookofMonthRef, { isComplete: true });
      }
      if (mostLikedSnapshot.exists()) {
        await update(mostLikedRef, { isComplete: true });
      }
      if (sliderSnapshot.exists()) {
        await update(sliderRef, { isComplete: true });
      }
      setloadingstat(false)
      window.location.reload()
    } catch (error) {
      console.error("Error updating database:", error);
      setloadingstat(false)
    }
  };

  const formatTimestamp = (timestamp: number | undefined) => {
    if (!timestamp) return "";
  
    const date = new Date(timestamp * 1000);
  
    // Get day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
  
    // Construct the date string with day before month
    const formattedDate = `${month} ${day}, ${year}`;
  
    return formattedDate;
  };


const navigate = useNavigate()
  useEffect(() => {
    const fetchStoryDetails = async () => {
      try {
        const storyRef = ref(db, `allStories/${id}`);
        const snapshot = await get(storyRef);
        const storyData = snapshot.val();

        if (storyData) {
          setLoading(true)
          const coverURL = await getDownloadURL(
            sref(
              storageRef,
              `authersImageData/${storyData.autherEmail}/storiesArchive/${id}/cover/${storyData.cover}`
            )
          ).catch(() => {
            return storyData.title
          });
          const likeCountRef = ref(db, `allStories/${id}/Likes`);
          
          const commentCountRef = ref(db, `allStories/${id}/comment`);
          const likeCountSnapshot = await get(likeCountRef);
          const commentCountSnapshot = await get(commentCountRef);
          const likeCount = likeCountSnapshot.exists() ? Object.keys(likeCountSnapshot.val()).length : 0;
          const commentCount = commentCountSnapshot.exists() ? Object.keys(commentCountSnapshot.val()).length : 0;
          setcommentCounts(commentCount)

          setStory({
            id,
            ...storyData,
            coverURL,
            likeCount: likeCount,
            commentCountFinal:commentCount
          });

          // Set initial title for editing
          setEditedTitle(storyData.title);
          setLoading(false)
        }
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchStoryDetails();
  }, [id, storageRef]);

  // const storageRef = getStorage();
  const handleDelete = async () => {
    try {
      // Display a loading indicator
      setLoading(true);
  
      const storyRef = ref(db, `allStories/${story?.id}`);
      const storyRefAuthor = ref(db, `authers/${user?.id}/storiesArchive/${story?.id}`);
        
      // Uncomment and complete the condition if snapshot checking is required
      // if ((await snapshot).exists() && (await snapshot2).exists()) {
        const storagePath = `authersImageData/${user && user.email}/storiesArchive/${story?.id}/cover/${story?.id}_cover.jpeg`;
        const storyStorageRef = sref(getStorage(), storagePath);
      await deleteObject(storyStorageRef);
      // alert("storage deleted")
      remove(storyRef);
      remove(storyRefAuthor);
      
      // Delete files from storage
      
      // Navigate to the user's profile page after deletion
      await navigate(`/profile/${user?.id}`);
    } catch (error) {
      // Handle specific errors or provide a generic error message
      console.error('Error deleting story:', error);
    } finally {
      // Hide the loading indicator whether the operation is successful or not
      setLoading(false);
    }
  };





 

  return (
    <div className="select-none bg-gray-50 dark:bg-slate-800">
      {/* <Header /> */}
      {!story?(<><div className="flex justify-center items-center  h-screen"><Loading/></div></>):
    errorMessage?(<>{errorMessage}</>):
    ( <div  
    key={story.id}
    className={`carousel-card  `
    }>

       <div className="lg:flex  justify-evenly
      gap-12 shadow-3xl "
     style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${story.coverURL})`,
backgroundRepeat: "no-repeat",
backgroundSize: "cover",
backdropFilter: 'blur(25px)'
    }}
     >
      {story.cover ?
     <div className=" lg:pl-12 lg:py-12 lg:w-2/3  bg-transparent  ">
       <img
         src={story?.coverURL}
         className={`shadow-2xl  rounded-sm object-cover w-full ${loading ?"": "animate__animated animate__fadeInLeft"} `}
         alt=""
       />
     </div>
 :
 <div className="text-center">
   {story.autherId === user?.id ?
   <div>
<div className="text-black dark:text-white font-bold my-24">

 story will not be available for Readers if cover img is not present 
</div>
<div>

 <Link className="cursor-pointer text-black dark:text-white border p-4 rounded-md" to={`/addcover/${story.id}`}>Update cover img</Link>
</div>
   </div>
   : <div className=" lg:p-12 lg:w-2/3  bg-transparent  "><Skeleton className="h-full w-full" /></div>}
 </div>
}
     <div className="w-full  text-start flex flex-col py-10">
       <div className={`text-3xl pl-2.5 lg:pl-0 text-center lg:text-start md:text-xl lg:text-4xl ${loading ? "" :"animate__animated animate__fadeInFromLeft"} text-white font-bold  font-1`}>
         {story?.title.toUpperCase()}
       </div>
       <div className="text-base pl-2.5 lg:pl-0 text-center lg:text-start  md:text-md lg:text-lg font-bold text-white  font-2 mt-4">
         Author: &nbsp;<span className="font-normal font-2">{story?.autherName.toUpperCase()}</span> 
       </div>
       <div className="text-base pl-2.5 lg:pl-0 text-center lg:text-start md:text-md xl:text-lg font-bold text-white font-2 mt-4 lg:flex  gap-5">
        <div className="mt-1">
         Published: &nbsp; <span className="font-normal font-2"> {formatTimestamp(story?.timestamp)}</span>
        </div>
      

  
<div className="text-black lg:mt-1 flex items-center justify-center mt-4 gap-12 font-bold font-2 ">
         <div className="flex gap-2 ">
           <div className="rounded-full  bg-gray-50 dark:bg-gray-100 ">
           <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 " version="1.1" viewBox="0.00 0.00 2048.00 1024.00">
<g fill="#000000">
<path d="
  M 1059.38 719.04
  C 1049.67 742.00 1042.74 766.30 1038.58 791.93
  C 1038.52 792.30 1038.39 792.33 1038.19 792.01
  C 1037.61 791.08 1037.32 790.07 1037.32 789.00
  C 1037.26 626.51 1037.26 471.68 1037.33 324.50
  C 1037.33 320.88 1037.80 317.21 1039.09 313.86
  C 1063.46 250.55 1100.97 194.85 1152.68 151.83
  C 1179.77 129.30 1209.95 110.50 1243.20 95.44
  C 1252.63 91.17 1262.85 87.36 1273.84 84.01
  C 1276.23 83.28 1278.58 82.93 1280.88 82.95
  C 1291.61 83.02 1302.06 83.03 1312.23 82.97
  C 1312.60 82.97 1312.81 83.16 1312.84 83.53
  L 1353.06 533.01
  C 1353.23 534.92 1351.83 536.62 1349.92 536.81
  C 1341.71 537.62 1333.69 538.49 1325.88 539.41
  C 1296.53 542.86 1268.26 549.00 1241.07 557.81
  C 1159.65 584.21 1092.98 639.63 1059.38 719.04
  Z"
/>
<path d="
  M 1364.89 183.64
  C 1389.12 193.29 1410.90 203.60 1430.22 214.58
  C 1433.64 216.52 1434.56 219.86 1435.02 223.52
  C 1453.70 375.25 1471.35 517.73 1487.97 650.94
  C 1488.15 652.43 1488.27 653.83 1488.32 655.14
  C 1488.41 657.57 1486.67 657.86 1484.85 657.45
  C 1410.71 640.90 1331.84 635.42 1257.37 652.15
  C 1168.79 672.05 1090.79 724.51 1045.83 803.78
  C 1044.10 806.82 1042.36 809.91 1040.61 813.05
  C 1040.40 813.42 1040.18 813.43 1039.93 813.08
  C 1039.41 812.33 1039.28 811.53 1039.55 810.69
  C 1041.09 805.76 1042.63 801.04 1044.18 796.52
  C 1056.41 760.67 1074.87 726.23 1099.07 697.02
  C 1142.28 644.86 1203.59 608.97 1269.03 592.43
  C 1311.44 581.71 1356.85 577.99 1400.65 579.14
  C 1403.67 579.21 1405.28 578.57 1405.00 575.25
  C 1404.58 570.17 1404.12 565.22 1403.61 560.41
  C 1390.38 434.66 1377.23 309.17 1364.17 183.96
  C 1364.15 183.74 1364.32 183.56 1364.54 183.57
  C 1364.67 183.58 1364.78 183.60 1364.89 183.64
  Z"
/>
<path d="
  M 846.40 689.26
  C 810.95 674.94 773.65 664.98 734.48 659.37
  C 676.86 651.12 617.90 651.54 557.59 660.63
  C 555.46 660.95 553.35 661.09 551.24 661.05
  C 550.91 661.04 550.60 660.92 550.36 660.69
  C 549.54 659.90 549.21 658.98 549.36 657.93
  C 569.58 521.67 591.50 372.67 615.13 210.94
  C 615.81 206.32 618.10 204.72 622.60 203.93
  C 638.54 201.14 653.38 199.05 667.13 197.66
  C 710.86 193.25 754.54 195.42 798.16 204.17
  C 877.54 220.09 949.13 259.34 1008.14 314.96
  C 1010.21 316.91 1010.99 318.92 1010.99 321.96
  C 1011.00 483.37 1011.00 644.31 1010.99 804.76
  C 1010.99 806.45 1010.41 806.68 1009.26 805.44
  C 1007.73 803.81 1006.25 802.13 1004.80 800.40
  C 963.61 751.02 906.59 713.57 846.40 689.26
  Z"
/>
<path d="
  M 1522.04 704.11
  C 1522.85 704.27 1523.21 703.94 1523.12 703.13
  C 1521.87 690.72 1520.46 678.83 1518.91 667.47
  C 1513.96 631.22 1509.04 594.97 1503.48 558.85
  C 1490.83 476.76 1478.68 394.39 1467.02 311.74
  C 1466.97 311.43 1467.10 311.31 1467.41 311.39
  C 1474.70 313.30 1482.18 317.41 1488.36 321.35
  C 1489.58 322.13 1490.29 323.20 1490.50 324.55
  C 1513.37 474.02 1537.09 629.10 1561.66 789.79
  C 1561.99 791.94 1562.15 793.99 1562.13 795.92
  C 1562.13 796.27 1561.97 796.38 1561.64 796.23
  C 1484.07 761.30 1399.96 742.26 1315.38 744.11
  C 1216.60 746.26 1125.06 772.31 1040.77 822.26
  C 1040.56 822.38 1040.31 822.18 1040.40 821.95
  C 1040.98 820.48 1041.80 819.16 1042.85 817.99
  C 1068.40 789.48 1099.01 766.30 1132.57 747.43
  C 1244.51 684.50 1378.07 677.13 1502.25 700.33
  C 1508.92 701.58 1515.52 702.84 1522.04 704.11
  Z"
/>
<path d="
  M 524.24 703.80
  C 529.88 703.90 534.68 702.51 540.15 701.45
  C 596.85 690.50 652.62 686.45 707.45 689.31
  C 775.73 692.86 843.63 709.93 903.98 741.10
  C 938.89 759.13 970.15 782.03 997.77 809.80
  C 1001.21 813.26 1004.31 816.88 1007.08 820.66
  C 1007.59 821.35 1007.46 821.49 1006.71 821.07
  C 972.08 801.65 936.45 785.71 899.80 773.25
  C 814.07 744.11 726.76 736.64 637.87 750.84
  C 586.09 759.12 534.94 774.67 486.02 795.85
  C 485.80 795.94 485.57 795.76 485.60 795.53
  C 486.49 789.49 487.36 783.74 488.19 778.27
  C 505.92 661.61 525.56 533.26 547.11 393.23
  C 550.36 372.17 553.64 350.59 556.95 328.50
  C 557.56 324.41 559.35 321.63 562.32 320.18
  C 568.21 317.27 573.57 311.83 580.35 311.67
  C 580.89 311.66 581.12 311.91 581.04 312.44
  L 523.75 703.22
  C 523.70 703.52 523.93 703.79 524.24 703.80
  Z"
/>
</g>
</svg>
           </div>
           <div className="text-white">: {formatNumber(story?.viewCount) || 0}</div>
         </div>

         <div className="flex gap-2">
         <div className="rounded-full  bg-gray-50 ">
          <i className="fa-sharp fa-solid fa-thumbs-up px-1.5 mt-0.5"></i>
           </div>
           <div className="text-white">: {formatNumber(story.likeCount) || 0}</div>
         </div>

         <div className="flex gap-2">
           <div className="rounded-full  bg-gray-50 ">
             <i className="fa-sharp fa-solid fa-comment px-2 mt-2"></i>
           </div>
           <div className="text-white">: {formatNumber(commentCounts) || 0}</div>
         </div>

         {/* <div className="flex gap-2">
           <div>
             <i className="fa-sharp fa-solid fa-share "></i>
           </div>
           <div>Share</div>
         </div>

         

         <div className="flex gap-2">
           <div>
             <i className="fa-sharp fa-solid fa-flag "></i>
           </div>
           <div>Report</div>
         </div> */}
       </div>
       </div>
       {/* {story.isExclusive} */}
       <div className="flex justify-center lg:justify-start flex-wrap pl-2.5 lg:pl-0 text-white text gap-4 mt-4">
       {story?.isExclusive && <h1 className="bg-blue-900 font-normal font-2 p-2 rounded-md text-lg lg:text-md text-center mt-2">Likhasi Exclusive</h1>}
  <h1 className="bg-[#1F456E] p-2 font-normal font-2 rounded-md text-lg lg:text-md text-center mt-2">{story?.category}</h1>
  {story?.isComplete &&<h1 className="bg-[#177245] p-2 rounded-md text-lg lg:text-md text-center mt-2">Complete</h1>}
  {story?.is18 &&<h1 className="bg-red-900 p-2 font-normal font-2 rounded-md text-lg lg:text-md text-center mt-2">Mature</h1>}
  {story?.islgbt &&<h1 className="bg-pink-600 p-2 font-normal font-2 rounded-md text-lg lg:text-md text-center mt-2">LGBTQIA+</h1>}
  
</div>
<div className="mt-4  gap-4 pl-2.5 lg:pl-0">

<div className="flex gap-4">
<div className="font-1  font-semibold text-white pt-0.5">Tags:</div>
<div className="w-full flex flex-wrap gap-2">
  {story.tags.split(' ').map((tag, index) => (
    <div key={index} className="flex bg-gray-500 rounded-md px-2">
        <div className=" text-xl   font-bold mx-1 text-white">#</div>
        <div className=" text-sm  my-1 text-white font-bold" >{tag.toUpperCase()} </div>
    </div>
  ))}
</div>

</div>
  {/* {story?.tags.map((tag, index) => (
    <div className="flex bg-gray-500 rounded-md px-2">

    <div className=" text-xl   font-bold mx-1 text-white">#</div>
    <div className=" text-sm  my-1 text-white" key={index}>{tag.toUpperCase()} </div>
    </div>
    ))} */}
    </div>
       <div className="text-white pl-2 pr-2  lg:pl-0 lg:pr-4  font-2 flex-1 auto mt-4 ">
       {story.summary}
       </div>
       <div className=" flex flex-col mb-1">

       
<div className="text-white mt-4 lg:flex gap-12 font-bold font-2 ">
  {/* <div className="flex gap-2">
    <div>
      <i className="fa-sharp fa-solid fa-eye"> </i>
    </div>
    <div>: {story?.viewCount || 0}</div>
  </div>

  <div className="flex gap-2">
    <div>
      <i className="fa-sharp fa-solid fa-thumbs-up "></i>
    </div>
    <div>: {story.likeCount || 0}</div>
  </div>

  <div className="flex gap-2">
    <div>
      <i className="fa-sharp fa-solid fa-comment "></i>
    </div>
    <div>: {commentCounts || 0}</div>
  </div> */}
  <div className="">

<Like2 storyID={story.id} storyName={story.title} autherID={story.autherId} class="lg:text-2xl text-white "/>
  </div>

<AddtoBookshelf storyID={story.id}/>

<Share storyId={story.id} chapterId={chapterId}/>
  

  

<div
className="font-bold bg-[#002e7200] mt-4 my-anchor-element4  lg:mt-0 hover:bg-blue-900 flex gap-4 p-3 text-md rounded-md border-[1px] border-solid border-blue-900 cursor-pointer  hover:text-white"
onClick={() => navigate(`/report/${story.id}`)}
>
<div className="">
<i className="fa-sharp fa-solid fa-flag"></i>
</div>
<div>Report</div>
</div>
<Tooltip  anchorSelect=".my-anchor-element4" className=''  place="top" style={{ backgroundColor: 'white', color: 'black' }}>
        <div style={{ whiteSpace: "normal" }}>{"Report"}</div>
      </Tooltip>
</div>

</div>
      
       
     </div>
   </div>
     
      <div className="flex flex-col items-center mx-auto">
       
      {showConfirmation && (
       <div
      className={`fixed top-0 left-0 right-0 bottom-0 z-10 flex justify-center items-center bg-black bg-opacity-90 `}
      style={{ transform: `translateY-100%` }}
    >
      <div className="bg-white dark:bg-slate-800 lg:w-1/2 p-10">
      <div className='text-9xl text-yellow-500 text-center'>
            <i className="fa-solid fa-triangle-exclamation"></i>
            </div>
        <p className="mt-4 font-1 font-bold text-black dark:text-gray-100">{"You will not be able to write it further. Are you sure, you want to proceed?"}</p>
        <div className="flex gap-4 mt-4">
          <button
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            onClick={handleSubmit}
          >
            {loadingstat ? 'Submitting...' : 'Submit'}
          </button>
          <button
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
      )}

        <div className="w-full lg:px-24 my-4">
          <div className="lg:flex px-2 lg:px-0 justify-between">

          
          <div className="">
            {user?.id === story?.autherId &&
            <div>

            {!story?.isExclusive ? (
              <IsExclusiveForm
              autherId={story?.autherId}
              isExclusive={story?.isExclusive}
              storyId={story?.id}
              authorEmail={story?.autherEmail}
              storytitle={story?.title}
              />
              ) : (
                <></>
                )}
                </div>
              }
          </div>








          <div className="">
          {
            user?.id === story?.autherId &&
            <>
        {!story.isComplete &&
        <>
     
        {story.category === "Poem" ?<></>: 
      
      
      
      <button className={"bg-blue-700 text-white rounded-md py-1.5 px-4 w-full lg:w-auto mt-4 lg:mt-0 text-lg hover:bg-blue-900 cursor-pointer " }
       onClick={()=>setShowConfirmation(true)}>Mark as completed</button>}
        </>
           
          }
            </>}
            </div>
            </div>
            <div className="w-full text-black flex lg:text-2xl justify-end lg:p-4">
        <div className="flex gap-2 px-2 lg:px-0">




        </div>
      </div>
          <div className="lg:my-24 shadow-md bg-white rounded-xl">
          <ChapterIndex autherId={story?.autherId} currentChapterId={chapterId} storyId={story.id} />
           {
             user?.id === story?.autherId ?
             <div>
            {story.isComplete || [story.chapters].length >= 15? <></>:
            <div className="w-full flex justify-center gap-4 bg-white dark:bg-slate-900 py-4">
              {chapters.length === 0 &&
              <div>

<div className="font-bold bg-[#002e7200] text-blue-900 dark:text-slate-100 hover:bg-blue-900  flex  text-center items-center gap-4 p-3 text-lg rounded-md border-[1px] border-solid border-blue-900 cursor-pointer  hover:text-white " onClick={handleDelete}>Delete Story</div>
              </div>
}
{/* } */}
       
              <div className="font-bold bg-[#002e7200] text-blue-900 dark:text-slate-100 hover:bg-blue-900  flex  text-center items-center gap-4 p-3 text-lg rounded-md border-[1px] border-solid border-blue-900 cursor-pointer  hover:text-white " onClick={()=>navigate(`/addchapter/${story.id}`)}>Add Next Chapter</div>
            </div>
            }
            </div>:<></>
          }
          
          </div>
          <div className="w-full">

          <div className="font-1 font-semibold text-2xl text-black flex gap-4 dark:text-white mt-4 px-2 lg:mt-0 lg:px-0">
            <div className="">
             Comments
            </div>
            <div>
            <Info props={"You can only comment once"} />

            </div>
             </div>
          <Comment storyName={story.title} storyID={story.id} autherID={story.autherId}  />
          </div>
          <AllComments autherID={story.autherId} storyID={story.id}storyName={story.title} />
          {/* <Like storyName={story.title} storyID={story.id} autherID={story.autherId} /> */}
        
           
        </div>
        <div className="w-full lg:px-24">
        <div className="w-full rounded-md h-[2px] bg-gray-400">

</div>
        </div>
        <div className="font-1 w-full  lg:px-24 pt-4 text-start font-semibold text-2xl text-black dark:text-white px-2 lg:pt-0 ">
          More from the author :
        </div>
        <div className="w-full lg:px-16 pb-5">

        <StoryCardForSynopsis storyType={`authers/${story.autherId}/storiesArchive/`}/>
        </div>
        
      </div>
      </div>)}
      <Footer/>
    </div>
  );
};

export default Synopsis;