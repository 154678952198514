import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useAppSelector } from "../hooks/storeHook";

const TOUR_STEPS = [
  
  // {
  //   target: ".lateAuthers",
  //   content:
  //     "Discover the global rank of authors. ",
  //     disableBeacon: true

  // },
  {
    target: ".profile",
    content:
      "Discover your readers, bookshelf & more. ",
      disableBeacon: true
  },
  {
    target: ".notifications",
    content:
      "Notifications"
  },
  {
    target: ".write",
    content: " For every author ",
    // disableBeacon: true // This makes the tour to start automatically without clicking
  },
  {
    target: ".genres",
    content:
      "Choose your part of the journey"
  },
  {
    target: ".latestRealease",
    content:
      "LatestRealease"
  },
  
 
  
  {
    target: ".latestAuthers",
    content:
      "Discover the global rank of authors. "
  },
  {
    target: ".SocialMedia",
    content: "Follow us for updates"
  },
];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action:any) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date()
      };
    default:
      return state;
  }
};

// Tour component
const Tour = () => {
  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  const {user}=useAppSelector((state)=>state.auth)
  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    const userBirthdate = user?.userData?.birthdate;
    if (userBirthdate) {
      const birthDateEpoch = new Date(userBirthdate).getTime(); 
      const currentDateEpoch = new Date().getTime();
      const userAgeMilliseconds = currentDateEpoch - birthDateEpoch;
      const userAgeYears = Math.floor(userAgeMilliseconds / (365.25 * 24 * 60 * 60 * 1000)); // Approximate years
    const verify =localStorage.getItem('ageVerification' )
    const newtour= localStorage.getItem("tour")
      if (verify && !newtour) {
        dispatch({ type: "START" });
      }
    }
  }, []);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    localStorage.setItem("tour", "1");
  };

  const callback = (data:any) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
      });
    }
  };

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before after a delay of 15 seconds
    const startTourAfterDelay = setTimeout(() => {
      if (!localStorage.getItem("tour")) {
        dispatch({ type: "START" });
      }
    }, 5000); // 15 seconds in milliseconds

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(startTourAfterDelay);
  }, []);


  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  return (
    <>
      {/* <li className="items-center tour-link" onClick={startTour}>
        <button className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block">
          <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{" "}
          Start tour
        </button>
      </li> */}
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{
          tooltipContainer: {
            textAlign: "center"
          },
          buttonBack: {
            background: "#002D72",
            color:"white",
            borderRadius:"4px",
            marginRight: 10
          },
          buttonNext:{
            background: "#002D72",
            color:"white",
            borderRadius:"4px",
            marginRight: 10
          }
        }}
        locale={{
          last: "End tour"
        }}
      />
    </>
  );
};

export default Tour;
