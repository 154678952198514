import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "../hooks/storeHook";

import logo from "../assets/Logo3.svg";
const Footer = () => {
  const navigate = useNavigate();
  const router = useLocation();
  const isActiveLink = (link: any) => link === router.pathname;

  const { user } = useAppSelector((state) => state.auth);

  return (
    <footer className="bg-[#002D72] text-white font-1 select-none">
      <div className="lg:flex justify-between gap-4 p-10">
        <div
          className="flex flex-col justify-center items-center lg:w-2/3 cursor-pointer "
          onClick={() => navigate("/")}
        >
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full h-12 -mt-1 lg:mt-2 lg:h-16 lg:my-2"
            viewBox="0 0 285.14 70.29"
          >
            <g>
              <path
                d="M20,19a1.76,1.76,0,0,1-.95.45c-1,0-3-1-4.3-2-.91-.65-2.4-1.88-2.72-3.26-.11-.48.33-1.81.38-1.45a7.65,7.65,0,0,0,2.89,3.92A21.37,21.37,0,0,0,20,19Z"
                fill="#f9f9f9"
              />
              <path
                d="M21.48,17.48a1.78,1.78,0,0,1-1,.49c-1.12,0-3.24-1.13-4.63-2.14-1-.7-2.58-2-2.91-3.52-.12-.52.35-2,.39-1.56A8.45,8.45,0,0,0,16.43,15C18.86,16.82,21.62,17.34,21.48,17.48Z"
                fill="#f9f9f9"
              />
              <path
                d="M27.94,69.93c.29.15.28.23-.05.23-1.13,0-4.63.19-5.46-.49C10.67,60.07,16,45,21.73,34.08q4-7.51,7.92-15C31.83,14.93,34.1,8,29.14,4.9c-4.17-2.57-9,3.66-11,6.35a2.82,2.82,0,0,1-1.47,1.12,2.39,2.39,0,0,1-2.38-.73.35.35,0,0,1-.08-.39C15.49,7.4,17.89,2.62,21.74.88q5.42-2.46,9.92,2c4.07,4,4.3,9.71,1.81,14.78q-5.22,10.58-10.65,21a33.79,33.79,0,0,0-4.2,17.76Q19.1,65.51,27.94,69.93Z"
                fill="#f9f9f9"
              />
              <path
                d="M13.46,42Q19.34,29.73,26,17.86a10.92,10.92,0,0,0,1.42-5.15,2.17,2.17,0,0,0-2.28-2.38c-1.67.22-3.88,3.8-4.53,5.18a.47.47,0,0,1-.79.08c-2.3-2.95,5.7-11.43,9.09-7.78,1.78,1.92,1.35,5.66.25,7.79C23.29,27,15,38.43,12.68,51.32c-1.24,6.93.62,14.18,6.62,18.31.47.33.42.46-.15.38-10.43-1.3-10.9-14-8.46-21.44A60.37,60.37,0,0,1,13.46,42Z"
                fill="#f9f9f9"
              />
              <path
                d="M50.65,50.46a.34.34,0,0,0,.14-.45L41.46,30.08a.54.54,0,0,0-.77-.24C33.61,34,29,40.28,25.89,47.89c0,0,0,0,0,0h0c2.66-8.48,8.16-17.45,16.52-21a.56.56,0,0,1,.72.27L54.91,50.55a.17.17,0,0,1-.12.25c-7.69,1.59-16.81,6.08-21.14,13a.09.09,0,0,1-.09,0h0a0,0,0,0,1,0,0,10,10,0,0,1,.73-1.39A28.35,28.35,0,0,1,49.82,50.81,4.32,4.32,0,0,0,50.65,50.46Z"
                fill="#f9f9f9"
              />
              <path
                d="M60,52.89a.18.18,0,0,0,.1-.24L51,33.52a.66.66,0,0,0-.7-.38l-1.71.26a.28.28,0,0,1-.32-.16l-.71-1.37a.43.43,0,0,1,.27-.62l4.32-1.14a.64.64,0,0,1,.81.37L64.77,54a.28.28,0,0,1-.23.41c-9.63.82-20.83,3.34-28.32,9.87-.39.33-.41.31-.06-.07,5.53-6,14.92-9.85,22.84-11A4.74,4.74,0,0,0,60,52.89Z"
                fill="#f9f9f9"
              />
              <path
                d="M60.36,57.83l-.24-1.29c0-.19,0-.29.23-.32l4-.48a.18.18,0,0,1,.19.11L66,59.64a.08.08,0,0,1-.08.11,57.69,57.69,0,0,0-28.86,5.92h0L37,65.55a.08.08,0,0,1,0-.13c6.89-4.28,15.17-6.26,23.14-7.31A.23.23,0,0,0,60.36,57.83Z"
                fill="#f9f9f9"
              />
              <path
                d="M33.07,70.28s0,0,0,0l-.06-.1A.11.11,0,0,1,33,70c7.9-5.59,18.82-7.66,28.16-8.29a.66.66,0,0,1,.74.48l.7,2c.12.33,0,.49-.35.47A58.9,58.9,0,0,0,33.07,70.28Z"
                fill="#f9f9f9"
              />
              <path
                d="M11.16,14.78s-3.41,3.8-5.7,6.42a14.22,14.22,0,0,1-3.52,3.05s-.21,6.33-.52,7.87c-.15.71-.4,1.73-.62,2.58A1.05,1.05,0,0,0,.19,35a.87.87,0,0,0,0,1.15C2.3,33.43,4.37,30.69,6.42,28a2,2,0,0,1,.07-1.27.89.89,0,0,1,.68-.34.89.89,0,0,1,.7,1.43c-.23.29-.93.34-1.25.34-2,2.75-4.19,5.39-6.17,8.16a1,1,0,0,0,.44.11.88.88,0,0,0,.68-.33,1.22,1.22,0,0,0,.14-.74c.66-.4,1.52-.9,2.38-1.39,2.41-1.37,7.52-2.18,7.52-2.18a11.44,11.44,0,0,1,1.5-4c1.57-2.44,4.69-7.6,4.69-7.6a14.13,14.13,0,0,1-4-2A11,11,0,0,1,11.16,14.78Z"
                fill="#f9f9f9"
                fill-rule="evenodd"
              />
              <path
                d="M172.78,46.76l-.57-.6q2.32-8.11,7-12.55a14.6,14.6,0,0,1,10.21-3.9,7.76,7.76,0,0,1,6.27,3c1.69,2,2.52,4.84,2.52,8.52V70.29H187V42q0-8.79-3.37-8.79c-1.77,0-3.79,1.63-6.08,4.91a32.91,32.91,0,0,0-4.78,10.43Z"
                fill="#f9f9f9"
              />
              <path
                d="M136.21,12.27l.1,57a1,1,0,0,1-1.05,1.05l-6.63,0a.7.7,0,0,1-.78-.8l0-50.94a2.44,2.44,0,0,0-.44-1.39,4,4,0,0,0-1.9-1.43c-.43-.14-.43-.3,0-.45l10.07-3.47a.5.5,0,0,1,.65.48Z"
                fill="#f9f9f9"
              />
              <path
                d="M173.58,13.35l0,56a.83.83,0,0,1-.92.94l-6.89,0a.7.7,0,0,1-.79-.78l0-50.34a2.09,2.09,0,0,0-.74-1.55,4.09,4.09,0,0,0-1.6-.76.55.55,0,0,1-.07-1l10-3.22c.7-.22,1.05,0,1.05.76Z"
                fill="#f9f9f9"
              />
              <path
                d="M80.27,68.77a39.85,39.85,0,0,0,24.26-7.26.51.51,0,0,1,.81.41l.12,7.48a.8.8,0,0,1-.9.89l-32.76,0a.72.72,0,0,1-.82-.82l-.06-56a.46.46,0,0,1,.45-.45l7.66,0a.44.44,0,0,1,.43.45l0,54.54a.78.78,0,0,0,.86.88Z"
                fill="#f9f9f9"
              />
              <path
                d="M146.53,29.91a1,1,0,0,0-.67,1.71A4,4,0,0,1,147,34.43a1.6,1.6,0,0,1-.27.94l-7.36,10-.11.09a15,15,0,0,0-2,2c-1.5,1.82-1.08,2.26,1.25,1.35q5.61-2.18,7.85,4.36c1.94,5.62,3.65,10.94,5.15,15.92a1.49,1.49,0,0,0,1.6,1.09l7.21,0a1,1,0,0,0,1-1.23c-2.52-6.74-5.06-18.29-10.6-23.49a2.8,2.8,0,0,0-1.63-.72c-1.38-.12-2.74-.21-4.09-.26h0c-1,0-1.83-.05-2.5-.05l14.86-13.35c.67-.61.57-.93-.29-.94Z"
                fill="#f9f9f9"
              />
              <path
                d="M246.77,34.54c-2.86,5,3.16,8.48,7.27,10,7.42,2.7,24.29,9,16.79,20.52-5.29,8.13-20.76,5.45-27.67,1.2A2.88,2.88,0,0,1,242,62.6l3.2-7.36a.69.69,0,0,1,1.21-.1c4.48,7.06,10.37,12,19.17,12.44a2.44,2.44,0,0,0,2.83-2.42C269.49,55.91,245,55.39,242.77,43c-2.75-15.26,19.34-13.67,27.31-9.47a1.68,1.68,0,0,1,.73,2.19l-3.1,6.64a.9.9,0,0,1-1.5.19c-3.73-4.44-12-11-18.27-9A2,2,0,0,0,246.77,34.54Z"
                fill="#f9f9f9"
              />
              <path
                d="M121,24.06a4.84,4.84,0,1,1-4.84-4.84A4.84,4.84,0,0,1,121,24.06Z"
                fill="#f9f9f9"
              />
              <path
                d="M111,35.78a3.21,3.21,0,0,1-1-.48c-.71-.5-.65-.82.23-.93A29.27,29.27,0,0,0,120,31.13a.56.56,0,0,1,.82.49V69.46a.73.73,0,0,1-.83.83l-6.77-.05a.71.71,0,0,1-.78-.8l0-31.84A1.89,1.89,0,0,0,111,35.78Z"
                fill="#f9f9f9"
              />
              <path
                d="M285.14,24.06a4.85,4.85,0,1,1-4.85-4.84A4.85,4.85,0,0,1,285.14,24.06Z"
                fill="#f9f9f9"
              />
              <path
                d="M275.07,35.78a3.11,3.11,0,0,1-1-.48c-.72-.5-.66-.82.22-.93a29.32,29.32,0,0,0,9.8-3.24.56.56,0,0,1,.81.49l0,37.84a.73.73,0,0,1-.83.83l-6.77-.05a.7.7,0,0,1-.78-.8l0-31.84a1.88,1.88,0,0,0-1.4-1.82Z"
                fill="#f9f9f9"
              />
              <path
                d="M213.09,36.61c-1.34.87-2.49,2.31-2.16,3.65.26,1.08,1.75,2.11,3,2.5l3.16,1a3.37,3.37,0,0,0,2.32-.08l7.38-3c3-1.23.69-5.35-2.53-6.48C219.94,32.67,216.06,34.79,213.09,36.61Zm7.09,31.81c-.61.08,3-2,3.6-2.35,7.63-5,2.19-15.31-4.31-19.75-.62-.47.34-.59.45-.77s.3-.3.47-.19c2.16,1.45,6.35,4.7,7.76,6.86,0,0-.12-1-.12-2.28l0-5.43c0-.79.29-3.44-.39-3-1.29.75-2.61,1.5-4.08,2.46A38.88,38.88,0,0,1,220,46.62a18.33,18.33,0,0,0-8.84,10.48c-2.14,6,.73,10.94,7.25,11.18a6.92,6.92,0,0,0,2.63-.61c.58-.21.8.31,1.5.35a2.32,2.32,0,0,1-.79.51C212,72.82,194.64,68,205,54.44a27.42,27.42,0,0,1,9.59-7.67c2.2-1.49,2.64-1.59-.15-2.18L203.81,41.9a1.52,1.52,0,0,1-1.08-2.1c3.39-7.81,12.52-10.71,20.34-8.65,5.13,1.33,7.14,6.19,8.4,11.58q3.18,13.5,6.43,26.35c.19.75-.09,1.11-.87,1.13l-6.58.08a1,1,0,0,1-1.1-1l-.26-6.94c0-.94-.28-1-.75-.19a15.87,15.87,0,0,1-6,6Z"
                fill="#f9f9f9"
              />
            </g>
          </svg>
        </div>
        <div className="flex w-full lg:px-10 text-center items-center  lg:gap-4 gap-14 mt-10 lg:mt-0 justify-between text-[1.7vh] lg:text-lg">
          <div className="flex-col w-full text-start">
            <div
              className="hover:text-[#F8F6F0] text-[#f8f6f09e] font-semibold hover:font-bold mt-4 cursor-pointer"
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              Home
            </div>
            <div
              className="hover:text-[#F8F6F0] text-[#f8f6f09e] font-semibold hover:font-bold mt-4 cursor-pointer"
              onClick={() => {
                navigate(`/allauthors/${user?.id}`);
                window.scrollTo(0, 0);
              }}
            >
              Top Listed Authors
            </div>
            <div
              className="hover:text-[#F8F6F0] text-[#f8f6f09e] font-semibold hover:font-bold mt-4 cursor-pointer"
              onClick={() => {navigate("/lateststories");window.scrollTo(0, 0);}}
            >
              Latest Release
            </div>
          </div>

          <div className="flex-col w-full text-start">
            <div
              className="hover:text-[#F8F6F0] text-[#f8f6f09e] font-semibold hover:font-bold mt-4 cursor-pointer"
              onClick={() => {navigate(`/profile/${user?.id}`);window.scrollTo(0, 0);}}
            >
              Profile
            </div>
            <div
              className="hover:text-[#F8F6F0] text-[#f8f6f09e] font-semibold hover:font-bold mt-4 cursor-pointer"
              onClick={() => {navigate("/aboutus");window.scrollTo(0, 0);}}
            >
              About us
            </div>
            <div
              className="hover:text-[#F8F6F0] text-[#f8f6f09e] font-semibold hover:font-bold mt-4 cursor-pointer"
              onClick={() => {navigate("/help&support");window.scrollTo(0, 0);}}
            >
              Help & Support
            </div>
          </div>
        </div>

        <div className="flex-col w-full lg:px-10 mt-10 lg:mt-0 hidden lg:block SocialMedia">
          <h1 className="text-2xl mb-4 style2">EXPLORE WITH LIKHASI</h1>
          <div className="flex justify-between py-4">
            <a
              href="https://www.facebook.com/profile.php?id=61554544033149"
              className="social"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 cursor-pointer"
                viewBox="0 0 18 18"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.426 17.647H.974A.974.974 0 010 16.673V.974C0 .436.436 0 .974 0h15.7c.537 0 .973.436.973.974v15.699a.974.974 0 01-.974.974h-4.497v-6.834h2.294l.343-2.663h-2.637v-1.7c0-.772.214-1.297 1.32-1.297h1.41V2.77a18.853 18.853 0 00-2.055-.105c-2.033 0-3.425 1.241-3.425 3.52V8.15h-2.3v2.663h2.3v6.834z"
                ></path>
              </svg>
            </a>

            <a href="https://twitter.com/Likhasiworks" className="social">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 cursor-pointer"
                viewBox="0 0 18 18"
              >
                <path d="M3.5 5.1l3.8 5L4 13.6c-1.9 1.8-3.1 3.6-2.8 3.9.3.3 1.7-.8 3.1-2.5 3.2-3.8 4.1-3.8 6.4 0 1.3 2.2 2.5 3 4.5 3H18l-3.6-5-3.7-5.1L13.9 4c1.8-2.2 2.8-4 2.3-4-.4 0-1.8 1.3-3 3-1.3 1.6-2.6 3-3 3-.4 0-1.6-1.4-2.7-3C6.1.9 4.7 0 2.7 0H-.2l3.7 5.1zm6.3 3.3c5.3 7.6 5.8 8.6 4.4 8.6C13.4 17 3 2.7 3 1.6c0-2 2.3.3 6.8 6.8z"></path>
              </svg>
            </a>

            <a
              href="https://www.instagram.com/likhasi.works/"
              className="social"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 cursor-pointer"
                viewBox="0 0 18 18"
              >
                <path
                  fill-rule="evenodd"
                  d="M15.441 15.993H2.206a.552.552 0 01-.552-.552V7.17H3.86c-.287.414-.384 1.185-.384 1.675 0 2.953 2.408 5.356 5.368 5.356 2.96 0 5.368-2.403 5.368-5.356 0-.49-.069-1.25-.425-1.675h2.206v8.272a.552.552 0 01-.552.552M8.844 5.458a3.39 3.39 0 013.394 3.386 3.39 3.39 0 01-3.394 3.386A3.39 3.39 0 015.45 8.844a3.39 3.39 0 013.393-3.386m4.391-3.252h1.655c.304 0 .551.247.551.551v1.655a.552.552 0 01-.551.551h-1.655a.552.552 0 01-.551-.551V2.757c0-.304.247-.551.551-.551M15.55 0H2.098A2.095 2.095 0 000 2.093v13.461c0 1.156.94 2.093 2.098 2.093h13.451a2.095 2.095 0 002.098-2.093V2.093C17.647.937 16.707 0 15.549 0"
                ></path>
              </svg>
            </a>

            <a
              href="https://www.linkedin.com/company/likhasi/"
              className="social"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                className="h-7 -mt-0.5"
              >
                {" "}
                <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z" />
              </svg>
            </a>
          </div>
          <div className="w-full h-0.5 bg-[#f8f6f09e]"></div>
          <div className="flex gap-4 pt-2 text-[10px]">
            <div
              className="opacity-30 cursor-pointer"
              onClick={() => {navigate("/privacypolicy");window.scrollTo(0, 0);}}
            >
              Privacy Policy
            </div>
            <div
              className="opacity-30 cursor-pointer"
              onClick={() => {navigate("/terms&conditions");window.scrollTo(0, 0);}}
            >
              Terms & Conditions
            </div>
          </div>
        </div>

        <div className="flex-col w-full lg:px-10 mt-10 lg:mt-0 block lg:hidden SocialMedia">
          <h1 className="style2 text-xl mb-4">EXPLORE WITH LIKHASI</h1>
          <div className="flex lg:gap-8 justify-between  py-4">
            <a
              href="https://www.facebook.com/profile.php?id=61554544033149"
              className="social"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 cursor-pointer"
                viewBox="0 0 18 18"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.426 17.647H.974A.974.974 0 010 16.673V.974C0 .436.436 0 .974 0h15.7c.537 0 .973.436.973.974v15.699a.974.974 0 01-.974.974h-4.497v-6.834h2.294l.343-2.663h-2.637v-1.7c0-.772.214-1.297 1.32-1.297h1.41V2.77a18.853 18.853 0 00-2.055-.105c-2.033 0-3.425 1.241-3.425 3.52V8.15h-2.3v2.663h2.3v6.834z"
                ></path>
              </svg>
            </a>

            <a href="https://twitter.com/Likhasiworks" className="social">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 cursor-pointer"
                viewBox="0 0 18 18"
              >
                <path d="M3.5 5.1l3.8 5L4 13.6c-1.9 1.8-3.1 3.6-2.8 3.9.3.3 1.7-.8 3.1-2.5 3.2-3.8 4.1-3.8 6.4 0 1.3 2.2 2.5 3 4.5 3H18l-3.6-5-3.7-5.1L13.9 4c1.8-2.2 2.8-4 2.3-4-.4 0-1.8 1.3-3 3-1.3 1.6-2.6 3-3 3-.4 0-1.6-1.4-2.7-3C6.1.9 4.7 0 2.7 0H-.2l3.7 5.1zm6.3 3.3c5.3 7.6 5.8 8.6 4.4 8.6C13.4 17 3 2.7 3 1.6c0-2 2.3.3 6.8 6.8z"></path>
              </svg>
            </a>

            <a
              href="https://www.instagram.com/likhasi.works/"
              className="social"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 cursor-pointer"
                viewBox="0 0 18 18"
              >
                <path
                  fill-rule="evenodd"
                  d="M15.441 15.993H2.206a.552.552 0 01-.552-.552V7.17H3.86c-.287.414-.384 1.185-.384 1.675 0 2.953 2.408 5.356 5.368 5.356 2.96 0 5.368-2.403 5.368-5.356 0-.49-.069-1.25-.425-1.675h2.206v8.272a.552.552 0 01-.552.552M8.844 5.458a3.39 3.39 0 013.394 3.386 3.39 3.39 0 01-3.394 3.386A3.39 3.39 0 015.45 8.844a3.39 3.39 0 013.393-3.386m4.391-3.252h1.655c.304 0 .551.247.551.551v1.655a.552.552 0 01-.551.551h-1.655a.552.552 0 01-.551-.551V2.757c0-.304.247-.551.551-.551M15.55 0H2.098A2.095 2.095 0 000 2.093v13.461c0 1.156.94 2.093 2.098 2.093h13.451a2.095 2.095 0 002.098-2.093V2.093C17.647.937 16.707 0 15.549 0"
                ></path>
              </svg>
            </a>

            <a
              href="https://www.linkedin.com/company/likhasi/"
              className="social"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                className="h-7"
              >
                {" "}
                <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z" />
              </svg>
            </a>
          </div>
          <div className="w-full h-0.5 bg-[#f8f6f09e]"></div>
          <div className="flex gap-4 pt-2 text-[10px]">
            <div
              className="opacity-30 cursor-pointer"
              onClick={() => {navigate("/privacypolicy");window.scrollTo(0, 0);}}
            >
              Privacy Policy
            </div>
            <div
              className="opacity-30 cursor-pointer"
              onClick={() => {navigate("/terms&conditions");window.scrollTo(0, 0);}}
            >
              Terms & Conditions
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pb-4 text-[#f8f6f09e] text-base">
        &copy; LIKHASI 2024. All Rights Reserved.{" "}
      </div>
    </footer>
  );
};

export default Footer;
