import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

 const CustomCircularProgressbar = ({ country, value }: { country: string; value: any }) => (
  <div className="select-none">
    <CircularProgressbar
      value={value}
      className="h-12 lg:h-24"
      text={`${value}%`}
      styles={buildStyles({
        // textSize: "16px",
        pathColor: "#4CAF50",
        textColor: "#002D72",
        trailColor: "#eee",
      })}
    />
  </div>
);



export default CustomCircularProgressbar
