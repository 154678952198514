import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useNavigation } from "react-router-dom";
import { getDownloadURL, getStorage, ref as sref } from 'firebase/storage';
import { auth, db } from "../../firebase";
import { ref, onValue, off, get, runTransaction, query, limitToFirst, orderByChild, limitToLast } from "firebase/database";
import { Story } from "../../models/story/Story";
import AddtoBookshelf from "./AddtoBookshelf";
import Share from "./Share";
import { useAppSelector } from "../../hooks/storeHook";
import Header from "../Header/Header";
import Footer from "../Footer";
// 
import Loading from "../../pages/Home/Loading";
import { formatNumber } from "./useDebounceEffect";


const TopListedStories= () => {
  const [stories, setStories] = useState<Story[]>([]);
  const [chapterIds, setChapterIds] = useState<{ [storyId: string]: string | undefined }>({});
const navigate = useNavigate()
  const [numStoriesToShow, setNumStoriesToShow] = useState(10);
  const storageRef = getStorage();
  const { user } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = useAppSelector((state) => state.auth.user?.userData);
  useEffect(() => {
    const user = auth.currentUser;
    if (!user?.emailVerified) {
      navigate(`/verification-pending/${user?.uid}`);
   
    }else
    { if(user && !userDetails) {
         navigate(`/credform/${user?.uid}`);
       }
      }
    
  }, []);
  
  const fetchStoriesWithLimit = async (limit: number)  => {
    const storiesRef = ref(db, 'allStories');
    
    const queryRef = query(storiesRef, orderByChild('timestamp'), limitToLast(limit));
    const unsubscribe = onValue(queryRef, async (snapshot) => {
      try {
        const data = snapshot.val();
        if (data) {
          const storiesArray: Story[] = await Promise.all(Object.keys(data).map(async (key) => {
            const storyData = data[key];
            const coverURL = await  getDownloadURL(
              sref(
                storageRef,
                `authersImageData/${storyData.autherEmail}/storiesArchive/${key}/cover/${storyData.cover}`
              )
            ).catch(() => {
              return false;
            });

            const likeCountRef = ref(db, `allStories/${key}/Likes`);
            const likeCountSnapshot = await get(likeCountRef);
            const likeCount = likeCountSnapshot.exists() ? Object.keys(likeCountSnapshot.val()).length : 0;
            
            const viewCountRef = ref(db, `allStories/${key}/viewCount`);
            const viewCountSnapshot = await get(viewCountRef);
            const viewCountData = viewCountSnapshot.exists() ? viewCountSnapshot.val() : 0;

            return {
              id: key,
              ...storyData,
              coverURL: coverURL,
              likeCount: likeCount,
              viewCount: viewCountData
            };
          }));

          setStories(storiesArray);
          console.log('After SetStories:', stories);

        }
      } catch (error) {
        console.error("Error fetching stories:", error);
      }
    });

    return () => {
      off(storiesRef, "value", unsubscribe);
    };
  }

  useEffect(() => {
    fetchStoriesWithLimit(numStoriesToShow);
  }, []);

  // Function to load more stories
  const loadMoreStories = async () => {
    setIsLoading(true);
    const newNumStoriesToShow = numStoriesToShow + 10;
    await fetchStoriesWithLimit(newNumStoriesToShow);
    setNumStoriesToShow(newNumStoriesToShow);
  };

  useEffect(() => {
    const fetchChapterId = async (story: Story) => {
      const chaptersRef = ref(db, `allStories/${story.id}/chapters`);
      const chaptersSnapshot = await get(chaptersRef);
      const chapters = chaptersSnapshot.val();

      if (chapters) {
        for (const chapterId in chapters) {
          if (chapters[chapterId].chapterNo === 1) {
            setChapterIds((prevChapterIds) => ({
              ...prevChapterIds,
              [story.id]: chapterId,
            }));
            break;
          }
        }
      }
    };

    stories.forEach((story) => {
      fetchChapterId(story);
    });
  }, [stories]);

  const sortedStories = stories.sort((a, b) => b.timestamp - a.timestamp);

  const handleClick =  async (story: Story)  => {
    try {
      const viewCountRef = ref(db, `allStories/${story?.id}/viewCount`);
      const autherViewCountRef = ref(db, `authers/${story?.autherId}/viewCount`);
      const audienceRef = ref(db, `authers/${story?.autherId}/audiance/${user?.userData?.countryOrigin}`);
      
      runTransaction(viewCountRef, (currentData) => {
        return (currentData || 0) + 1;
      });

      runTransaction(audienceRef, (currentData) => {
        return (currentData || 0) + 1;
      });
      runTransaction(autherViewCountRef, (currentData) => {
        return (currentData || 0) + 1;
      });
    } catch (error) {
      console.error("Error updating view counts:", error);
    }
    navigate(`/synopsis/${story?.id}/chapter/${chapterIds[story?.id]}`)
  };

  const [bannedCountries, setBannedCountries] = useState<string[]>([]);
  useEffect(() => {
    const bannedCountriesRef = ref(db, "bannedCountries");

    const unsub = onValue(bannedCountriesRef, (snapshot) => {
      const bannedCountriesData = snapshot.val();
      if (Array.isArray(bannedCountriesData)) {
        setBannedCountries(bannedCountriesData);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  const filteredStories = sortedStories.filter((story) => {
    const isCoverURLValid = story.coverURL;
    if (bannedCountries.includes(user?.userData?.countryOrigin || "")) {
      return !story.islgbt && isCoverURLValid;
    } else {
      return isCoverURLValid;
    }
  });

  const [totalStoryCount, setTotalStoryCount] = useState<number>(0);
  const fetchTotalStoryCount = async () => {
    const storiesRef = ref(db, 'allStories');
    const totalStoryCountSnapshot = await get(storiesRef);
    if (totalStoryCountSnapshot.exists()) {
      setTotalStoryCount(totalStoryCountSnapshot.size);
    }
  };

  useEffect(() => {
    fetchTotalStoryCount(); 
  }, []);

  if (filteredStories.length <=0) {
    return <div className="flex justify-center items-center h-screen"><Loading/></div>;
  }


  return (
    <div className="select-none">
      <div>
        {/* <Header/> */}
        <div className="bg-white dark:bg-slate-800">
        <div className="pt-6  grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-5 xl:gap-x-8 p-10">
          
        {filteredStories.filter((story) => story.coverURL !== story.title && chapterIds[story.id] !== undefined ).map((story) => (
          <div
          key={story?.id}
          className={`transition-transform duration-300 ease-in-out transform hover:scale-105 rounded-lg lg:p-4 cursor-pointer lg:items-baseline flex gap-4 lg:flex-col`}
          onClick={() => {handleClick(story)}}
        >
        <div className="rounded-md h-36 xl:h-[22.1rem] w-1/3 lg:w-full   transition-all duration-300">
<img
src={story?.coverURL}
alt={story?.title}
className="h-full w-full object-contain rounded-md transition-all duration-300"
/>
</div>
<div className="flex flex-col w-2/3 lg:w-auto">
    <div className="mt-4 text-start font-lg font-bold text-gray-700 dark:text-gray-200 line-clamp-2 font-1">{story?.title}</div>
      <div className=" text-sm text-gray-500 font-2 text-start line-clamp-1 dark:text-gray-300">by {story?.autherName}</div>
    <div className=" text-start font-lg font-bold text-gray-700 line-clamp-2 font-1 dark:text-gray-400">{story?.category}</div>
      <div className="text-black flex gap-4 font-bold font-2 mt-1">
              <div className="flex items-center gap-1 lg:gap-2 mt-0.5">
                <div className="text-blue-900 dark:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 lg:h-10 lg:w-10 p-0.5 bg-blue-900 rounded-full " version="1.1" viewBox="0.00 0.00 2048.00 1024.00">
<g fill="#ffffff">
<path d="
M 1059.38 719.04
C 1049.67 742.00 1042.74 766.30 1038.58 791.93
C 1038.52 792.30 1038.39 792.33 1038.19 792.01
C 1037.61 791.08 1037.32 790.07 1037.32 789.00
C 1037.26 626.51 1037.26 471.68 1037.33 324.50
C 1037.33 320.88 1037.80 317.21 1039.09 313.86
C 1063.46 250.55 1100.97 194.85 1152.68 151.83
C 1179.77 129.30 1209.95 110.50 1243.20 95.44
C 1252.63 91.17 1262.85 87.36 1273.84 84.01
C 1276.23 83.28 1278.58 82.93 1280.88 82.95
C 1291.61 83.02 1302.06 83.03 1312.23 82.97
C 1312.60 82.97 1312.81 83.16 1312.84 83.53
L 1353.06 533.01
C 1353.23 534.92 1351.83 536.62 1349.92 536.81
C 1341.71 537.62 1333.69 538.49 1325.88 539.41
C 1296.53 542.86 1268.26 549.00 1241.07 557.81
C 1159.65 584.21 1092.98 639.63 1059.38 719.04
Z"
/>
<path d="
M 1364.89 183.64
C 1389.12 193.29 1410.90 203.60 1430.22 214.58
C 1433.64 216.52 1434.56 219.86 1435.02 223.52
C 1453.70 375.25 1471.35 517.73 1487.97 650.94
C 1488.15 652.43 1488.27 653.83 1488.32 655.14
C 1488.41 657.57 1486.67 657.86 1484.85 657.45
C 1410.71 640.90 1331.84 635.42 1257.37 652.15
C 1168.79 672.05 1090.79 724.51 1045.83 803.78
C 1044.10 806.82 1042.36 809.91 1040.61 813.05
C 1040.40 813.42 1040.18 813.43 1039.93 813.08
C 1039.41 812.33 1039.28 811.53 1039.55 810.69
C 1041.09 805.76 1042.63 801.04 1044.18 796.52
C 1056.41 760.67 1074.87 726.23 1099.07 697.02
C 1142.28 644.86 1203.59 608.97 1269.03 592.43
C 1311.44 581.71 1356.85 577.99 1400.65 579.14
C 1403.67 579.21 1405.28 578.57 1405.00 575.25
C 1404.58 570.17 1404.12 565.22 1403.61 560.41
C 1390.38 434.66 1377.23 309.17 1364.17 183.96
C 1364.15 183.74 1364.32 183.56 1364.54 183.57
C 1364.67 183.58 1364.78 183.60 1364.89 183.64
Z"
/>
<path d="
M 846.40 689.26
C 810.95 674.94 773.65 664.98 734.48 659.37
C 676.86 651.12 617.90 651.54 557.59 660.63
C 555.46 660.95 553.35 661.09 551.24 661.05
C 550.91 661.04 550.60 660.92 550.36 660.69
C 549.54 659.90 549.21 658.98 549.36 657.93
C 569.58 521.67 591.50 372.67 615.13 210.94
C 615.81 206.32 618.10 204.72 622.60 203.93
C 638.54 201.14 653.38 199.05 667.13 197.66
C 710.86 193.25 754.54 195.42 798.16 204.17
C 877.54 220.09 949.13 259.34 1008.14 314.96
C 1010.21 316.91 1010.99 318.92 1010.99 321.96
C 1011.00 483.37 1011.00 644.31 1010.99 804.76
C 1010.99 806.45 1010.41 806.68 1009.26 805.44
C 1007.73 803.81 1006.25 802.13 1004.80 800.40
C 963.61 751.02 906.59 713.57 846.40 689.26
Z"
/>
<path d="
M 1522.04 704.11
C 1522.85 704.27 1523.21 703.94 1523.12 703.13
C 1521.87 690.72 1520.46 678.83 1518.91 667.47
C 1513.96 631.22 1509.04 594.97 1503.48 558.85
C 1490.83 476.76 1478.68 394.39 1467.02 311.74
C 1466.97 311.43 1467.10 311.31 1467.41 311.39
C 1474.70 313.30 1482.18 317.41 1488.36 321.35
C 1489.58 322.13 1490.29 323.20 1490.50 324.55
C 1513.37 474.02 1537.09 629.10 1561.66 789.79
C 1561.99 791.94 1562.15 793.99 1562.13 795.92
C 1562.13 796.27 1561.97 796.38 1561.64 796.23
C 1484.07 761.30 1399.96 742.26 1315.38 744.11
C 1216.60 746.26 1125.06 772.31 1040.77 822.26
C 1040.56 822.38 1040.31 822.18 1040.40 821.95
C 1040.98 820.48 1041.80 819.16 1042.85 817.99
C 1068.40 789.48 1099.01 766.30 1132.57 747.43
C 1244.51 684.50 1378.07 677.13 1502.25 700.33
C 1508.92 701.58 1515.52 702.84 1522.04 704.11
Z"
/>
<path d="
M 524.24 703.80
C 529.88 703.90 534.68 702.51 540.15 701.45
C 596.85 690.50 652.62 686.45 707.45 689.31
C 775.73 692.86 843.63 709.93 903.98 741.10
C 938.89 759.13 970.15 782.03 997.77 809.80
C 1001.21 813.26 1004.31 816.88 1007.08 820.66
C 1007.59 821.35 1007.46 821.49 1006.71 821.07
C 972.08 801.65 936.45 785.71 899.80 773.25
C 814.07 744.11 726.76 736.64 637.87 750.84
C 586.09 759.12 534.94 774.67 486.02 795.85
C 485.80 795.94 485.57 795.76 485.60 795.53
C 486.49 789.49 487.36 783.74 488.19 778.27
C 505.92 661.61 525.56 533.26 547.11 393.23
C 550.36 372.17 553.64 350.59 556.95 328.50
C 557.56 324.41 559.35 321.63 562.32 320.18
C 568.21 317.27 573.57 311.83 580.35 311.67
C 580.89 311.66 581.12 311.91 581.04 312.44
L 523.75 703.22
C 523.70 703.52 523.93 703.79 524.24 703.80
Z"
/>
</g>
</svg>
                </div>
                <div className="text-[#0c192eec] dark:text-white">: {formatNumber(story?.viewCount) || 0}</div>
              </div>

              <div className="flex items-center gap-1 lg:gap-2 ">
                <div className="text-white bg-blue-900 rounded-full text-xs lg:text-lg h-6 w-6 lg:h-10 lg:w-10">
                  <i className="fa-sharp fa-solid fa-thumbs-up  mt-1.5 ml-1.5 lg:mt-2.5 lg:ml-2.5"></i>
                </div>
                <div className="text-[#0c192eec] dark:text-white">: {formatNumber(story?.likeCount) || 0}</div>
         

</div>
            </div>
            </div>
        </div>
          ))}
        </div>
        {totalStoryCount > stories.length  ?
        <div className="mx-auto text-center">
        <div className="flex justify-center">

      <div
      className="my-4  rounded-md bg-blue-900 text-white px-4 py-2.5 cursor-pointer"
      onClick={loadMoreStories}
      >
          More
        </div>
        </div>
          </div>:<></>
    }
      </div>
     
          </div>
          <Footer/>
    </div>
  );
};

export default TopListedStories;