import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { homeClasses } from "./homeClasses";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHook";
import { useEffect, useState } from "react";
import { get, onValue, ref, update } from "firebase/database";
import { auth, db } from "../../firebase";
import { autherLogin } from "../../features/userAutherSlice";
import StoryCard from "../../components/Story/StoryCard";
import Banner from "./Banner";
import SuggestionCard from "../../components/Story/Suggestions";
import TopAuthor from "../../components/Story/TopAuthers";
import UserBookmark from "../Profile/UserBookmark";
import Spotlight from "../../components/Story/Spotlight";
import TopCompleteStoriesCard from "../../components/Story/TopCompleteStories";
import Dropdown from "../../components/Header/Categories";
import Footer from "../../components/Footer";
import Feedback from "../Feedback/Feedback";
import AgeVerificationModal from "./Age";
import Tour from "../../components/Tour";
import React from "react";
const Home : React.FC = () => {
  const [feedbackModel, setfeedbackModel] = useState(false)
  const [bookmark, setbookmark] = useState()
  const [isLoading, setisLoading] = useState(false)
  const [isLoadingFeed, setisLoadingFeed] = useState(false)
  const { container, cardContainer, title, description } = homeClasses;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ageVerificationModal, setAgeVerificationModal] = useState(false)
  const {user}=useAppSelector((state)=>state.auth)
  const userDetails = useAppSelector((state) => state.auth.user?.userData);
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      
      if (!user?.emailVerified) {
        navigate(`/verification-pending/${user?.uid}`);
        
      }else
      { if(user && !userDetails) {
        navigate(`/credform/${user?.uid}`);
      }
    }
  }
    const userRef = ref(db, `authers/${user?.uid}`);
    const unsubscribe = onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      dispatch(autherLogin(userData));
    });
    
    return () => {
      unsubscribe();
    };
  }, [dispatch]);



  useEffect(() => {
    const userBirthdate = user?.userData?.birthdate;
if (userBirthdate) {
  const birthDateEpoch = new Date(userBirthdate).getTime(); // Convert to epoch timestamp

  const currentDateEpoch = new Date().getTime();
  const userAgeMilliseconds = currentDateEpoch - birthDateEpoch;
  const userAgeYears = Math.floor(userAgeMilliseconds / (365.25 * 24 * 60 * 60 * 1000)); // Approximate years
const verify =localStorage.getItem('ageVerification' )

if (!verify && !localStorage.getItem("tour")) {
  setAgeVerificationModal(false)
  if (userAgeYears < 18) {
    setAgeVerificationModal(true)
  }
  return
  }
}
  }, [user?.userData?.birthdate])
  




useEffect(() => {
  const storyRef = ref(db, `users/${user?.id}/bookmarks`);
  const unsubscribe = onValue(storyRef, async (snapshot) => {
      setisLoadingFeed(true)
    const storyData = snapshot.val();
    setbookmark(storyData)
    if (!localStorage.getItem("tour")) {

      return
     }
    if (storyData && Object.keys(storyData).length >= 1) {
      setfeedbackModel(true);
      setisLoadingFeed(false)
    }
    setisLoadingFeed(false)
    }
    )
    const userFeedbackRef = ref(db, `feedback/${user?.id}`);
    const feedbackUnsubscribe = onValue(userFeedbackRef, (snapshot) => {
      setisLoadingFeed(true)
      
      const userFeedback = snapshot.val();
      if (userFeedback ) {
        setfeedbackModel(false);
        setisLoadingFeed(false) // User has submitted feedback, set feedbackModel to false
      }
      setisLoadingFeed(false)
    });

    setisLoading(false)
    return () => {
      feedbackUnsubscribe();
      unsubscribe();
    };
}, [user?.id])


if (isLoading) {
  return <div>Loading...</div>; 
}
  

  return (
    <div className="select-none">
    {ageVerificationModal && <AgeVerificationModal onClose={() => {setAgeVerificationModal(false); localStorage.setItem('ageVerification' , JSON.stringify(true))}} />}
    {
      isLoadingFeed &&
      (feedbackModel && <Feedback/>)
    }
    {/* <Header /> */}
<Banner/>
    

<Tour />
<div className="bg-white dark:bg-slate-800">


{bookmark &&

      <section id="continueReading"  >
<div className="flex flex-col px-4 lg:px-0" >

<div className="text-xl lg:text-4xl text-[#0c192eec] dark:text-white select-none text-start px-2 lg:px-12 font-bold  font-2 flex justify-between">
  <div className="mt-14 style2">

  Continue Reading
  </div>
  
   </div>


<UserBookmark/>
 

</div>

</section>
}





<section id="popular">

<div className="text-xl lg:text-4xl text-[#0c192eec] dark:text-white select-none text-start px-2 lg:px-12 font-bold  font-2 flex justify-between pt-4">
  <div className="font-">

  Popular Picks
  </div>
 
   </div>


<div className="flex flex-col py-" >


    
       <StoryCard  storyType="mostLikedStories"/>
       <div className="flex w-full justify-center">
       
       <div
                      onClick={() =>
                        navigate(
                          `/section/${"mostLikedStories"}`
                        )
                      }
                      className="mb-8 rounded-md bg-blue-900 text-white px-4 py-2.5 cursor-pointer"
                      >
                      
                         View All
                       
                      </div>
                    </div> 
       
</div>
</section>

<section id="BestOfMonth">
<div className="flex flex-col py-" >

     <div className="text-xl lg:text-4xl text-[#0c192eec] dark:text-white select-none text-start px-2 lg:px-12 font-bold  font-2 flex justify-between">
  <div>

  Best of <span className="text-xl lg:text-4xl text-[#0c192eec] dark:text-white">{new Date().toLocaleString('default', { month: 'long' })}</span>
  </div>
 
   </div>

    
       <StoryCard  storyType="bookOfTheMonth"/>
       
       <div className="flex w-full justify-center">
       
       <div
                      onClick={() =>
                        navigate(
                          `/section/${"bookOfTheMonth"}`
                        )
                      }
                      className="mb-8 rounded-md bg-blue-900 text-white px-4 py-2.5 cursor-pointer"
                      >
                      
                         View All
                       
                      </div>
                    </div> 
     
</div>
</section>




<section id="exclusive">
<div className="flex flex-col py-" >
<div className="text-xl lg:text-4xl text-[#0c192eec] dark:text-white select-none text-start px-2 lg:px-12 font-bold  font-2 flex justify-between">
  <div className="font-">

  Likhasi Exclusive
  </div>
 
   </div>



    
       <StoryCard  storyType="ExclusiveList"/>
       
       <div className="flex w-full justify-center">
       
       <div
                      onClick={() =>
                        navigate(
                          `/section/${"ExclusiveList"}`
                        )
                      }
                      className="mb-8 rounded-md bg-blue-900 text-white px-4 py-2.5 cursor-pointer"
                      >
                      
                         View All
                       
                      </div>
                    </div> 
      
</div>
</section>




<section id="editorsChoice">
<div className="flex flex-col py-" >

<div className="text-xl lg:text-4xl text-[#0c192eec] dark:text-white select-none text-start px-2 lg:px-12 font-bold  font-2 flex justify-between">
  <div className="font-">

  Editors Choice
  </div>
 
   </div>

    
       <StoryCard  storyType="editorsChoice"/>
       
       <div className="flex w-full justify-center">
       
       <div
                      onClick={() =>
                        navigate(
                          `/section/${"editorsChoice"}`
                        )
                      }
                      className="mb-8 rounded-md bg-blue-900 text-white px-4 py-2.5 cursor-pointer"
                      >
                      
                         View All
                       
                      </div>
                    </div> 
     
       
</div>
</section>

{user && 
<section id="recommendation">
<div className="flex flex-col py-" >

      <div className="text-xl lg:text-4xl text-[#0c192eec] dark:text-white select-none text-start px-2 lg:px-12 font-bold  font-2 flex justify-between">
  <div>

        Recommended For You...
 
  </div>
  
   </div>
   <div className="px-3">

    
      <SuggestionCard count={8} />
   </div>
       
     
       
</div>
</section>
}

<section id="completed">
<div className="flex flex-col py-" >

 <div className="text-xl lg:text-4xl text-[#0c192eec] dark:text-white select-none text-start px-2 lg:px-12 font-bold  font-2 flex justify-between">
  <div>

        Completed
 
  </div>
  
   </div>


<TopCompleteStoriesCard/>
 

</div>
</section>

<section id="spotLight">


<div className="flex flex-col pb-8" >

       <Spotlight  />

      
       
     
     
</div>
</section>

       
       
</div>

      <Footer/>
    </div>
  );
};

export default Home;
