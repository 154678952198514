import { StoryForm } from "../../models/story/StoryForm";
import React, { useState, useRef, useEffect, createRef, DependencyList } from 'react';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { app, db } from '../../firebase';
import { useAppSelector } from '../../hooks/storeHook';
import { getDatabase, ref as dbRef, push, set } from 'firebase/database';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer';
import ReactCrop, { Crop, PixelCrop, centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import watermark from "../../assets/watermark.png"
interface ConfirmationDialogProps {
  message: string;
  onCancel: () => void;
  onOk: () => void;
  loadingStat:boolean;
//   formData: any;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  message,
  onCancel,
  onOk,
  loadingStat
}) => {





  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 z-10 flex justify-center items-center bg-black bg-opacity-90 select-none`}
      style={{ transform: `translateY-100%` }}
    >
      <div className="bg-white dark:bg-slate-800 lg:w-1/2 p-10">
      <div className='text-9xl text-yellow-500 text-center'>
            <i className="fa-solid fa-triangle-exclamation"></i>
            </div>
        <p className="mt-4 font-1 font-bold text-black dark:text-gray-100">{message}</p>
        <div className="flex gap-4 mt-4">
          <button
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            onClick={onOk}
          >
            {loadingStat ? 'Submitting...' : 'Submit'}
          </button>
          <button
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
