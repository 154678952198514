export const authClasses = {
  container:
    "grid h-screen w-screen place-items-center px-4 text-sm font-medium",
  form: "p-4 md:p-5 lg:p-6",
  button:
    "rounded-md bg-white text-blue-900 px-4 py-2.5 ",
  input:
    "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline",
  text: "text-slate-50 mx-2 mb-0.5",
  textLight: "text-slate-300",
  link: "font-medium cursor-pointer text-white hover:underline dark:text-primary-500 font-2",
  hr: "w-full border-slate-100",
  forgotPasswordButton: "mx-3 w-full font-1 text-white",
};
export const writeClassMod={
  a:"flex bg-gray-50 dark:bg-gray-100  justify-evenly p-1 rounded-lg"
}