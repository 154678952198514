import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, getStorage, ref as sref } from 'firebase/storage';
import { db } from "../../firebase";
import { ref, onValue, off, get } from "firebase/database";
import { Story } from "../../models/story/Story";
import Share from "../../components/Story/Share";
import { useAppSelector } from "../../hooks/storeHook";
import { Carousel } from "react-responsive-carousel";
import { writeClassMod } from "../Auth/authClasses";


const truncateSummary = (summary: string, wordLimit: number, lineLimit: number) => {
  if (!summary) {
    return ""; // Return an empty string if summary is undefined or empty
  }

  const words = summary.split(" ");
  const lines = summary.split("\n");
  const truncatedWords = words.slice(0, wordLimit);
  const truncatedLines = lines.slice(0, lineLimit);

  let truncatedSummary = truncatedWords.join(" ");
  if (words.length > wordLimit) {
    truncatedSummary += "...";
  }

  if (lines.length > lineLimit) {
    truncatedSummary = truncatedLines.join("\n") + "...";
  }

  return truncatedSummary;
};

const AutherSection = () => {
  const [stories, setStories] = useState<Story[]>([]);
  const [chapterIds, setChapterIds] = useState<{ [storyId: string]: string | undefined }>({});
  const [viewCounts, setViewCounts] = useState<{ [storyId: string]: number }>({});
  const storageRef = getStorage();
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { user } = useAppSelector((state) => state.auth);
  const [renderAutherSection, setRenderAutherSection] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      setRenderAutherSection(true);
    }, 5000);

    return () => clearTimeout(delay);
  }, []);
  useEffect(() => {
    const storiesRef = ref(db, `authers/${user?.id}/storiesArchive/`);
    const unsubscribe = onValue(storiesRef, async (snapshot) => {
      setLoading(true)
      const data = snapshot.val();
      if (data) {
        const storiesArray: Story[] = await Promise.all(Object.keys(data).map(async (key) => {
          const storyData = data[key];
          const coverURL = await getDownloadURL(
            sref(
              storageRef,
              `authersImageData/${storyData.autherEmail}/storiesArchive/${key}/cover/${storyData.cover}`
            )
          ).catch(() => {
            return storyData.title
          });

          const likeCountRef = ref(db, `allStories/${key}/Likes`);
          const likeCountSnapshot = await get(likeCountRef);
          const likeCount = likeCountSnapshot.exists() ? Object.keys(likeCountSnapshot.val()).length : 0;
          
          return {
            id: key,
            ...storyData,
            coverURL: coverURL,
            likeCount: likeCount
          };
        }));

        setStories(storiesArray);
      }
      setLoading(false)
    });

    return () => {
      off(storiesRef, "value", unsubscribe);
    };
  }, [storageRef]);

  useEffect(() => {
    const fetchViewCounts = async () => {
      setLoading(true)
      const viewCountsData: { [storyId: string]: number } = {};
      for (const story of stories) {
        setLoading(true)
        const viewCountRef = ref(db, `allStories/${story.id}/viewCount`);
        const viewCountSnapshot = await get(viewCountRef);
        const viewCountData = viewCountSnapshot.val();
        const totalViews = viewCountData ? Object.keys(viewCountData).length : 0;
        viewCountsData[story.id] = totalViews;
      }
      setViewCounts(viewCountsData);
      setLoading(false)
    };

    fetchViewCounts();
  }, [stories]);

  useEffect(() => {
    const fetchChapterId = async (story: Story) => {
      setLoading(true)
      const chaptersRef = ref(db, `allStories/${story.id}/chapters`);
      const chaptersSnapshot = await get(chaptersRef);
      const chapters = chaptersSnapshot.val();

      if (chapters) {
        for (const chapterId in chapters) {
          if (chapters[chapterId].chapterNo === 1) {
            setChapterIds((prevChapterIds) => ({
              ...prevChapterIds,
              [story.id]: chapterId,
            }));
            break;
          }
        }
      }
      setLoading(false)
    };

    stories.forEach((story) => {
      fetchChapterId(story);
    });
  }, [stories]);

  const [activeIndex, setActiveIndex] = useState(0);
  const indicatorStyles: React.CSSProperties = {
    position:"relative",
    
    background: 'transparent',
    width: '10px',
    height: '10px',
    display: 'inline-block',
    margin: ' 0 8px',
    borderRadius: '50%', // This will make them round
    cursor: 'pointer',
  };
  const getSlidesToShow = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1024) {
      // Large screens (desktop)
      return 5;
    } else if (screenWidth >= 768) {
      // Medium screens (tablet)
      return 3;
    }else if (screenWidth >= 375 && screenWidth > 375) {
    return 2;
    }else {
      // Small screens (mobile)
      return 2;
    }
  };
  // Active indicator style
  const activeIndicatorStyles: React.CSSProperties = {
    ...indicatorStyles,
    background: '#002D72',
    visibility:"hidden"
  };

  // Custom render function for indicators
  const customRenderIndicator = (
    onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    index: number,
    label: string
  ) => {
    return (
      <li

        style={isSelected ? activeIndicatorStyles : indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        title={`${label} ${index + 1}`}
        aria-label={`${label} ${index + 1}`}
      />
    );
  };

  const arrowStyles: React.CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(40% - 15px)',
    cursor: 'pointer',
  };
  

  // Custom render function for the previous arrow
  const customRenderPrevArrow = (onClickHandler: () => void, hasPrev: boolean, label: string) =>
    hasPrev && (
      <button type="button" className='-mt-8' onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 0.5 }}>
        <svg className="h-12 lg:h-16" viewBox="0 0 38 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.3" cx="-1" cy="39" r="39" fill="#002D72"/>
<path d="M20.0304 46.7796C20.3232 46.4867 20.3232 46.0118 20.0304 45.7189L13.3107 38.9992L20.0304 32.2796C20.3232 31.9867 20.3232 31.5118 20.0304 31.2189C19.7375 30.926 19.2626 30.926 18.9697 31.2189L11.7197 38.4689C11.4268 38.7618 11.4268 39.2367 11.7197 39.5296L18.9697 46.7796C19.2626 47.0724 19.7375 47.0724 20.0304 46.7796Z" fill="#5B5236"/>
</svg>


      </button>
    );

  // Custom render function for the next arrow
  const customRenderNextArrow = (onClickHandler: () => void, hasNext: boolean, label: string) =>
    hasNext && (
      <button className='-mt-8' type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0.5 }}>
       <svg className="h-12 lg:h-16" viewBox="0 0 38 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.3" cx="39" cy="39" r="39" transform="matrix(-1 0 0 1 78 0)" fill="#002D72"/>
<path d="M17.9691 46.7796C17.6763 46.4867 17.6763 46.0118 17.9691 45.7189L24.6888 38.9992L17.9691 32.2796C17.6763 31.9867 17.6763 31.5118 17.9691 31.2189C18.262 30.926 18.7369 30.926 19.0298 31.2189L26.2798 38.4689C26.5727 38.7618 26.5727 39.2367 26.2798 39.5296L19.0298 46.7796C18.7369 47.0724 18.262 47.0724 17.9691 46.7796Z" fill="#5B5236"/>
</svg>

      </button>
    );

  
  return (
    <div className="mb-2 mt-2 lg:mt-10 select-none">
      <h2 className="lg:text-4xl text-[#0c192eec] select-none text-start px-10 font-bold  font-2 flex justify-between text-gray-900 dark:text-gray-100 mt-4 lg:mt-0">My writeups</h2>
      <div>
        {stories && stories.length > 0 ?
         
      
    
    ( 
      <Carousel
      showArrows={true}
      showThumbs={false}
        swipeable={false}
      renderArrowPrev={customRenderPrevArrow}
      renderArrowNext={customRenderNextArrow}
      renderIndicator={customRenderIndicator}
      onChange={(index) => setActiveIndex(index)}
    >
       {Array.from({ length: Math.ceil(stories.length / getSlidesToShow()) }).map((_, slideIndex) => (
    <div key={slideIndex} className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6  md:px-8 lg:px-10 py-6 md:py-6">
     {stories.slice(slideIndex * getSlidesToShow(), (slideIndex + 1) * getSlidesToShow()).map((bookmark, index) => {
                  const story = stories.find((story) => story.id === bookmark.id);

                  return (
                    <>
                      {story && (
                    <div
                      key={index}
                      className={`transition-transform duration-300 ease-in-out transform hover:scale-105 rounded-lg px-1 cursor-pointer items-baseline flex flex-col`}
                      // onClick={()=>handleClick(story )}
                      onClick={() => {
                        const chapterId = chapterIds[story.id];
                      
                        if (story.coverURL === undefined || !story.cover  ) {
                          navigate(`/addcover/${story.id}`);
                        } else {
                          navigate(`/synopsis/${story.id}/chapter/${undefined}`);
                        }
                      }}
                    >
                        <>
                        <div className="rounded-md h-auto w-full  transition-all duration-300">
                            <img
                              src={story.coverURL}
                              alt={story.title}
                              className="h-full w-full object-contain  rounded-md transition-all duration-300"
                            />
</div>
                          <div className="mt-4 text-start font-lg font-bold text-gray-700 dark:text-gray-200 line-clamp-2 font-1">{story.title}</div>
          <div className=" text-sm text-gray-500 font-2 text-start line-clamp-1 dark:text-gray-300">by {story.autherName}</div>
        <div className=" text-start font-lg font-bold text-gray-700 line-clamp-2 font-1 dark:text-gray-400">{story.category}</div>
          <div className="text-black flex gap-4 font-bold font-2 mt-1">
                  <div className="flex items-center gap-2 mt-0.5">
                    <div className="text-blue-900 dark:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 p-0.5 bg-blue-900 rounded-full " version="1.1" viewBox="0.00 0.00 2048.00 1024.00">
<g fill="#ffffff">
<path d="
  M 1059.38 719.04
  C 1049.67 742.00 1042.74 766.30 1038.58 791.93
  C 1038.52 792.30 1038.39 792.33 1038.19 792.01
  C 1037.61 791.08 1037.32 790.07 1037.32 789.00
  C 1037.26 626.51 1037.26 471.68 1037.33 324.50
  C 1037.33 320.88 1037.80 317.21 1039.09 313.86
  C 1063.46 250.55 1100.97 194.85 1152.68 151.83
  C 1179.77 129.30 1209.95 110.50 1243.20 95.44
  C 1252.63 91.17 1262.85 87.36 1273.84 84.01
  C 1276.23 83.28 1278.58 82.93 1280.88 82.95
  C 1291.61 83.02 1302.06 83.03 1312.23 82.97
  C 1312.60 82.97 1312.81 83.16 1312.84 83.53
  L 1353.06 533.01
  C 1353.23 534.92 1351.83 536.62 1349.92 536.81
  C 1341.71 537.62 1333.69 538.49 1325.88 539.41
  C 1296.53 542.86 1268.26 549.00 1241.07 557.81
  C 1159.65 584.21 1092.98 639.63 1059.38 719.04
  Z"
/>
<path d="
  M 1364.89 183.64
  C 1389.12 193.29 1410.90 203.60 1430.22 214.58
  C 1433.64 216.52 1434.56 219.86 1435.02 223.52
  C 1453.70 375.25 1471.35 517.73 1487.97 650.94
  C 1488.15 652.43 1488.27 653.83 1488.32 655.14
  C 1488.41 657.57 1486.67 657.86 1484.85 657.45
  C 1410.71 640.90 1331.84 635.42 1257.37 652.15
  C 1168.79 672.05 1090.79 724.51 1045.83 803.78
  C 1044.10 806.82 1042.36 809.91 1040.61 813.05
  C 1040.40 813.42 1040.18 813.43 1039.93 813.08
  C 1039.41 812.33 1039.28 811.53 1039.55 810.69
  C 1041.09 805.76 1042.63 801.04 1044.18 796.52
  C 1056.41 760.67 1074.87 726.23 1099.07 697.02
  C 1142.28 644.86 1203.59 608.97 1269.03 592.43
  C 1311.44 581.71 1356.85 577.99 1400.65 579.14
  C 1403.67 579.21 1405.28 578.57 1405.00 575.25
  C 1404.58 570.17 1404.12 565.22 1403.61 560.41
  C 1390.38 434.66 1377.23 309.17 1364.17 183.96
  C 1364.15 183.74 1364.32 183.56 1364.54 183.57
  C 1364.67 183.58 1364.78 183.60 1364.89 183.64
  Z"
/>
<path d="
  M 846.40 689.26
  C 810.95 674.94 773.65 664.98 734.48 659.37
  C 676.86 651.12 617.90 651.54 557.59 660.63
  C 555.46 660.95 553.35 661.09 551.24 661.05
  C 550.91 661.04 550.60 660.92 550.36 660.69
  C 549.54 659.90 549.21 658.98 549.36 657.93
  C 569.58 521.67 591.50 372.67 615.13 210.94
  C 615.81 206.32 618.10 204.72 622.60 203.93
  C 638.54 201.14 653.38 199.05 667.13 197.66
  C 710.86 193.25 754.54 195.42 798.16 204.17
  C 877.54 220.09 949.13 259.34 1008.14 314.96
  C 1010.21 316.91 1010.99 318.92 1010.99 321.96
  C 1011.00 483.37 1011.00 644.31 1010.99 804.76
  C 1010.99 806.45 1010.41 806.68 1009.26 805.44
  C 1007.73 803.81 1006.25 802.13 1004.80 800.40
  C 963.61 751.02 906.59 713.57 846.40 689.26
  Z"
/>
<path d="
  M 1522.04 704.11
  C 1522.85 704.27 1523.21 703.94 1523.12 703.13
  C 1521.87 690.72 1520.46 678.83 1518.91 667.47
  C 1513.96 631.22 1509.04 594.97 1503.48 558.85
  C 1490.83 476.76 1478.68 394.39 1467.02 311.74
  C 1466.97 311.43 1467.10 311.31 1467.41 311.39
  C 1474.70 313.30 1482.18 317.41 1488.36 321.35
  C 1489.58 322.13 1490.29 323.20 1490.50 324.55
  C 1513.37 474.02 1537.09 629.10 1561.66 789.79
  C 1561.99 791.94 1562.15 793.99 1562.13 795.92
  C 1562.13 796.27 1561.97 796.38 1561.64 796.23
  C 1484.07 761.30 1399.96 742.26 1315.38 744.11
  C 1216.60 746.26 1125.06 772.31 1040.77 822.26
  C 1040.56 822.38 1040.31 822.18 1040.40 821.95
  C 1040.98 820.48 1041.80 819.16 1042.85 817.99
  C 1068.40 789.48 1099.01 766.30 1132.57 747.43
  C 1244.51 684.50 1378.07 677.13 1502.25 700.33
  C 1508.92 701.58 1515.52 702.84 1522.04 704.11
  Z"
/>
<path d="
  M 524.24 703.80
  C 529.88 703.90 534.68 702.51 540.15 701.45
  C 596.85 690.50 652.62 686.45 707.45 689.31
  C 775.73 692.86 843.63 709.93 903.98 741.10
  C 938.89 759.13 970.15 782.03 997.77 809.80
  C 1001.21 813.26 1004.31 816.88 1007.08 820.66
  C 1007.59 821.35 1007.46 821.49 1006.71 821.07
  C 972.08 801.65 936.45 785.71 899.80 773.25
  C 814.07 744.11 726.76 736.64 637.87 750.84
  C 586.09 759.12 534.94 774.67 486.02 795.85
  C 485.80 795.94 485.57 795.76 485.60 795.53
  C 486.49 789.49 487.36 783.74 488.19 778.27
  C 505.92 661.61 525.56 533.26 547.11 393.23
  C 550.36 372.17 553.64 350.59 556.95 328.50
  C 557.56 324.41 559.35 321.63 562.32 320.18
  C 568.21 317.27 573.57 311.83 580.35 311.67
  C 580.89 311.66 581.12 311.91 581.04 312.44
  L 523.75 703.22
  C 523.70 703.52 523.93 703.79 524.24 703.80
  Z"
/>
</g>
</svg>
                    </div>
                    <div className="text-[#0c192eec] dark:text-white">: {story?.viewCount || 0}</div>
                  </div>

                  <div className="flex items-center gap-2 ">
                    <div className="text-white bg-blue-900 rounded-full text-lg h-10 w-10">
                      <i className="fa-sharp fa-solid fa-thumbs-up  mt-2.5"></i>
                    </div>
                    <div className="text-[#0c192eec] dark:text-white">: {story.likeCount || 0}</div>
             

</div>
                </div>
                        </>
                    </div>
                      )}
                    </>
                  );
                })}
              </div>
            ))}
    </Carousel>
        ):
        <div className="text-xl text-black dark:text-white lg:flex justify-center items-center w-full text-center py-8">
          <div className={`${!renderAutherSection && "hidden"} lg:flex lg:w-full`}>

          
          <div className="w-full lg:text-end ">

          You have not written anything so far.  
          </div>
          <div className="cursor-pointer mt-4 lg:mt-0 flex w-full justify-center lg:justify-start text-white font-1 font-bold " onClick={()=>{navigate(`/addstory/${user?.id}`)}}>
            <div
                          // key={item.name}
                          // onClick={handleAuther}
                          className="text-[#002D72] dark:text-blue-800    cursor-pointer   hover:text-blue-700 px-3  flex items-center gap-2   text-sm font-medium no-underline" // ? "bg-gray-900 text-white" // item.current

                          // aria-current={item.current ? "page" : undefined}
                        >
                          <div className={writeClassMod.a + " border-2 rounded-md shadow-xl bg-transparent"}>

<span className="text-xl ml-2.5 font-bold">Write </span>
<span>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" className="h-6 -ml-2" viewBox="0 0 284.1 141.48">
<g>
<path d="M123.64,45.1a3,3,0,0,1-1.67.8c-1.85,0-5.34-1.85-7.61-3.52-1.61-1.16-4.26-3.33-4.81-5.76-.2-.88.58-3.21.66-2.57A13.7,13.7,0,0,0,115.32,41C119.32,44,123.87,44.88,123.64,45.1Z" fill="#002D72"/>
<path d="M126.37,42.34a3.15,3.15,0,0,1-1.76.88c-2,0-5.74-2-8.19-3.76-1.76-1.25-4.57-3.6-5.16-6.24-.21-.93.62-3.47.71-2.78a14.76,14.76,0,0,0,5.49,7.5C121.75,41.18,126.6,42.1,126.37,42.34Z" fill="#002D72"/>
<path d="M137.79,135.17c.51.26.49.4-.09.41-2,0-8.19.32-9.67-.88-20.81-17-11.37-43.58-1.22-63q7-13.32,14-26.63c3.84-7.26,7.86-19.6-.88-25-7.4-4.56-16,6.46-19.6,11.22a4.8,4.8,0,0,1-2.59,2A4.2,4.2,0,0,1,113.54,32a.62.62,0,0,1-.15-.69C115.75,24.51,120,16,126.81,13q9.58-4.35,17.58,3.46c7.19,7,7.62,17.19,3.19,26.16q-9.24,18.7-18.86,37.22c-5.18,10-8,20.1-7.42,31.42Q122.16,127.33,137.79,135.17Z" fill="#002D72"/>
<path d="M112.17,85.77Q122.57,64,134.39,43.06a19.33,19.33,0,0,0,2.51-9.13c.06-2.3-1.65-4.49-4-4.19-3,.38-6.85,6.73-8,9.15a.81.81,0,0,1-1.09.4.89.89,0,0,1-.3-.24c-4.08-5.27,10.08-20.22,16.08-13.78,3.15,3.4,2.38,10,.44,13.79-10.42,20.11-25.16,40.45-29.2,63.21-2.19,12.26,1.1,25.09,11.71,32.42.88.57.75.81-.26.67-18.46-2.3-19.29-24.73-14.94-37.94A100.58,100.58,0,0,1,112.17,85.77Z" fill="#002D72"/>
<path d="M178,100.71a.62.62,0,0,0,.25-.8L161.72,64.65a1,1,0,0,0-1.28-.48l-.1,0c-12.52,7.42-20.73,18.47-26.17,32h-.07c4.72-15,14.44-30.89,29.23-37.15a1,1,0,0,1,1.28.47l20.94,41.39a.3.3,0,0,1-.13.4.13.13,0,0,1-.08,0c-13.61,2.81-29.74,10.75-37.41,22.92a.16.16,0,0,1-.16.09h-.05v-.08a16.46,16.46,0,0,1,1.29-2.46,50.1,50.1,0,0,1,27.54-20.44A6.39,6.39,0,0,0,178,100.71Z" fill="#002D72"/>
<path d="M194.53,105a.3.3,0,0,0,.2-.38h0l-16-33.85a1.19,1.19,0,0,0-1.25-.67l-3,.46a.49.49,0,0,1-.55-.29l-1.27-2.42a.77.77,0,0,1,.32-1,.85.85,0,0,1,.17-.06l7.58-2a1.12,1.12,0,0,1,1.43.65l20.85,41.55a.5.5,0,0,1-.23.67.41.41,0,0,1-.18.05c-17,1.47-36.86,5.91-50.12,17.47-.69.59-.72.56-.1-.11,9.79-10.55,26.38-17.44,40.45-19.51A7.84,7.84,0,0,0,194.53,105Z" fill="#002D72"/>
<path d="M195.17,113.76l-.44-2.29a.43.43,0,0,1,.25-.54.45.45,0,0,1,.16,0l7-.88a.31.31,0,0,1,.31.2l2.64,6.71a.14.14,0,0,1-.08.18H205a102.24,102.24,0,0,0-51.08,10.48h0l-.11-.2a.16.16,0,0,1,0-.22l0,0c12.2-7.57,26.87-11.08,41-12.93A.42.42,0,0,0,195.17,113.76Z" fill="#002D72"/>
<path d="M146.87,135.79h-.05l-.1-.16a.23.23,0,0,1,.05-.32l0,0c14-9.87,33.32-13.53,49.84-14.66a1.18,1.18,0,0,1,1.31.88l1.25,3.51c.21.59,0,.88-.64.84A104.35,104.35,0,0,0,146.87,135.79Z" fill="#002D72"/>
<path d="M108.09,37.57s-6,6.73-10.08,11.36a25,25,0,0,1-6.25,5.39s-.35,11.21-.88,13.93c-.26,1.26-.7,3.06-1.08,4.57a1.93,1.93,0,0,0-1.09.5,1.56,1.56,0,0,0,.09,2c3.64-4.79,7.29-9.68,10.94-14.44-.11-.55-.28-1.76.13-2.25a1.5,1.5,0,0,1,1.19-.58,1.55,1.55,0,0,1,1.21,2.56c-.42.51-1.66.59-2.22.6-3.56,4.82-7.4,9.51-10.91,14.4a1.44,1.44,0,0,0,.78.2,1.56,1.56,0,0,0,1.19-.58A2.1,2.1,0,0,0,91.36,74c1.18-.7,2.69-1.58,4.22-2.45,4.25-2.42,13.32-3.82,13.32-3.82a20.06,20.06,0,0,1,2.63-7.09c2.78-4.31,8.3-13.46,8.3-13.46a25,25,0,0,1-7.1-3.59A20,20,0,0,1,108.09,37.57Z" fill="#002D72" fill-rule="evenodd"/>
</g>
</svg>
  {/* <i className="fa-sharp fa-solid fa-pen-nib pt-2"></i> */}
</span>
</div>
                        </div>
                        </div>
                        </div>
          </div>}
         
      </div>
    </div>
  );
};

export default AutherSection;