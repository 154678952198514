import React, { useEffect, useState } from "react";
import { ref, onValue, off, get } from "firebase/database";
import { auth, db } from "../firebase";
import Header from "../components/Header/Header";
import { useAppSelector } from "../hooks/storeHook";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Info from "./Story/Info";
import { indexOf } from "lodash";

interface Author {
  id: string;
  email: string;
  autherName: string;
  viewCount: number;
  rank?: number;
  likeCount: number;
  commentCount: number;
}

function TopAuthorList(): JSX.Element {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [authors, setAuthors] = useState<Author[]>([]);
  const { user } = useAppSelector((state) => state.auth);

  const [userRank, setUserRank] = useState<number | null>(null);
  const userDetails = useAppSelector((state) => state.auth.user?.userData);
  useEffect(() => {
    const user = auth.currentUser;
    if (!user?.emailVerified) {
      navigate(`/verification-pending/${user?.uid}`);
   
    }else
    { if(user && !userDetails) {
         navigate(`/credform/${user?.uid}`);
       }
      }
    
  }, []);

  
  useEffect(() => {
    
    const authorsRef = ref(db, "authers");

    const unsubscribe = onValue(authorsRef, (snapshot) => {
      const data = snapshot.val();
      
      if (data) {
        setLoading(true);
        const authorsArray: Author[] = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
       

      setAuthors(authorsArray);
      const userIndex = validAuthors.findIndex((author) => author.email === user?.email);

      // Log the index
      console.log("User Index:", userIndex);

        setLoading(false);
      } else {
        console.log("authors not found");
      }
    });

    return () => {
      off(authorsRef, "value", unsubscribe);
    };

   
  }, [user]); 
    

  const calculateTotalEngagement = (author: {
    viewCount: number;
    likeCount: number;
    commentCount: number;
  }) => {
    return (
      0.3 * author.viewCount +
      0.35 * author.likeCount +
      0.35 * author.commentCount
    );
  };

  // Sort authors by total engagement in descending order
  const sortedAuthors = [...authors].sort((a, b) => {
    const totalEngagementA = calculateTotalEngagement(a);
    const totalEngagementB = calculateTotalEngagement(b);

    // Handle NaN cases
    if (isNaN(totalEngagementA) && isNaN(totalEngagementB)) {
      return 0;
    } else if (isNaN(totalEngagementA)) {
      return 1;
    } else if (isNaN(totalEngagementB)) {
      return -1;
    }

    return totalEngagementB - totalEngagementA;
  });
  console.log(sortedAuthors);
  const validAuthors = sortedAuthors.filter(author => author.autherName && author.email);

  useEffect(() => {
    if (user) {
      // Find the index of the current user's email in the validAuthors array
      const userIndex = validAuthors.findIndex((author) => author.email === user?.email);
      if (userIndex >= 0) {
        setUserRank(userIndex + 1); // Adding 1 to make it 1-based instead of 0-based
      }
      // Log the index
      console.log("User Index:", userIndex);
    }
  }, [validAuthors]);
  
    const navigate = useNavigate();
    const handleAuthorClick = (authorId: string ) => {
    
      navigate(`/byauther/authers/${authorId}/storiesArchive`);
    };
    return (
      <div className="select-none">
      {/* <Header /> */}
      {loading ? (
        <div>Loading</div>
      ) : errorMessage ? (
        <div>{errorMessage}</div>
      ) : (
<div>
        <div className=" lg:p-10 rounded-lg bg-white dark:bg-slate-800 ">
          <div className="bg-gray-400 text-center py-2 rounded-md mb-4 text-xs lg:text-md text-white"> The League is updated every month</div>
          <div className="lg:flex gap-12">

          <div className="flex w-full lg:w-1/5 bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-100 flex-col justify-center items-center rounded-md shadow-xl py-4 ">
<div className="font-1 text-xl font-extrabold">My Rank</div>
<div className="flex">
  <div>
    <div className="flex justify-center">

  {user?.photoUrl ?
    <img src={user.photoUrl} className="h-12 my-2 w-12 rounded-full" alt="" /> :
    <div className="rounded-full bg-white dark:bg-slate-700 px-4 py-2 my-4 text-2xl font-bold font-1 text-gray-900 dark:text-gray-100">
      {user?.userData?.name.slice(0,1).toUpperCase()}
    </div>
    }
    </div>
    <div className="text-md text-center">
      {user?.userData?.name}
    </div>
  <div className="cursor-pointer flex justify-center" onClick={() => navigate(`/profile/${user?.id}`)}>
    <div>
  {userRank !== null ? <div>
{userRank === 1 ?
  <svg className="h-6 mt-1.5 mr-2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                    viewBox="0 0 300.439 300.439" xmlSpace="preserve">
                 <g>
                   <path style={{fill:"#BF392C"}} d="M276.967,0h-84.498L70.415,178.385h84.498L276.967,0z"/>
                   <path style={{fill:"#E2574C"}} d="M23.472,0h84.498l122.053,178.385h-84.498L23.472,0z"/>
                   <path style={{fill:"#EFC75E"}} d="M154.914,93.887c57.271,0,103.276,46.005,103.276,103.276s-46.005,103.276-103.276,103.276
                     S51.638,254.434,51.638,197.163S97.643,93.887,154.914,93.887z"/>
                   <path style={{fill:"#D7B354"}} d="M154.914,122.053c-41.31,0-75.11,33.799-75.11,75.11s33.799,75.11,75.11,75.11
                     s75.11-33.799,75.11-75.11S196.224,122.053,154.914,122.053z M154.914,253.495c-30.983,0-56.332-25.35-56.332-56.332
                     s25.35-56.332,56.332-56.332s56.332,25.35,56.332,56.332S185.896,253.495,154.914,253.495z"/>
                 </g>
                 </svg>
                 :

                 <div>
{userRank === 2 ?

<svg className="h-6 mt-1.5 mr-2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                      viewBox="0 0 300.439 300.439" xmlSpace="preserve">
                   <g>
                     <path style={{fill:"#BF392C"}} d="M276.967,0h-84.498L70.415,178.385h84.498L276.967,0z"/>
                     <path style={{fill:"#E2574C"}} d="M23.472,0h84.498l122.053,178.385h-84.498L23.472,0z"/>
                     <path style={{fill:"#E4E7E7"}} d="M154.914,93.887c57.271,0,103.276,46.005,103.276,103.276s-46.005,103.276-103.276,103.276
                       S51.638,254.434,51.638,197.163S97.643,93.887,154.914,93.887z"/>
                     <path style={{fill:"#CDCFCF"}} d="M154.914,122.053c-41.31,0-75.11,33.799-75.11,75.11s33.799,75.11,75.11,75.11
                       s75.11-33.799,75.11-75.11S196.224,122.053,154.914,122.053z M154.914,253.495c-30.983,0-56.332-25.35-56.332-56.332
                       s25.35-56.332,56.332-56.332s56.332,25.35,56.332,56.332S185.896,253.495,154.914,253.495z"/>
                   </g>
                   </svg>

                   :

                   <div>
                    {userRank === 3 ?
                    <svg className="h-6 mt-1.5 mr-2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                    viewBox="0 0 300.439 300.439" xmlSpace="preserve">
                 <g>
                   <path style={{fill:"#BF392C"}} d="M276.967,0h-84.498L70.415,178.385h84.498L276.967,0z"/>
                   <path style={{fill:"#E2574C"}} d="M23.472,0h84.498l122.053,178.385h-84.498L23.472,0z"/>
                   <path style={{fill:"#ED9D5D"}} d="M154.914,93.887c57.271,0,103.276,46.005,103.276,103.276s-46.005,103.276-103.276,103.276
                     S51.638,254.434,51.638,197.163S97.643,93.887,154.914,93.887z"/>
                   <path style={{fill:"#D58D54"}} d="M154.914,122.053c-41.31,0-75.11,33.799-75.11,75.11s33.799,75.11,75.11,75.11
                     s75.11-33.799,75.11-75.11S196.224,122.053,154.914,122.053z M154.914,253.495c-30.983,0-56.332-25.35-56.332-56.332
                     s25.35-56.332,56.332-56.332s56.332,25.35,56.332,56.332S185.896,253.495,154.914,253.495z"/>
                 </g>
                 </svg>
                   :
                 <svg className="h-6 mt-1.5 mr-2" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                 viewBox="0 0 495.005 495.005" xmlSpace="preserve">
              <g>
                <polygon style={{fill:"#6E76E5"}} points="247.503,86.154 247.503,0 82.161,0 138.107,86.154 	"/>
                <polygon style={{fill:"#515BE0"}} points="356.899,86.154 412.845,0 247.503,0 247.503,86.154 	"/>
                <path style={{fill:"#00EED1"}} d="M247.503,285.419c30.714,0,58.342,13.214,77.509,34.267L490.735,73.75L412.845,0l-55.946,86.154
                  L247.503,254.617L138.107,86.154L82.161,0L4.271,73.75l165.723,245.937C189.161,298.634,216.788,285.419,247.503,285.419z"/>
                <path style={{fill:"#FFE477"}} d="M227.503,436.533v-92.642h20v-58.472c-30.714,0-58.342,13.214-77.509,34.267
                  c-16.95,18.617-27.284,43.365-27.284,70.526c0,57.876,46.917,104.793,104.793,104.793v-58.472H227.503z"/>
                <path style={{fill:"#FFDA44"}} d="M247.503,285.419v58.472h20v92.642h-20v58.472c57.875,0,104.793-46.917,104.793-104.793
                  c0-27.161-10.334-51.909-27.284-70.526C305.844,298.634,278.217,285.419,247.503,285.419z"/>
                <rect x="227.503" y="343.891" style={{fill:"#6E76E5"}} width="40" height="92.642"/>
              </g>
              </svg>
                  
                  
                  }



                   </div>


}

                 </div>

}

  </div> :<></>}
    
  </div> 
    
    
    
    
    <div className="text-3xl font-1 ml-2 font-semibold">

    <div>

    {userRank !== null ? userRank : "Not ranked"}
    </div>
   
    </div>
    
    
    </div>
  </div>


  


  
</div>


</div>

<div className="mt-3 lg:mt-0 grid grid-cols-2 lg:grid-cols-4 w-full gap-4">



<div className="flex bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-100 flex-col rounded-md shadow-xl px-2 py-8">
  <div className="text-4xl text-center">
  <i className="fa-solid fa-handshake"></i>
  </div>
<div className="font-1 text-xs lg:text-lg font-extrabold text-center mt-2">Acknowledgement</div>
<div className="font-2 text-xs lg:text-sx mt-1 text-center">Acknowledge the feedback from the readers.</div>


</div>

<div className="flex bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-100 flex-col  rounded-md shadow-xl px-2 py-8">
<div className="text-4xl text-center">
  <i className="fa-solid fa-face-smile-beam"></i>
  </div>
<div className="font-1 text-xs lg:text-lg font-extrabold text-center mt-2">Grammatical errors</div>
<div className="font-2 text-xs lg:text-sx mt-1 text-center">Refrain from making grammatical errors.</div>


</div>



<div className="flex  bg-white dark:bg-slate-800 flex-col text-gray-900 dark:text-gray-100 rounded-md shadow-xl px-2 py-8">
<div className="text-4xl text-center">
  <i className="fa-solid fa-repeat"></i>
  </div>
<div className="font-1 text-xs lg:text-lg font-extrabold text-center mt-2">Consistency</div>
<div className="font-2 text-xs lg:text-sx mt-1 text-center">Be consistent in posting upcoming chapters.</div>


</div>

<div className="flex bg-white dark:bg-slate-800  flex-col text-gray-900 dark:text-gray-100 rounded-md shadow-xl px-2 py-8">
<div className="text-4xl text-center">
  <i className="fa-solid fa-democrat"></i>
  </div>
<div className="font-1 text-xs lg:text-lg font-extrabold text-center mt-2">Craft</div>
<div className="font-2 text-xs lg:text-sx mt-1 text-center">Craft an irresistible synopsis that captures the essence of your story.</div>


</div>


</div>
</div>
<div className="">

        <div className="h-[0.5px] bg-gray-300 w-full mt-4 "></div>
</div>
          <div className="font-1 text-xl lg:text-4xl font-extrabold my-4 flex items-center gap-4 text-gray-900 dark:text-gray-100">
            <div className="px-2">

            Lead Board
            </div>
          <div className="text-sm lg:text-xl ">

            <Info props={"click on the author's name to discover their works."}/>
          </div>
            </div>
            <div className="overflow-x-auto">
            <table className="table-auto text-xs lg:text-xl w-full rounded-lg shadow-2xl p-10 min-w-full">
            <thead>
              <tr className="bg-gray-100 dark:bg-slate-600 text-gray-900 dark:text-gray-100">
                
                <th className=" px-4 py-2 font-2">Ranking</th>
                <th className=" px-4 py-2 font-2 flex gap-2">
                  <div>Author's Name</div>
                </th>
                <th className=" px-4 py-2 font-2">Total Engagement</th>
              </tr>
            </thead>
            <tbody className="font-1">
              {validAuthors.slice(0,100).map((author, index) => (
                <tr
                  key={author.id}
                  className={`${user?.email === author.email ? "bg-[#002e7283] dark:bg-gray-500" : ""}  border border-b-blue-900`}
                >
                  {(author?.autherName )  && (
                    <>
                    <td className="flex text-gray-900 dark:text-gray-100 justify-center px-4 py-2 items-center ">
                     <div className="mt-3"> {index+1 === 1  ?
                    <svg className="h-2 lg:h-6 mt-1 lg:mt-1.5 mr-1 lg:mr-2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                    viewBox="0 0 300.439 300.439" xmlSpace="preserve">
                 <g>
                   <path style={{fill:"#BF392C"}} d="M276.967,0h-84.498L70.415,178.385h84.498L276.967,0z"/>
                   <path style={{fill:"#E2574C"}} d="M23.472,0h84.498l122.053,178.385h-84.498L23.472,0z"/>
                   <path style={{fill:"#EFC75E"}} d="M154.914,93.887c57.271,0,103.276,46.005,103.276,103.276s-46.005,103.276-103.276,103.276
                     S51.638,254.434,51.638,197.163S97.643,93.887,154.914,93.887z"/>
                   <path style={{fill:"#D7B354"}} d="M154.914,122.053c-41.31,0-75.11,33.799-75.11,75.11s33.799,75.11,75.11,75.11
                     s75.11-33.799,75.11-75.11S196.224,122.053,154.914,122.053z M154.914,253.495c-30.983,0-56.332-25.35-56.332-56.332
                     s25.35-56.332,56.332-56.332s56.332,25.35,56.332,56.332S185.896,253.495,154.914,253.495z"/>
                 </g>
                 </svg>  :
                 <div>
                  
                    {index+1 === 2 ?
                      <svg className="h-2 lg:h-6 mt-1 lg:mt-1.5 mr-1 lg:mr-2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                      viewBox="0 0 300.439 300.439" xmlSpace="preserve">
                   <g>
                     <path style={{fill:"#BF392C"}} d="M276.967,0h-84.498L70.415,178.385h84.498L276.967,0z"/>
                     <path style={{fill:"#E2574C"}} d="M23.472,0h84.498l122.053,178.385h-84.498L23.472,0z"/>
                     <path style={{fill:"#E4E7E7"}} d="M154.914,93.887c57.271,0,103.276,46.005,103.276,103.276s-46.005,103.276-103.276,103.276
                       S51.638,254.434,51.638,197.163S97.643,93.887,154.914,93.887z"/>
                     <path style={{fill:"#CDCFCF"}} d="M154.914,122.053c-41.31,0-75.11,33.799-75.11,75.11s33.799,75.11,75.11,75.11
                       s75.11-33.799,75.11-75.11S196.224,122.053,154.914,122.053z M154.914,253.495c-30.983,0-56.332-25.35-56.332-56.332
                       s25.35-56.332,56.332-56.332s56.332,25.35,56.332,56.332S185.896,253.495,154.914,253.495z"/>
                   </g>
                   </svg>
                   :
                   <div>
                    {index+1 === 3 ?
                  <svg className="h-2 lg:h-6 mt-1 lg:mt-1.5 mr-1 lg:mr-2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                  viewBox="0 0 300.439 300.439" xmlSpace="preserve">
               <g>
                 <path style={{fill:"#BF392C"}} d="M276.967,0h-84.498L70.415,178.385h84.498L276.967,0z"/>
                 <path style={{fill:"#E2574C"}} d="M23.472,0h84.498l122.053,178.385h-84.498L23.472,0z"/>
                 <path style={{fill:"#ED9D5D"}} d="M154.914,93.887c57.271,0,103.276,46.005,103.276,103.276s-46.005,103.276-103.276,103.276
                   S51.638,254.434,51.638,197.163S97.643,93.887,154.914,93.887z"/>
                 <path style={{fill:"#D58D54"}} d="M154.914,122.053c-41.31,0-75.11,33.799-75.11,75.11s33.799,75.11,75.11,75.11
                   s75.11-33.799,75.11-75.11S196.224,122.053,154.914,122.053z M154.914,253.495c-30.983,0-56.332-25.35-56.332-56.332
                   s25.35-56.332,56.332-56.332s56.332,25.35,56.332,56.332S185.896,253.495,154.914,253.495z"/>
               </g>
               </svg>
                 :
               <><svg className="h-2 lg:h-6 mt-1 lg:mt-1.5 mr-1 lg:mr-2" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
               viewBox="0 0 495.005 495.005" xmlSpace="preserve">
            <g>
              <polygon style={{fill:"#6E76E5"}} points="247.503,86.154 247.503,0 82.161,0 138.107,86.154 	"/>
              <polygon style={{fill:"#515BE0"}} points="356.899,86.154 412.845,0 247.503,0 247.503,86.154 	"/>
              <path style={{fill:"#00EED1"}} d="M247.503,285.419c30.714,0,58.342,13.214,77.509,34.267L490.735,73.75L412.845,0l-55.946,86.154
                L247.503,254.617L138.107,86.154L82.161,0L4.271,73.75l165.723,245.937C189.161,298.634,216.788,285.419,247.503,285.419z"/>
              <path style={{fill:"#FFE477"}} d="M227.503,436.533v-92.642h20v-58.472c-30.714,0-58.342,13.214-77.509,34.267
                c-16.95,18.617-27.284,43.365-27.284,70.526c0,57.876,46.917,104.793,104.793,104.793v-58.472H227.503z"/>
              <path style={{fill:"#FFDA44"}} d="M247.503,285.419v58.472h20v92.642h-20v58.472c57.875,0,104.793-46.917,104.793-104.793
                c0-27.161-10.334-51.909-27.284-70.526C305.844,298.634,278.217,285.419,247.503,285.419z"/>
              <rect x="227.503" y="343.891" style={{fill:"#6E76E5"}} width="40" height="92.642"/>
            </g>
            </svg></>

                  }
                   </div>
                    
                    }
                  
                 </div>
                    }
                    </div>
                      <div className="font-semibold mt-4 ">

                       {index + 1}
                      </div>
                       </td>
                     

                      <td className="text-gray-900 dark:text-gray-100  px-4  py-2 cursor-pointer" onClick={() => {
    if (
      Math.round(
        0.3 * author.viewCount +
          0.35 * author.likeCount +
          0.35 * author.commentCount
      ) > 0
    ) {
      handleAuthorClick(author.id);
    }
  }}>
                        <div className="flex">
                          <div className="hidden lg:block text-gray-100 dark:text-gray-200 w-4 lg:w-8 h-4 lg:h-8 text-center lg:pt-0.5 bg-blue-900 rounded-full ">
                            {author.autherName.slice(0, 1).toUpperCase()}
                          </div>
                          <div className="ml-2 lg:ml-4 line-clamp-1 text-gray-900 dark:text-gray-100">
                            <div className=" font-bold lg:text-xl">
                              {author.autherName}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="mb-4 flex justify-center px-4 py-2 text-gray-900 dark:text-gray-100">
                        {isNaN(Math.round(0.3 * author.viewCount + 0.35 * author.likeCount + 0.35 * author.commentCount)) ? (
                          <>0</>
                        ) : (
                          <>
                            {Math.round(0.3 * author.viewCount + 0.35 * author.likeCount + 0.35 * author.commentCount)}
                          </>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
        </div>
      )}
      <Footer/>
    </div>
  );
}

export default TopAuthorList;