import React, { useEffect, useState } from 'react';
import { ref, get, onValue } from 'firebase/database';
import { db } from '../../firebase';
import { Comment } from '../../models/story/comment/Comment';
import Reply from './AutherReply';
import Replyshow from './Replyshow';
import Feedback from '../../pages/Feedback/Feedback';
import CommentReport from './CommentReport';
import { useAppSelector } from '../../hooks/storeHook';

interface AllCommentsProps {
  storyID: string;
  autherID: string;
  storyName:string;
}

interface Feedback {
  userId: string | null; // Assuming userId is a string or null
  userName: string;
  reason: string;
}

const AllComments: React.FC<AllCommentsProps> = ({ storyID, autherID, storyName }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [report, setReport] = useState<{ [key: string]: Feedback }>({});
  const [loading, setLoading] = useState(true);
  const [reportModal, setReportModal] = useState(false)
const [showReply, setshowReply] = useState(false)
const [isReportSubmitted, setIsReportSubmitted] = useState(false);
const [reportedName, setreportedName] = useState("")



const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    // Create a reference to the comments node in the database for the specific storyID
    const commentsRef = ref(db, `allStories/${storyID}/comment/`);

    // Use Firebase's onValue event listener to fetch comments whenever the data changes
    const unsubscribe = onValue(commentsRef, (snapshot) => {
      const commentsData = snapshot.val();

      // If there are comments, convert them to an array of objects with IDs and update the state
      if (commentsData) {
        const commentsArray: Comment[] = Object.keys(commentsData).map((commentId) => ({
          id: commentId,
          ...commentsData[commentId],
        }));
        setComments(commentsArray);
      }

      setLoading(false);
    });

    // Unsubscribe from the event listener when the component unmounts
    return () => unsubscribe();
  }, [storyID]);


  useEffect(() => {
    // Create a reference to the comments node in the database for the specific storyID
    const reportRef = ref(db, `commentReports/${storyName}/`);

    // Use Firebase's onValue event listener to fetch comments whenever the data changes
    const unsubscribe = onValue(reportRef, (snapshot) => {
      const reportData = snapshot.val();
    
      if (reportData) {
        const reportsArray: any = Object.keys(reportData).map((reportID) => {
          const reportItem = reportData[reportID];
          return {
            userId: reportItem?.userId || "", // Provide a default value if userId is undefined
          };
        });
    
        setReport(reportsArray);
        console.log(report);
      }
    
      setLoading(false);
    });

    // Unsubscribe from the event listener when the component unmounts
    return () => unsubscribe();
  }, [storyID]);




  const handleReportClick = (userName:string) => {
    setReportModal(true);
    // You can use the userName value as needed in your application
    console.log(`Reporting user: ${userName}`);
    setreportedName(userName)
  };

  const reportID = Object.values(report)?.map(reportItem => reportItem?.userId)
  console.log(report)
  console.log(reportID)

  

  return (
    <div className='select-none w-full'>
      
   
      {comments.length > 0 ? (
        <div className=''>
          {comments.map((commentData) => (
              
            <div className='shadow-md rounded-md bg-white dark:bg-slate-900' key={commentData.id}>
             {reportModal && (
      <CommentReport storyId={storyID} commentId={commentData.id} storyName={storyName} userName={reportedName}   onCancel={()=>setReportModal(false)} isReportSubmitted={isReportSubmitted}/>
    )}
              <div className='my-4 p-10'>
                <div className="flex gap-4">
                  <div className='rounded-full text-center h-8 w-8 text-xl  bg-blue-900 text-white dark:text-slate-100'>{commentData.name.slice(0,1).toUpperCase()}</div>
                  <div className="flex flex-col w-full">

              <div className=' font-semibold font-1 flex justify-between text-black dark:text-slate-100'>
                <div>
                 {commentData.name}
                </div>
               
                {Object.values(report).some(reportItem => reportItem?.userId === user?.id) ? (
                  <div className='text-xs text-gray-500 cursor-not-allowed'>
                          Reported <i className="ml-2 fa-solid fa-flag"></i>
                        </div>
                      ) : (
                        <div className='text-xs cursor-pointer ' onClick={() => handleReportClick(commentData.name.toString())}>
                          Report <i className="ml-2 fa-solid fa-flag"></i>
                        </div>
                      )}
                    
                      
                 </div>
              <div className='text-md flex font-regular font-2 opacity-50 text-black dark:text-slate-100'>
                <div> {commentData.comment} </div> 
                
                  </div>
              {/* <i className="fa-sharp fa-solid fa-reply"></i> */}
              <div className="flex">

              </div>

              <Reply
                storyID={storyID}
                autherID={autherID}
                commentID={commentData.id}
              /> 
              <Replyshow storyID={storyID} commentID={commentData.id} />
                  </div>

                </div>

             
              
              </div>
             

            </div>
          ))}
        </div>
      ) : (
        <p className='dark:text-white text-slate-900'>No comments yet.</p>
      )}
    </div>
  );
};

export default AllComments;