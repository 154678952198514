import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { ref, onValue, update } from 'firebase/database';
import Header from './Header';
import { useAppSelector } from '../../hooks/storeHook';
import { UserNotification } from '../../models/user/Notification';
import { useNavigate, useParams } from 'react-router-dom';
import GlobalNotification from './GlobalNotification';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function Notifications() {
  const [notifications, setNotifications] = useState<Record<string, UserNotification>>({});
  const { userID } = useParams();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { user } = useAppSelector((state) => state.auth);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);


  const navigate = useNavigate();
  const markAllAsRead = async () => {
  
    try {
      // Create an object to update multiple paths
      const updates: Record<string, boolean> = {};
      Object.keys(notifications).forEach((notificationId) => {
        updates[`authers/${user?.id}/notification/${notificationId}/isread`] = true;
      });

      // Update all paths in a single request
      await update(ref(db), updates);

      // Update the local state to reflect the changes
      const updatedNotifications = { ...notifications };
      Object.keys(updatedNotifications).forEach((notificationId) => {
        updatedNotifications[notificationId].isread = true;
      });
      setNotifications(updatedNotifications);
      setHasUnreadNotifications(false);
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };
  useEffect(() => {
    const fetchNotifications = async () => {
      if (user && user.id) {
        setLoading(true);
        try {
          const storyArchiveRef = ref(db, `authers/${user?.id}/notification/`);

          onValue(storyArchiveRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              const storyArchiveData: Record<string, any> = data;

              setNotifications(storyArchiveData);
              const hasUnread = Object.values(storyArchiveData).some(
                (notification) => !notification.isread
              );
              setHasUnreadNotifications(hasUnread);
            }
            setLoading(false);
          });
        } catch (error: any) {
          setLoading(false);
          setErrorMessage(error);
        }
      }
    };
    fetchNotifications();
  }, [user, userID]);

  return (
    <Menu as="div" className="relative inline-block text-left  select-none">
      <div>
        {user?.id?
        <Menu.Button className="inline-flex relative justify-center rounded-md text-sm text-white ">
          <div className='' onClick={markAllAsRead}>
            <i className='fa-sharp fa-solid fa-bell opacity-80 hover:opacity-100 text-[#F8F6F0] '></i>
            {hasUnreadNotifications && (
              <div className="absolute text-[0.8vw] -top-1.5 left-1.5 right-0 bg-red-500 w-4 h-4 rounded-full flex items-center justify-center text-white font-bold">
    {Object.values(notifications).filter((notification) => !notification.isread).length}
  </div>
 )} 
          </div>
        </Menu.Button>
        :
        <button className="inline-flex relative justify-center rounded-md text-sm text-white ">
        <div className='' onClick={()=>navigate("/auth")}>
          <i className='fa-sharp fa-solid fa-bell opacity-80 hover:opacity-100 text-[#F8F6F0] '></i>
          
        </div>
      </button>
    }
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-[40rem] h-[20rem] overflow-y-auto max-h-screen origin-top-right rounded-md bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-50 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            
            <Menu.Item>
              
                <div className="px-2 overflow-y-auto">
                  <GlobalNotification />
                  {Object.keys(notifications).length === 0 ? (
                <p className='p-10 flex items-center justify-center '>No notifications found.</p>
              ) : (
                  <ul>
                    {Object.entries(notifications).map(([notificationId, notification]) => (
                      <li
                        className={` my-2 py-2 text-sm shadow-md  ${
                          notification.type === 'like'
                            ? 'border-l-4 border-r-4 p-1 border-red-500'
                            : 'border-l-4 border-r-4 p-1 border-blue-500'
                        }`}
                        key={notificationId}
                        onClick={() => {
                          // Mark the notification as read when clicked
                          const updatedNotifications = Object.fromEntries(
                            Object.entries(notifications).map(([id, notif]) => [
                              id,
                              { ...notif, isread: true },
                            ])
                          );
                          setNotifications(updatedNotifications);
                          setHasUnreadNotifications(false);
                        }}
                      >
                        {notification.type === 'like' ? (
                          <>
                            <i className="fa-sharp fa-solid fa-heart text-red-500 "></i>
                          </>
                        ) : (
                          <>
                            <i className="fa-sharp fa-solid fa-comment text-blue-500 "></i>
                          </>
                        )}
                        <span className='font-bold ml-2'>
                          {notification.userName.toUpperCase()}{' '}
                        </span>
                        {notification.type === 'like' ? (
                          <>liked your</>
                        ) : (
                          <>commented on</>
                        )}{' '}
                        story{' '}
                        <span className='font-semibold'>{notification.storyTitle}</span>
                      </li>
                    ))}
                  </ul>
              )}
                </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}