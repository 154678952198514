import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { authClasses } from './Auth/authClasses';
import { ref, push, set, get } from 'firebase/database';
import { db } from './../firebase';
import { useAppSelector } from './../hooks/storeHook';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from './../components/Header/Header';
import { FeedbackForm, FeedbackFormSchema, ReportForm, ReportFormSchema } from './../models/other/FeedbackForm';

import bg from "./../assets/bg/reportBg.jpg"
import Footer from './../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import Info from './Story/Info';
const Report = () => {
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const {
    container,
    form,
    button,
    input,
    text,
    link,
    hr,
    forgotPasswordButton,
  } = authClasses;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [success, setSuccess] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState<number | null>(null);
const [titleStory, settitleStory] = useState("")
  const handleChapterButtonClick = (chapterNumber: number) => {
    setSelectedChapter(chapterNumber);
  };

  const { auther } = useAppSelector((state) => state.auther);

  const onSubmit = (data: FeedbackForm) => {
    const { title, category, desc } = data;
    // Additional processing or handling, if needed
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReportForm>({
    resolver: yupResolver(ReportFormSchema),
  });

  const handleFormSubmit = async (data: ReportForm) => {
    const wordCount = data.desc?.split(/\s+/).filter(Boolean).length; 
      console.log(wordCount)
      if (wordCount > 50 || wordCount<10) {
        toast.warning(wordCount >50 ? `Report cannot exceed 100 words. current word Count ${wordCount}`:`Report cannot be less than 10 words. current word Count ${wordCount}`);
        return;
      }
    try {
      setLoading(true);
      const commonFeedbackIdRef = ref(db, 'report');
      const commonFeedbackId = push(commonFeedbackIdRef).key;

      if (commonFeedbackId) {
        await set(ref(db, `report/${commonFeedbackId}`), data);
        setLoading(false);
        setSuccess(true);
        navigate(-1);
      } else {
        // Handle the case where commonFeedbackId is null
      }
    } catch (error: any) {
      setLoading(false);
      const errorCode = error.code;
      setErrorMessage(errorCode);
    }
    onSubmit(data);
  };


  useEffect(() => {
    const fetchStoryDetails = async () => {
      try {
        const storyRef = ref(db, `allStories/${id}`);
        const snapshot = await get(storyRef);
        const storyData = snapshot.val();
  
        if (storyData) {
          setLoading(true);
          settitleStory(storyData.title);
          
  
          
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };
  
    fetchStoryDetails();
  }, [id,]);

  return (
    <div className='select-none'>
      {/* <Header /> */}
      <ToastContainer/>
      <div className='flex justify-center items-center  bg-[#dddcdc] py-24 ' style={{
        backgroundImage: ` url(${bg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  // backgroundAttachment: "fixed",
      }}>
        <div className='bg-[#2f48b8ab] lg:w-2/3  flex flex-col justify-center items-center rounded-md '>
        <div className='text-white text-3xl justify-center w-full text-center mt-4 mb-4 font-1 font-bold '>
Report issue
          </div>
        <form onSubmit={handleSubmit(handleFormSubmit)} className={"mb-12  p-8 bg-white dark:bg-slate-800 rounded-lg "}>
          
          <div>
          <label htmlFor="title" className='text-gray-700 dark:text-gray-200 font-2 font-medium '>Email:</label>
          <input className={input} value={user?.email} readOnly  type="text" {...register('name')} />
          </div>

          <div className="md:flex gap-4">

          <div className='w-full mt-4'>
            <label htmlFor="title" className='text-gray-700 dark:text-gray-200 font-2 font-medium'>Title:</label>
            <input className={input} value={titleStory} readOnly type="text" {...register('title')} />
            {errors.title && <span>{errors.title.message}</span>}
          </div>

          <div className='w-full mt-4'>
            <label htmlFor="category" className='text-gray-700 dark:text-gray-200 font-2 font-medium'>Issue:</label>
            <select className={input} {...register('category')} required>
              {/* Options */}
              <option value="">Select</option>
  <option value="CopyViolation">This user has copied my work</option>
  <option value="PlotSpoilers">Plot or Storyline Spoilers</option>
  <option value="MisleadingTitles">Misleading Titles or Summaries</option>
  <option value="Plagiarism">Plagiarism</option>
  <option value="InappropriateContent">Inappropriate Content</option>
  <option value="CopyrightInfringement">Copyright Infringement</option>
  <option value="HarassmentBullying">Harassment or Bullying</option>
  <option value="EmotionalManipulation">Emotional Manipulation or Clickbait</option>
  <option value="CharacterImpersonation">Impersonation of Characters or Real Individual</option>
  <option value="PrivateInformation">This content contains my private information which I did not permit to be used here by this person</option>
  <option value="GrammaticalErrors">Reading experience: Contains excessive grammatical errors</option>
  <option value="FraudulentActivity">Fraudulent Activity</option>
  <option value="ReligiousRadicalisation">Religious radicalisation</option>
  <option value="MaturedLGBTQIAContent">Untagged matured/LGBTQIA+ content</option>
  <option value="TechnicalIssues">Technical Issues Reporting</option>
  <option value="FalseInformation">False information</option>
  <option value="HateSpeech">Hate Speech</option>
  <option value="UserEngagementManipulation">User Engagement Manipulation</option>
  <option value="UserSafetyWellBeing">User Safety and Well-being</option>
  <option value="Other">Other</option>
</select>
            {errors.category && <span>{errors.category.message}</span>}
          </div>
          </div>

         

          <div className='mt-4'>
            <div className="flex gap-2 mb-1">

            <label htmlFor="desc" className='text-gray-700 mt-1 dark:text-gray-200 font-2 font-medium'>Please provide the details:
             </label>
             {/* <Info props={"Please provide the details."}/> */}
            </div>
            <textarea className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline resize-none h-40"} {...register('desc')} />
            {errors.desc && <span>{errors.desc.message}</span>}
          </div>
<div className='flex justify-center mt-4'>

          <button className={"rounded-md text-white px-4 py-2.5 bg-blue-900"} type="submit">
            {loading ? 'Loading...' : 'Submit'}
          </button>
</div>
        </form>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Report;