import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { app } from "../../firebase";
import { db } from "../../firebase";
import { ref, onValue, off, get } from "firebase/database";
import { Story } from "../../models/story/Story";
import { getDownloadURL, getStorage, ref as sref } from "firebase/storage";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useAppSelector } from "../../hooks/storeHook";

import bg from "../../assets/bgBanner.gif"
import InfoCustom from "../Story/InfoCustom";
import SingleBanner from "./bannerMini.tsx/SingleBanner";
const truncateSummary = (
  summary: string,
  wordLimit: number,
  lineLimit: number
) => {
  const words = summary.split(" ");
  const lines = summary.split("\n");
  const truncatedWords = words.slice(0, wordLimit);
  const truncatedLines = lines.slice(0, lineLimit);

  let truncatedSummary = truncatedWords.join(" ");
  if (words.length > wordLimit) {
    truncatedSummary += "...";
  }

  if (lines.length > lineLimit) {
    truncatedSummary = truncatedLines.join("\n") + "...";
  }

  return truncatedSummary;
};

const Banner: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
 

  const indicatorStyles: React.CSSProperties = {
    position: "relative",
    background: "#002e727b", // Change the color to #002D72
    width: "3vw", // Adjust the width as needed
    height: "4px", // Adjust the height as needed
    display: "inline-block",
    margin: " 2vh 8px",
    borderRadius: "2px", // Adjust the border-radius as needed for a flat line
    cursor: "pointer",
  };

  // Active indicator style
  const activeIndicatorStyles: React.CSSProperties = {
    ...indicatorStyles,
    background: "#002D72 ", // Change the active color if needed
  };

  // Custom render function for indicators
  const customRenderIndicator = (
    onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    index: number,
    label: string
  ) => {
    return (
      <li
        style={isSelected ? activeIndicatorStyles : indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        title={`${label} ${index + 1}`}
        aria-label={`${label} ${index + 1}`}
      />
    );
  };

  const arrowStyles: React.CSSProperties = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    cursor: "pointer",
  };

  // Custom render function for the previous arrow
  const customRenderPrevArrow = (
    onClickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) =>
    hasPrev && (
      <button
        type="button"
        className="-mt-8"
        onClick={onClickHandler}
        title={label}
        style={{ ...arrowStyles, left: 0.5 }}
      >
        <svg
          width="38"
          height="78"
          viewBox="0 0 38 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.3" cx="-1" cy="39" r="39" fill="#002D72" />
          <path
            d="M20.0304 46.7796C20.3232 46.4867 20.3232 46.0118 20.0304 45.7189L13.3107 38.9992L20.0304 32.2796C20.3232 31.9867 20.3232 31.5118 20.0304 31.2189C19.7375 30.926 19.2626 30.926 18.9697 31.2189L11.7197 38.4689C11.4268 38.7618 11.4268 39.2367 11.7197 39.5296L18.9697 46.7796C19.2626 47.0724 19.7375 47.0724 20.0304 46.7796Z"
            fill="#002D72"
          />
        </svg>
      </button>
    );

  // Custom render function for the next arrow
  const customRenderNextArrow = (
    onClickHandler: () => void,
    hasNext: boolean,
    label: string
  ) =>
    hasNext && (
      <button
        className="-mt-8 bg-[]"
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{ ...arrowStyles, right: 0.5 }}
      >
       <svg
  width="38"
  height="78"
  viewBox="0 0 38 78"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    opacity="0.3"
    cx="39"
    cy="39"
    r="39"
    transform="matrix(-1 0 0 1 78 0)"
    fill="#002D72" 
  />
  <path
    d="M17.9691 46.7796C17.6763 46.4867 17.6763 46.0118 17.9691 45.7189L24.6888 38.9992L17.9691 32.2796C17.6763 31.9867 17.6763 31.5118 17.9691 31.2189C18.262 30.926 18.7369 30.926 19.0298 31.2189L26.2798 38.4689C26.5727 38.7618 26.5727 39.2367 26.2798 39.5296L19.0298 46.7796C18.7369 47.0724 18.262 47.0724 17.9691 46.7796Z"
    fill="#002D72"
  />
</svg>

      </button>
    );

 
    const storyNumbers = [0, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <div className=" bg-white select-none -mb-5"
   
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.25)), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // backgroundAttachment: "fixed",
        
            }}
          
    >
      <Carousel
         showArrows={true}
         showThumbs={true}
        //  autoPlay={true}
        //  infiniteLoop={true}  
        //  interval={5000}
         swipeable={false}
         renderArrowPrev={customRenderPrevArrow}
         renderArrowNext={customRenderNextArrow}
         renderIndicator={customRenderIndicator}
         onChange={(index) => setActiveIndex(index)}
      >

         
        <SingleBanner storyNumber={0}/>
        
        <SingleBanner storyNumber={2}/>
        
        <SingleBanner storyNumber={3}/>
        
        <SingleBanner storyNumber={4}/>
        
        <SingleBanner storyNumber={5}/>
        
        <SingleBanner storyNumber={6}/>
        
        <SingleBanner storyNumber={7}/>

        
        <SingleBanner storyNumber={8}/>

        
        <SingleBanner storyNumber={9}/>
      </Carousel>
    </div>
  );
};

export default Banner;
