import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, getStorage, ref as sref } from "firebase/storage";
import { auth, db } from "../../firebase";
import { ref, get, runTransaction, update, DataSnapshot, onValue, off } from "firebase/database";
import { Story } from "../../models/story/Story";
import Header from "../../components/Header/Header";
import Like from "../../components/Story/Like";
import Comment from "../../components/Story/Comment";
import AllComments from "../../components/Story/AllComments";
import ChapterFetch from "../../components/Story/ChapterFetch";
import IsExclusiveForm from "./IsExclusiveForm";
import { useAppSelector } from "../../hooks/storeHook";
import ChapterPaging from "../../components/Story/Chapterpaging";
import AdvertisementComponent from "../../components/Story/Advertisement";
import StoryCard from "../../components/Story/StoryCard";
import MoreFromAuther from "../../components/Story/MoreFromAuther";
import Share from "../../components/Story/Share";
import SuggestionCard from "../../components/Story/Suggestions";
import Footer from "../../components/Footer";
import 'animate.css';
import Skeleton from "react-loading-skeleton";
import { Advertisement } from "../../models/story/ad";



const StoryDetailsPage = () => {
  const { id, chapterId } = useParams<{ id: string; chapterId: string }>();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [story, setStory] = useState<Story | null>(null);
  const [hasViewed, setHasViewed] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const storageRef = getStorage();
  const { user } = useAppSelector((state) => state.auth);
  const [mobileAd, setmobileAd] = useState(true)
  const navigate = useNavigate()
  const userDetails = useAppSelector((state) => state.auth.user?.userData);
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, });
  }, []);
  useEffect(() => {
    const user = auth.currentUser;
    if (!user?.emailVerified) {
      navigate(`/verification-pending/${user?.uid}`);
   
    }else
    { if(user && !userDetails) {
         navigate(`/credform/${user?.uid}`);
       }
      }
    
  }, []);

  const [advertisement, setAdvertisement] = useState<Advertisement[]>([]);
  const [advertisementIndex, setAdvertisementIndex] = useState<number | null>(null);

  useEffect(() => {
    const adRef = ref(db, 'advertisement');
    const unsubscribe = onValue(adRef, (snapshot: DataSnapshot) => {
      const data = snapshot.val();
      if (data) {
        setAdvertisement([data]);
      }
      // console.log("hy",advertisement.length)

       // Reset the index when data updates
    });

    return () => {
      off(adRef, 'value', unsubscribe);
    };
  }, []);


  
  useEffect(() => {
    const fetchStoryDetails = async () => {
      try {
        const storyRef = ref(db, `allStories/${id}`);
        const snapshot = await get(storyRef);
        const storyData = snapshot.val();

        if (storyData) {
          setLoading(true)
          const coverURL = await getDownloadURL(
            sref(
              storageRef,
              `authersImageData/${storyData.autherEmail}/storiesArchive/${id}/cover/${storyData.cover}`
            )
          ).catch(() => {
            return storyData.title
          });
          const likeCountRef = ref(db, `allStories/${id}/Likes`);
          const likeCountSnapshot = await get(likeCountRef);
          const likeCount = likeCountSnapshot.exists() ? Object.keys(likeCountSnapshot.val()).length : 0;

          setStory({
            id,
            ...storyData,
            coverURL,
            likeCount: likeCount
          });

          // Set initial title for editing
          setEditedTitle(storyData.title);
          setLoading(false)
        }
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchStoryDetails();
  }, [id, storageRef]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(event.target.value);
  };

  const saveTitle = async () => {
    if (editedTitle) {
      try {
        const storyRef = ref(db, `allStories/${id}`);
        await update(storyRef, { title: editedTitle });
        setIsEditingTitle(false); // Disable editing mode after saving
      } catch (error) {
        console.error("Error updating title:", error);
      }
    }
  };

 

  const leftAdvertisements = [
    {
      id: 'left-1',
      title: 'Left Advertisement 1',
      img: 'https://example.com/left-advertisement1.jpg',
    },
    {
      id: 'left-2',
      title: 'Left Advertisement 2',
      img: 'https://example.com/left-advertisement2.jpg',
    },
    // Add more left advertisements as needed
  ];

  const rightAdvertisements = [
    {
      id: 'right-1',
      title: 'Right Advertisement 1',
      img: 'https://example.com/right-advertisement1.jpg',
    },
    {
      id: 'right-2',
      title: 'Right Advertisement 2',
      img: 'https://example.com/right-advertisement2.jpg',
    },
    // Add more right advertisements as needed
  ];

  return (
    <div className="select-none">

{(mobileAd && advertisement.length>0)&&
 <div className="flex lg:hidden  fixed top-0 right-0 z-50 justify-center items-center content-center w-full h-full p-4 overflow-x-hidden overflow-y-scroll bg-black bg-opacity-70 md:inset-0 md:h-full ">
<div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-opacity-900 backdrop-filter backdrop-blur-lg">
    <div className='bg-white pb-4 rounded-lg overflow-y-auto'>
        <div className='text-xl text-end text-red-500 mr-2 cursor-pointer ' onClick={()=>setmobileAd(false)}>
        <i className="fa-solid fa-times-circle"></i>
        </div>

        <AdvertisementComponent/>
    
        </div>
    </div>
  </div>}
      {/* <Header /> */}
      {/* {!story?(<>loading</>):
    errorMessage?(<>{errorMessage}</>):
    (  */}
    
    <div className="flex flex-col bg-white dark:bg-slate-800">
      <div className="bg-gray-900" >
      <div className="m-0 p-0  justify- flex flex-col items-" 
      style={{
      backgroundImage: `
      linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), 
      url(${story?.coverURL ? story?.coverURL : ""})`,
backgroundRepeat: "no-repeat",
backgroundSize: "cover",
    }}>
     {!story?.coverURL ?
    
    <div className=" h-24 lg:h-96 mx-auto lg:w-64 my-8 bg-gray-500  rounded-sm justify-center flex flex-col items-center ">
    
    <Skeleton className="h-full w-full" />
    </div>:
    <div className={`h-24 lg:h-96 lg:mx-auto lg:w-64 my-24 rounded-sm justify-center flex flex-col items-center ${loading ?"": "animate__animated animate__flip"}`}>
    
          <img src={story?.coverURL} className=" object-cover h-48 lg:h-full bg-white" alt="Story Cover" />
    </div>
    
    }
      </div>
      </div>
     
      {/* <div className="absolute top-32 w-full flex flex-col">
        
     {!story?.coverURL ?
    
<div className=" h-24 lg:h-96 mx-auto lg:w-64  bg-gray-500  rounded-sm justify-center flex flex-col items-center ">

<Skeleton className="h-full w-full" />
</div>:
<div className={`h-24 lg:h-96 mx-auto lg:w-64  bg-gray-500  rounded-sm justify-center flex flex-col items-center ${loading ?"": "animate__animated animate__flip"}`}>

      <img src={story?.coverURL} className=" lg:object-fill  bg-white" alt="Story Cover" />
</div>

}
</div> */}
<div className="texl-xl lg:text-5xl w-full text-center mt-3 pt-4 pb-2 font-bold text-black dark:text-white ">{story?.title} </div>

      <div className=" flex justify-between w-full h-full">
      {story?.category==="Fanfiction" ?
<div className="h-full w-1/5 hidden lg:block">
</div>:
<div className="h-full w-1/5 hidden lg:block">
  <AdvertisementComponent/>
  <AdvertisementComponent/>
</div>
}
<div className=" w-full ">


  <div className="lg:px-5">

<ChapterFetch autherID={story?.autherId} storyID={id} chapterID={chapterId} autherName={story?.autherName} title={story?.title}/>
  </div>
</div>
{story?.category==="Fanfiction" ?
<div className="h-full w-1/5 hidden lg:block">
</div>:
<div className="h-full w-1/5 hidden lg:block">
  <AdvertisementComponent/>
  <AdvertisementComponent/>
</div>
}
      </div>
      </div>
      {/* )} */}

     
      <div className="lg:px-12 pt-8 bg-white dark:bg-slate-800">
        <ChapterPaging storyId={story?.id || ""} autherId={story?.autherId || ""} />

    

        <div className="w-full text-black font-1 font-bold text-3xl pt-10  dark:text-white mt-4 px-2 lg:mt-0 lg:px-0">You may also like</div>
      <div className="py-">
            <SuggestionCard count={4}/>
      </div>
          </div>
      <Footer/>
    </div>
  );
};

export default StoryDetailsPage;