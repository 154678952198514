import React, { useState, useEffect } from "react";
// import { db } from "./firebase"; // Update the path to your Firebase configuration
import { DataSnapshot, DatabaseReference, off, onValue, ref } from "firebase/database";
import { db } from "../firebase";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
const About = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  useEffect(() => {
    // Fetch the privacy policy data from the Realtime Database
    const policyRef = ref(db, "aboutUs");

    const onDataCallback = (snapshot: DataSnapshot) => {
      const policyData = snapshot.val();
      if (policyData) {
        setPrivacyPolicy(policyData);
      }
    };

    const policyListener = onValue(policyRef, onDataCallback);

    return () => {
      // Clean up the Firebase listener when the component unmounts
      off(policyRef, "value", policyListener);
    };
  }, []);

  return (
    <div className="select-none">
      {/* <Header/> */}
      <Helmet>
        <meta name="title" content="Know More About Us | Likhasi" />

        <meta name="description" content="Know more about the Likhasi platform to read and write your story for free. Become an author to promote your work here. Explore Here to know more" />
        
        <title>Know More About Us | Likhasi</title>
      </Helmet>
      <div className="p-10 bg-white dark:bg-slate-800">

      <div className="text-xl font-1 mt-3  text-gray-700 dark:text-gray-100">About us</div>
{/* <ReactQuill
  value={privacyPolicy}
  readOnly
  theme="snow"
  modules={{
    toolbar: false, // Disable the toolbar for read-only mode
  }}
/> */}
<div className="font-2 text-lg text-gray-700 dark:text-gray-200"  dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
</div>
      <Footer/>
    </div>
  );
};

export default About;