import { useEffect, DependencyList } from 'react'

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps?: any,
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps)
}
export function formatNumber(value:number) {
  // convert the value to a number
  value = Number(value);
  // if the value is less than 1000, return it as it is
  if (value < 1000) return value;
  // if the value is between 1000 and 1 million, divide it by 1000 and add a 'K' suffix
  if (value >= 1000 && value < 1000000) return (value / 1000).toFixed(0) + 'K';
  // if the value is more than 1 million, divide it by 1 million and add a 'M' suffix
  if (value >= 1000000) return (value / 1000000).toFixed(0) + 'M';
}