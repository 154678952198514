import * as yup from "yup";

export const FeedbackFormSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  category: yup.string().required("Category is required"),
  desc: yup.string().required("Description are required"),
  
  
});

export interface FeedbackForm {
    title: string,
    category:string
    desc: string,
}

export const ReportFormSchema = yup.object().shape({
  name: yup.string().required("name is required"),
  title: yup.string().required("Title is required"),
  category: yup.string().required("Category is required"),
  desc: yup.string().required("Description are required"),
  
  
});

export interface ReportForm {
  name:string;
    title: string,
    category:string
    desc: string,
}