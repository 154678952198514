import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Home from "./pages/Home/Home";
import Profile from "./pages/Profile/Profle";
import { auth, db } from "./firebase";
import { useAppDispatch, useAppSelector } from "./hooks/storeHook";
import { login } from "./features/authSlice";
import AuthRoutes from "./components/HOC/AuthRoutes";
import Auth from "./pages/Auth/Auth";
import UserCredentialForm from "./pages/UserCredentialForm/UserCredentialForm";
import Auther from "./pages/Auther/Auther";
import StoryAdd from "./pages/Story/StoryAdd";
import ChapterAdd from "./pages/Story/ChapterAdd";
import { autherLogin } from "./features/userAutherSlice";
import { get, ref, set, update } from "firebase/database";
import Feedbackform from "./pages/Feedback/Feedbackform";
import CategoryPage from "./pages/Categories/CategoriesPage";
import StoryDetailsPage from "./pages/Story/storyRead";
import SearchDemo from "./components/Story/Search";
import Notification from "./components/Header/Notifications";
import TopAuthorList from "./pages/Allauthers";
import PrivacyPolicies from "./pages/PrivacyPolicies";
import NotFoundPage from "./pages/NotFoundPage";
import VerificationPending from "./pages/Verification";
import TopCompletedStoriesCard from "./components/Story/TopCompleteStories";
import TopListedStories from "./components/Story/TopListedStories";
import Synopsis from "./components/Story/Synopsis";
import ViewAll from "./pages/Story/ViewAll";
import Terms from "./pages/Terms";
import HelpSupport from "./pages/HelpSupport";
import StoriesByAuther from "./pages/Story/StoriesByAuther";
import StoryCover from "./components/Story/StoryCover";
import Report from "./pages/Report";
import Feedback from "./pages/Feedback/Feedback";
import About from "./pages/Aboutus";

import Header from "./components/Header/Header";
import React from "react";
import Loading from "./pages/Home/Loading";

const App = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAppSelector((state) => state.auth);
  const userDetails = useAppSelector((state) => state.auth.user?.userData);

  const userAuther = useAppSelector((state) => state.auth.user?.isAuther);
  // const toggleTheme = ()=>{
  //     setDarkMode(!darkMode);
  //     const root = window.document.documentElement; //add 'dark class to html root element'
  //     root.classList.toggle('dark');
  //   }
  //   if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  //     document.documentElement.classList.add('dark')
  //   } else {
  //     document.documentElement.classList.remove('dark')
  //   }
  const navigate = useNavigate();
  const { auther } = useAppSelector((state) => state.auther);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && user.email) {
        setIsLoading(true); // Show loading indicator

        const userDetailsRef = ref(db, `users/${user?.uid}/userData`);
        const isAutherRef = ref(db, `users/${user?.uid}/isAuther`);
        const userBookShelfRef = ref(db, `users/${user?.uid}/bookshelf`);
        
        const userSuspensionRef = ref(db, `users/${user?.uid}/suspension`);
        const userDetailsSnapshot = await get(userDetailsRef);
        const isautherSnapshot = await get(isAutherRef);
        const bookshelfSnapshot = await get(userBookShelfRef);
        const userSuspensionSnapshot = await get(userSuspensionRef)

        if (!user.emailVerified) {
          setIsLoading(false); // Hide loading indicator
          navigate(`/verification-pending/${user.uid}`);
        } else {
          if (userDetailsSnapshot.exists()) {
            const userDetailsData = userDetailsSnapshot.val();
            const userBookshelfData = bookshelfSnapshot.val();
            const userSuspensionData = userSuspensionSnapshot.val();

            dispatch(
              login({
                email: user.email,
                id: user.uid,
                photoUrl: user?.photoURL || null,
                isAuther: userAuther || false,
                userData: userDetailsData || null,
                bookshelf: userBookshelfData || null,
                suspension:userSuspensionData || null
              })
            );

            if (isautherSnapshot.exists()) {
              const isAutherData = isautherSnapshot.val();

              dispatch(
                login({
                  email: user.email,
                  id: user.uid,
                  photoUrl: user?.photoURL || null,
                  isAuther: isAutherData,
                  userData: userDetailsData || null,
                  bookshelf: userBookshelfData || null,
                  suspension:userSuspensionData || null
                })
              );
            }

            setIsLoading(false); // Hide loading indicator
          } else {
            setIsLoading(true);
            dispatch(
              login({
                email: user.email,
                id: user.uid,
                photoUrl: user?.photoURL || null,
                isAuther: userAuther || false,
                userData: null,
                bookshelf: null,
                suspension: null
              })
              
            );

            if (!user.emailVerified) {
              setIsLoading(false); // Hide loading indicator
              navigate(`/verification-pending/${user.uid}`);
            } else {
              navigate(`/credform/${user.uid}`);
            }
            setIsLoading(false); // Hide loading indicator
          }
        }
      } else {
        setIsLoading(false); // Hide loading indicator
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  const [darkMode, setDarkMode] = React.useState(() => {
  // Use a function as the initial state to compute the value only once
  const storedTheme = localStorage.getItem('theme');

  if (!storedTheme) {
    localStorage.setItem('theme',"light")
  }
  return storedTheme === 'dark' || (!storedTheme && window.matchMedia('(prefers-color-scheme: dark)').matches);
});

  const toggleTheme = () => {
    setDarkMode((prevDarkMode) => {
      const newDarkMode = !prevDarkMode;
      localStorage.setItem('theme', newDarkMode ? 'dark' : 'light');
      const root = window.document.documentElement;
      root.classList.toggle('dark', newDarkMode);
      return newDarkMode;
    });
  };

  useEffect(() => {
    // Move the initial theme setup inside useEffect
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);
  

  const currentPath = window.location.pathname;

  // Check if the current route is "/auth"
  const isAuthRoute = currentPath === '/auth' ;

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen "> <Loading/></div>
    // <div className="flex justify-center items-center h-screen"><img src={loader} alt="" /></div>; // Show a loading indicator or nothing while data is being fetched
  }
  
  return (
    <div 
    className={`app ${darkMode ? 'dark' : 'light'} select-none`}
    >
{!isAuthRoute && <Header darkMode={darkMode} toggleDarkMode={toggleTheme }// toggleDarkMode={toggleDarkMode} 
/>}
    <Routes >
<Route path="/" element={<Home />} />
      <Route element={<AuthRoutes />}>
        <Route path="/profile/:userId" element={<Profile />} />
        <Route path="/credform/:userId" element={<UserCredentialForm />} />
        {/* <Route path="/auther/:userId" element={<Auther />} /> */}
        <Route path="/addstory/:userId" element={<StoryAdd />} />
        <Route path="/addchapter/:storyId" Component={ChapterAdd} />

        <Route
          path="/story/:id/chapter/:chapterId"
          Component={StoryDetailsPage}
        />
        <Route path="/addcover/:storyId" Component={StoryCover} />
        <Route path="/notify/:userId" Component={Notification} />
        <Route path="/synopsis/:id/chapter/:chapterId" Component={Synopsis} />

        <Route path="/allauthors/:userId" Component={TopAuthorList} />
        {/* <Route path="/mybookmarks/:userId" Component={StoryCard3} /> */}
        <Route
          path="/verification-pending/:userId"
          element={<VerificationPending />}
        />
        <Route path="/search/:userId" element={<SearchDemo />} />
        <Route path="/feedback/:userId" element={<Feedback />} />
        
        <Route path="/report/:id" element={<Report />} />
      </Route>
      <Route path="/category/:genre" Component={CategoryPage} />
      <Route path='/section/:typeOfStory' Component={ViewAll}/>
      
      <Route path='/byauther/authers/:id/storiesArchive' Component={StoriesByAuther}/>
      <Route path="/lateststories" Component={TopListedStories} />
      <Route path="/privacypolicy" element={<PrivacyPolicies />} />
      
      <Route path="/aboutus" element={<About />} />
      
      <Route path="/terms&conditions" element={<Terms />} />
      <Route path="/help&support" element={<HelpSupport />} />
      <Route path="auth" element={<Auth />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    </div>
  );
};

export default App;
