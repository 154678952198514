import React, { useState } from "react";
import { StoryForm } from "../../models/story/StoryForm";
import { push, ref, set, update } from "firebase/database";

import { db } from "../../firebase";
import { useAppSelector } from "../../hooks/storeHook";
import { useNavigate, useNavigation } from "react-router-dom";
interface PreviewProps {
  message: string;
  onCancel: () => void;
  formData: any;
  storyID:string
}

const Preview: React.FC<PreviewProps> = ({
  message,
  onCancel,
  formData,
  storyID
}) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const [loading, setloading] = useState(false)
  
  const handleConfirmClick = async () => {
    setloading(true)
    try {
        const chapterRefPath = `authers/${user?.id}/storiesArchive/${storyID}/chapters/`;
        const allStoryRefPath = `allStories/${storyID}/chapters/`;
  
        // Generate a common ID for both references
        const commonChapterId =  push(ref(db, chapterRefPath)).key;
  
        const chapterRef = ref(db, `${chapterRefPath}${commonChapterId}`);
        const allStoryRef = ref(db, `${allStoryRefPath}${commonChapterId}`);




            formData.timestamp = Math.floor(Date.now() / 1000);
            await set(chapterRef, formData);
            await set(allStoryRef, formData);
            await update(ref(db, `authers/${user?.id}/storiesArchive/${storyID}`),  {timestamp: Math.floor(Date.now() / 1000)} )
            await update(ref(db, `allStories/${storyID}`),  {timestamp: Math.floor(Date.now() / 1000)} )
    //   const storyArchivePath = `authers/${user?.id}/storiesArchive/`;
    //   const allStoriesPath = `allStories/`;
    //   const commonId =await push(ref(db, storyArchivePath)).key;
    //   const newStoryRefPath =await `${storyArchivePath}${commonId}`;
    //   const newAllStoryRefPath =await `${allStoriesPath}${commonId}`;
    //   await set(ref(db, newStoryRefPath), formData);
    //   await set(ref(db, newAllStoryRefPath), formData);
      // onCancel();

      await navigate(`/synopsis/${storyID}/chapter/${commonChapterId}`)
      setloading(false)
    } catch (error) {
      console.log(error);
    }
    setloading(false)
  };
  return (
    <div
      className={`fixed top-0  left-0 right-0  z-10 flex justify-center select-none items-center  bg-black bg-opacity-90`}
      style={{ transform: `translateY-100%` }}
    >
      <div className="bg-white dark:bg-slate-800 lg:w-3/4 px-10 overscroll-y-auto py-14 h-screen overflow-y-auto">
      <div className='dark:text-white text-slate-800 '>
<div className="" dangerouslySetInnerHTML={{ __html: formData.content }}/>
            </div>
   
        <div className="flex gap-4 mt-4">
          <button
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            onClick={handleConfirmClick}
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
          <button
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
