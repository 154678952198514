import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ref, get, set, runTransaction } from "firebase/database";
import { db } from "../../firebase";
import { useAppSelector } from "../../hooks/storeHook";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
interface ChapterData {
  chapterId: string;
  chapterNo: number;
  title: string;
  timestamp?: number;
}

interface ChapterIndexProps {
  currentChapterId?: string;
  storyId: string;
  autherId: string;
}

const ChapterIndex: React.FC<ChapterIndexProps> = ({ currentChapterId = "", storyId, autherId }) => {
  const [chapters, setChapters] = useState<ChapterData[]>([]);
  const { user } = useAppSelector((state) => state.auth);
  const navigate= useNavigate()
  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const chaptersRef = ref(db, `allStories/${storyId}/chapters`);
        const snapshot = await get(chaptersRef);
        const chaptersData = snapshot.val();

        if (chaptersData) {
          const chaptersArray: ChapterData[] = Object.keys(chaptersData).map((chapterId) => ({
            chapterId,
            chapterNo: chaptersData[chapterId].chapterNo,
            title: chaptersData[chapterId].title,
            timestamp: chaptersData[chapterId].timestamp,
          }));
          setChapters(chaptersArray);
        }
      } catch (error) {
        console.error("Error fetching chapters:", error);
      }
    };

    fetchChapters();
  }, [storyId]);

  const handleChapterClick = async (chapterId: string) => {
    if (user) {
      try {
        const userBookmarksRef = ref(db, `users/${user.id}/bookmarks`);
        const snapshot = await get(userBookmarksRef);
        const existingBookmarks = snapshot.val() || {};

        const newBookmark = { [storyId]: chapterId };

        const updatedBookmarks = { ...existingBookmarks, ...newBookmark };

        await set(userBookmarksRef, updatedBookmarks);

        // Increment the total view count for the story
        const viewCountRef = ref(db, `allStories/${storyId}/viewCount`);
        const autherViewCountRef = ref(db, `authers/${autherId}/viewCount`);
        runTransaction(viewCountRef, (currentData) => {
          return (currentData || 0) + 1;
        });
        runTransaction(autherViewCountRef, (currentData) => {
          return (currentData || 0) + 1;
        });

        // alert("Bookmark added successfully!");
      } catch (error) {
        console.error("Error adding bookmark:", error);
      }
    } else {
      console.log("User not logged in. Cannot add bookmark.");
    }
  };

  // Function to convert epoch timestamp to a human-readable date
  const formatTimestamp = (timestamp: number | undefined) => {
    if (!timestamp) return "";
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleDateString(); // You can customize the date format here
  };

  return (
    <div className="p-6 bg-white dark:bg-slate-900 rounded-md select-none ">
    <div className="font-bold text-black dark:text-white font-1 text-xs md:text-2xl mb-4 ">Chapters</div>
    {currentChapterId ? (
      <div className="">
      <table className="  w-full ">
        <thead>
          <tr className="">
            <th className="text-xs lg:text-lg text-black dark:text-white">Chapter No.</th>
            <th className="text-xs lg:text-lg text-black dark:text-white">Title</th>
            <th className="text-xs lg:text-lg text-black dark:text-white">Published on</th>
          </tr>
        </thead>
        <tbody className="">
          {chapters.map((chapter) => (
            <tr
              key={chapter.chapterId}
              className={`text-black dark:text-gray-200 cursor-pointer border-b `}
              onClick={() => handleChapterClick(chapter.chapterId)}
            >
              <td className="lg:pl-12 text-center">{chapter.chapterNo}</td>
              <td className=" text-xs lg:text-xl font-bold font-2 py-12 text-center" onClick={() => navigate(`/story/${storyId}/chapter/${chapter.chapterId}`)}>
                {chapter.title}
              </td>
              <td  className="font-normal text-xs lg:text-xl font-2 py-12 text-center">{formatTimestamp(chapter.timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    )
  :
  (
    <div className="">
    <table className="  w-full ">
      <thead>
        <tr className="">
          <th className="text-xs lg:text-lg text-black dark:text-white">Chapter No.</th>
          <th className="text-xs lg:text-lg text-black dark:text-white">Title</th>
          <th className="text-xs lg:text-lg text-black dark:text-white">Published on</th>
        </tr>
      </thead>
      <tbody className="">
        {chapters.map((chapter) => (
          <tr
            key={chapter.chapterId}
            className={`text-black dark:text-gray-200 cursor-pointer border-b `}
          >
            <td className="lg:pl-12 text-center"> <Skeleton count={1} /></td>
            <td className=" text-xs lg:text-xl font-bold font-2 py-12 text-center" >
            <Skeleton count={1} />
            </td>
            <td  className="font-normal text-xs lg:text-xl font-2 py-12 text-center"> <Skeleton count={1} /></td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  )
  }
  </div>
  );
};

export default ChapterIndex;