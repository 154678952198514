import React from 'react'
import "./loading.css"
const Loading = () => {
  return (
    <div className='flex flex-col h-screen items-center w-full justify-center select-none'>
      <div className='absolute top-[30%] left-[50%]'>

      <div className="loader book ">
  <figure className="page"></figure>
  <figure className="page"></figure>
  <figure className="page"></figure>
</div>
      </div>
<div>

<h1 className='w-full text-blue-900 mt-64 style'>Reading</h1>
    </div>
</div>
  )
}

export default Loading
