import React, {  useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

const Dropdown: React.FC = () => {
  const navigate = useNavigate();
  const valuesArray = [
    "Real life experiences",
    "Fantasy",
    "Magical realism",
    "Mythology",
    "Short stories",
    "Poems",
    "Young adult",
    "Erotica",
    "Romance",
    "Mystery",
    "Fanfiction",
    "Everyday memoir",
    "Daily frustration",
    "Comedy",
    "Sci-fiction",
    "Literary fiction",
    "Adventure",
    "Essay",
    "Flash fiction",
    "Hear-me-out",
    "Thriller",
    "Exposé",
    "Confessions",
    "Self-help",
    "Tech",
    "Historical fiction",
    "Autobiography",
    "Psychology", 
    "Satire"
  ];
  
  const [selectedValue, setSelectedValue] = useState(""); // Track the selected value

  const handleDropdownChange = (value: string) => {
    setSelectedValue(value); // Update the selected value in state
    if (value !== "") {
      const newUrl = `/category/${value}`
    window.location.href = newUrl;
      // navigate(`/category/${encodeURIComponent(value)}`);
    }
  };

  return (
    <div className="select-none">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="text-lg  font-semibold leading-6 text-gray-100 flex"
          >
            { "Genre"} {/* Display the selected value or "Options" */}
            <ChevronDownIcon className="ml-2 h-5  mt-1 w-5 text-gray-400" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute -right-72  z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-slate-800 shadow-sm focus:outline-none">
            <div className="">
              {valuesArray.map((value, index) => (
                <Menu.Items className="absolute right-20  z-10 mt-2 w-auto origin-top-right rounded-md bg-white dark:bg-slate-800 text-white dark:text-gray-200 focus:outline-none">
                <div className="py-1 grid grid-cols-3 gap-4" style={{ minWidth: '768px' }}> {/* Inline style for minimum width */}
                  {valuesArray.map((value, index) => (
                    <Menu.Item key={value}> 
                      {({ active }) => (
                        <div
                          onClick={() => handleDropdownChange(value)}
                          className={classNames(
                            active ? 'bg-[#002D72]  text-gray-100 dark:text-gray-50' : 'text-gray-700 dark:text-gray-200',
                            'block px-4 py-2 text-sm cursor-pointer font-1' // Retain your styling here
                          )} 
                        >
                          {value}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default Dropdown;