import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../../firebase';
import  auther from "../../assets/author.png"
import { Reply } from '../../models/story/comment/Reply';

interface ReplyshowProps {
  storyID: string;
  commentID: string;
}

const Replyshow: React.FC<ReplyshowProps> = ({ storyID, commentID }) => {
  const [loading, setLoading] = useState(true);
  const [replies, setReplies] = useState<Reply[]>([]);

  useEffect(() => {
    // Create a reference to the replies node in the database for the specific storyID
    const repliesRef = ref(db, `allStories/${storyID}/comment/${commentID}/replies/`);

    // Use Firebase's onValue event listener to fetch replies whenever the data changes
    const unsubscribe = onValue(repliesRef, (snapshot) => {
      const repliesData = snapshot.val();

      // If there are replies, convert them to an array of objects and update the state
      if (repliesData) {
        const repliesArray: Reply[] = Object.keys(repliesData).map((commentId) => ({
          id: commentId,
          ...repliesData[commentId],
        }));
        setReplies(repliesArray);
      }

      setLoading(false);
    });

    // Unsubscribe from the event listener when the component unmounts
    return () => unsubscribe();
  }, [storyID, commentID]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='select-none'>
      {replies.length > 0 ? (
        <div className=''>
          
          <div>
            {replies.map((reply) => (
              <div className='' key={reply.id}>
              <div className='mt-2'>
              <div className='bg-black h-[0.5px] w-full mb-2'></div>
                <div className="flex items-center  mt-2">
                  

              <div className='text-md font-regular font-2 text-black dark:text-white opacity-50'>{reply.reply} </div> 
              <div className='rounded-full text-center -mt-2 -ml-4'>
                    {/* <img src={auther} alt=""  /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className='h-12' viewBox="0.00 0.00 2048.00 1024.00">
<g stroke-width="2.00" fill="none" stroke-linecap="butt">
<path stroke="#8096b9" vector-effect="non-scaling-stroke" d="
  M 789.92 481.89
  C 812.14 474.04 824.71 455.08 821.59 431.29
  C 818.51 407.78 800.36 395.66 778.33 392.75
  C 771.85 391.90 764.78 391.49 757.11 391.54
  C 743.10 391.62 729.68 392.75 716.87 394.93
  C 716.58 394.98 716.43 395.15 716.43 395.46
  L 716.43 563.57
  C 716.43 564.16 716.73 564.45 717.32 564.45
  L 738.01 564.51
  C 738.29 564.51 738.52 564.28 738.52 563.99
  L 738.53 490.49
  C 738.53 490.27 738.71 490.09 738.93 490.09
  C 745.18 490.06 751.80 490.07 758.79 490.12
  C 771.66 490.21 782.38 494.51 788.36 506.86
  C 790.35 510.97 791.73 514.72 792.51 518.11
  C 794.70 527.60 797.12 537.63 799.76 548.18
  C 801.15 553.69 802.85 558.93 804.86 563.88
  C 805.01 564.24 805.35 564.47 805.74 564.48
  L 827.46 564.48
  C 827.80 564.48 827.91 564.32 827.78 564.01
  C 825.35 557.89 823.39 551.80 821.88 545.74
  C 819.11 534.65 816.50 524.10 814.05 514.10
  C 810.60 500.09 803.19 487.48 789.92 482.73
  C 789.52 482.59 789.52 482.03 789.92 481.89"
/>
<path stroke="#8096b9" vector-effect="non-scaling-stroke" d="
  M 1239.85 481.91
  C 1258.20 475.02 1270.52 461.21 1271.75 441.59
  C 1273.63 411.36 1252.46 394.69 1224.11 392.29
  C 1205.57 390.72 1186.44 391.60 1166.72 394.93
  C 1166.42 394.98 1166.20 395.24 1166.20 395.54
  L 1166.20 563.96
  C 1166.20 564.31 1166.37 564.48 1166.71 564.48
  L 1187.79 564.47
  C 1188.14 564.47 1188.31 564.30 1188.31 563.96
  L 1188.31 490.54
  C 1188.31 490.24 1188.46 490.09 1188.76 490.09
  C 1195.65 490.06 1202.35 490.07 1208.86 490.12
  C 1229.24 490.28 1238.68 501.61 1242.91 520.52
  C 1245.08 530.21 1247.40 539.83 1249.86 549.38
  C 1251.21 554.63 1252.81 559.47 1254.67 563.92
  C 1254.83 564.29 1255.11 564.48 1255.51 564.48
  L 1277.22 564.48
  C 1277.56 564.48 1277.67 564.32 1277.55 564.01
  C 1274.65 556.48 1272.16 548.39 1270.09 539.74
  C 1268.14 531.66 1266.18 523.51 1264.20 515.29
  C 1260.74 500.91 1253.73 488.11 1239.85 482.75
  C 1239.12 482.46 1239.12 482.18 1239.85 481.91"
/>
<path stroke="#8096b9" vector-effect="non-scaling-stroke" d="
  M 635.24 537.71
  C 633.27 524.67 630.77 511.62 627.74 498.57
  C 621.23 470.48 612.86 435.39 602.62 393.29
  C 602.54 392.99 602.28 392.78 601.98 392.78
  L 579.74 392.78
  C 579.53 392.78 579.34 392.92 579.29 393.13
  C 570.14 427.63 561.06 461.96 552.05 496.12
  C 548.15 510.89 544.98 524.85 542.54 538.00
  C 542.28 539.39 542.02 539.39 541.76 538.01
  C 538.82 522.54 535.55 507.06 531.94 491.57
  C 524.35 458.94 516.66 426.12 508.86 393.10
  C 508.81 392.89 508.68 392.78 508.46 392.78
  L 485.81 392.78
  C 485.59 392.78 485.43 392.99 485.49 393.20
  L 528.87 564.18
  C 528.92 564.38 529.05 564.48 529.26 564.48
  L 551.76 564.48
  C 552.00 564.48 552.15 564.36 552.21 564.13
  C 561.18 531.21 570.25 498.11 579.40 464.82
  C 583.63 449.45 587.03 434.57 589.62 420.20
  C 589.92 418.52 590.19 418.52 590.44 420.21
  C 592.35 433.57 594.73 446.24 597.56 458.21
  C 605.43 491.46 613.96 526.79 623.15 564.20
  C 623.20 564.39 623.31 564.48 623.50 564.48
  L 645.80 564.48
  C 646.16 564.48 646.39 564.30 646.49 563.95
  L 694.86 393.26
  C 694.93 393.02 694.75 392.78 694.50 392.78
  L 672.73 392.78
  C 672.50 392.78 672.30 392.94 672.24 393.16
  C 665.13 420.31 657.73 448.58 650.05 477.96
  C 644.75 498.23 640.08 518.15 636.03 537.74
  C 635.72 539.20 635.46 539.19 635.24 537.71"
/>
<path stroke="#8096b9" vector-effect="non-scaling-stroke" d="
  M 875.54 393.24
  C 875.54 392.98 875.33 392.77 875.07 392.77
  L 853.93 392.77
  C 853.67 392.77 853.46 392.98 853.46 393.24
  L 853.46 564.00
  C 853.46 564.26 853.67 564.47 853.93 564.47
  L 875.07 564.47
  C 875.33 564.47 875.54 564.26 875.54 564.00
  L 875.54 393.24"
/>
<path stroke="#8096b9" vector-effect="non-scaling-stroke" d="
  M 969.28 412.01
  C 969.28 411.82 969.43 411.67 969.62 411.67
  L 1021.41 411.67
  C 1021.60 411.67 1021.75 411.52 1021.75 411.33
  L 1021.75 393.12
  C 1021.75 392.93 1021.60 392.78 1021.41 392.78
  L 895.04 392.78
  C 894.85 392.78 894.70 392.93 894.70 393.12
  L 894.70 411.33
  C 894.70 411.52 894.85 411.67 895.04 411.67
  L 946.55 411.67
  C 946.74 411.67 946.89 411.82 946.89 412.01
  L 946.89 564.14
  C 946.89 564.33 947.04 564.48 947.23 564.48
  L 968.94 564.48
  C 969.13 564.48 969.28 564.33 969.28 564.14
  L 969.28 412.01"
/>
<path stroke="#8096b9" vector-effect="non-scaling-stroke" d="
  M 1063.00 484.35
  C 1063.00 484.15 1063.16 483.99 1063.36 483.99
  L 1129.39 483.99
  C 1129.59 483.99 1129.75 483.83 1129.75 483.63
  L 1129.75 466.07
  C 1129.75 465.87 1129.59 465.71 1129.39 465.71
  L 1063.36 465.71
  C 1063.16 465.71 1063.00 465.55 1063.00 465.35
  L 1063.00 411.77
  C 1063.00 411.57 1063.16 411.41 1063.36 411.41
  L 1133.19 411.40
  C 1133.39 411.40 1133.55 411.24 1133.55 411.04
  L 1133.55 393.14
  C 1133.55 392.94 1133.39 392.78 1133.19 392.78
  L 1041.23 392.78
  C 1041.03 392.78 1040.87 392.94 1040.87 393.14
  L 1040.87 564.12
  C 1040.87 564.32 1041.03 564.48 1041.23 564.48
  L 1137.03 564.48
  C 1137.23 564.48 1137.39 564.32 1137.39 564.12
  L 1137.39 546.21
  C 1137.39 546.01 1137.23 545.85 1137.03 545.85
  L 1063.36 545.85
  C 1063.16 545.85 1063.00 545.69 1063.00 545.49
  L 1063.00 484.35"
/>
<path stroke="#8096b9" vector-effect="non-scaling-stroke" d="
  M 786.35 466.63
  C 797.76 459.18 801.78 446.23 799.17 433.01
  C 793.83 406.04 759.60 406.62 738.81 410.47
  C 738.65 410.50 738.53 410.64 738.53 410.81
  L 738.53 472.97
  C 738.53 473.16 738.68 473.31 738.86 473.30
  C 745.44 473.21 752.05 473.22 758.70 473.32
  C 768.70 473.47 778.16 471.98 786.35 466.63"
/>
<path stroke="#8096b9" vector-effect="non-scaling-stroke" d="
  M 1215.84 473.04
  C 1236.48 471.89 1251.95 457.84 1249.46 436.12
  C 1246.05 406.33 1210.96 406.42 1188.85 410.40
  C 1188.54 410.46 1188.31 410.73 1188.31 411.05
  L 1188.31 472.70
  C 1188.31 473.03 1188.47 473.19 1188.80 473.20
  C 1201.73 473.38 1210.75 473.33 1215.84 473.04"
/>
</g>
<path fill="#3871c1" d="
  M 1481.45 361.89
  L 1481.17 361.79
  C 1481.03 361.74 1480.93 361.79 1480.88 361.93
  C 1438.56 474.59 1376.90 578.66 1300.85 672.69
  C 1300.66 672.91 1300.45 672.94 1300.20 672.79
  C 1271.88 654.92 1296.75 602.32 1309.15 581.29
  C 1316.61 568.64 1324.52 556.10 1332.87 543.68
  C 1345.58 524.79 1358.32 506.02 1371.09 487.39
  C 1379.26 475.46 1386.92 463.15 1394.06 450.45
  C 1394.49 449.69 1394.63 449.74 1394.50 450.60
  C 1391.57 469.81 1387.52 488.50 1382.48 507.08
  C 1382.19 508.15 1382.36 508.23 1383.00 507.33
  C 1400.55 482.46 1409.18 454.67 1408.90 423.94
  C 1408.89 422.47 1409.19 421.07 1409.82 419.72
  C 1438.74 358.24 1454.19 291.44 1467.46 225.01
  C 1470.18 211.38 1473.06 197.20 1476.11 182.46
  C 1476.22 181.93 1476.42 181.90 1476.71 182.35
  C 1481.66 190.18 1486.71 198.92 1491.84 208.57
  C 1509.13 241.05 1522.01 274.18 1530.49 307.96
  C 1536.22 330.81 1540.60 353.00 1543.63 374.53
  C 1543.71 375.05 1543.63 375.58 1543.40 376.05
  C 1527.79 408.51 1506.87 439.07 1484.59 466.81
  C 1484.06 467.47 1484.14 467.57 1484.85 467.10
  C 1511.46 449.51 1531.56 426.05 1545.13 396.74
  C 1545.22 396.55 1545.50 396.60 1545.51 396.81
  C 1546.85 424.80 1543.65 452.39 1535.90 479.56
  C 1535.19 482.04 1534.21 484.26 1532.94 486.22
  C 1524.17 499.70 1513.73 513.24 1501.62 526.85
  C 1485.65 544.78 1469.15 561.36 1452.11 576.57
  C 1450.88 577.67 1451.01 577.86 1452.50 577.15
  C 1480.60 563.64 1503.61 544.65 1521.54 520.17
  C 1522.07 519.45 1522.15 519.50 1521.79 520.32
  C 1495.01 581.32 1451.88 625.62 1392.39 653.23
  C 1374.58 661.50 1356.14 668.54 1337.29 673.54
  C 1327.49 676.14 1318.68 677.25 1310.86 676.88
  C 1310.52 676.87 1310.22 676.97 1309.96 677.19
  C 1306.11 680.44 1302.59 683.63 1299.42 686.76
  C 1272.03 713.71 1245.58 743.06 1220.53 771.38
  C 1220.48 771.43 1220.42 771.44 1220.37 771.40
  L 1220.36 771.39
  C 1220.29 771.34 1220.28 771.28 1220.33 771.21
  C 1242.20 738.02 1269.04 707.14 1298.33 679.73
  C 1302.28 676.04 1305.75 672.38 1308.74 668.77
  C 1376.36 586.95 1431.42 495.03 1469.34 396.93
  C 1473.30 386.68 1477.35 375.03 1481.49 361.97
  C 1481.50 361.93 1481.49 361.90 1481.45 361.89
  Z"
/>
<path fill="#3871c1" d="
  M 1533.26 522.68
  C 1552.98 485.09 1562.71 445.70 1562.44 404.51
  C 1562.29 382.79 1559.41 360.63 1555.22 339.49
  C 1553.93 332.96 1560.63 332.68 1565.32 332.70
  C 1577.59 332.75 1590.26 332.77 1603.31 332.76
  C 1615.31 332.74 1625.57 340.39 1629.51 351.63
  C 1630.50 354.46 1630.99 359.96 1630.97 368.14
  C 1630.80 444.29 1630.81 517.42 1630.98 587.53
  C 1630.99 594.50 1630.58 599.41 1629.73 602.28
  C 1626.99 611.51 1619.36 618.88 1609.94 621.16
  C 1607.55 621.74 1604.16 622.03 1599.77 622.02
  C 1552.24 621.96 1506.17 621.95 1461.56 621.98
  C 1461.04 621.98 1460.95 621.79 1461.30 621.41
  C 1488.94 591.05 1514.15 559.12 1533.26 522.68
  Z"
/>
<path fill="#002d72" d="
  M 1427.60 333.86
  C 1408.80 398.59 1378.16 460.82 1339.83 515.74
  C 1329.90 529.97 1320.09 543.96 1310.40 557.73
  C 1296.59 577.32 1283.80 599.15 1276.87 622.15
  C 1276.79 622.42 1276.54 622.61 1276.25 622.61
  C 1011.80 622.62 746.92 622.63 481.61 622.62
  C 470.50 622.62 459.40 622.59 448.29 622.54
  C 442.72 622.51 438.67 622.10 436.14 621.29
  C 423.89 617.42 416.92 606.24 416.95 593.58
  C 417.09 518.68 417.11 443.12 417.00 366.90
  C 416.99 359.79 417.44 354.94 418.35 352.36
  C 422.34 341.06 432.47 333.41 444.47 333.41
  C 772.02 333.40 1099.61 333.40 1427.26 333.41
  C 1427.50 333.41 1427.67 333.63 1427.60 333.86
  Z
  M 789.92 481.89
  C 812.14 474.04 824.71 455.08 821.59 431.29
  C 818.51 407.78 800.36 395.66 778.33 392.75
  C 771.85 391.90 764.78 391.49 757.11 391.54
  C 743.10 391.62 729.68 392.75 716.87 394.93
  C 716.58 394.98 716.43 395.15 716.43 395.46
  L 716.43 563.57
  C 716.43 564.16 716.73 564.45 717.32 564.45
  L 738.01 564.51
  C 738.29 564.51 738.52 564.28 738.52 563.99
  L 738.53 490.49
  C 738.53 490.27 738.71 490.09 738.93 490.09
  C 745.18 490.06 751.80 490.07 758.79 490.12
  C 771.66 490.21 782.38 494.51 788.36 506.86
  C 790.35 510.97 791.73 514.72 792.51 518.11
  C 794.70 527.60 797.12 537.63 799.76 548.18
  C 801.15 553.69 802.85 558.93 804.86 563.88
  C 805.01 564.24 805.35 564.47 805.74 564.48
  L 827.46 564.48
  C 827.80 564.48 827.91 564.32 827.78 564.01
  C 825.35 557.89 823.39 551.80 821.88 545.74
  C 819.11 534.65 816.50 524.10 814.05 514.10
  C 810.60 500.09 803.19 487.48 789.92 482.73
  C 789.52 482.59 789.52 482.03 789.92 481.89
  Z
  M 1239.85 481.91
  C 1258.20 475.02 1270.52 461.21 1271.75 441.59
  C 1273.63 411.36 1252.46 394.69 1224.11 392.29
  C 1205.57 390.72 1186.44 391.60 1166.72 394.93
  C 1166.42 394.98 1166.20 395.24 1166.20 395.54
  L 1166.20 563.96
  C 1166.20 564.31 1166.37 564.48 1166.71 564.48
  L 1187.79 564.47
  C 1188.14 564.47 1188.31 564.30 1188.31 563.96
  L 1188.31 490.54
  C 1188.31 490.24 1188.46 490.09 1188.76 490.09
  C 1195.65 490.06 1202.35 490.07 1208.86 490.12
  C 1229.24 490.28 1238.68 501.61 1242.91 520.52
  C 1245.08 530.21 1247.40 539.83 1249.86 549.38
  C 1251.21 554.63 1252.81 559.47 1254.67 563.92
  C 1254.83 564.29 1255.11 564.48 1255.51 564.48
  L 1277.22 564.48
  C 1277.56 564.48 1277.67 564.32 1277.55 564.01
  C 1274.65 556.48 1272.16 548.39 1270.09 539.74
  C 1268.14 531.66 1266.18 523.51 1264.20 515.29
  C 1260.74 500.91 1253.73 488.11 1239.85 482.75
  C 1239.12 482.46 1239.12 482.18 1239.85 481.91
  Z
  M 635.24 537.71
  C 633.27 524.67 630.77 511.62 627.74 498.57
  C 621.23 470.48 612.86 435.39 602.62 393.29
  C 602.54 392.99 602.28 392.78 601.98 392.78
  L 579.74 392.78
  C 579.53 392.78 579.34 392.92 579.29 393.13
  C 570.14 427.63 561.06 461.96 552.05 496.12
  C 548.15 510.89 544.98 524.85 542.54 538.00
  C 542.28 539.39 542.02 539.39 541.76 538.01
  C 538.82 522.54 535.55 507.06 531.94 491.57
  C 524.35 458.94 516.66 426.12 508.86 393.10
  C 508.81 392.89 508.68 392.78 508.46 392.78
  L 485.81 392.78
  C 485.59 392.78 485.43 392.99 485.49 393.20
  L 528.87 564.18
  C 528.92 564.38 529.05 564.48 529.26 564.48
  L 551.76 564.48
  C 552.00 564.48 552.15 564.36 552.21 564.13
  C 561.18 531.21 570.25 498.11 579.40 464.82
  C 583.63 449.45 587.03 434.57 589.62 420.20
  C 589.92 418.52 590.19 418.52 590.44 420.21
  C 592.35 433.57 594.73 446.24 597.56 458.21
  C 605.43 491.46 613.96 526.79 623.15 564.20
  C 623.20 564.39 623.31 564.48 623.50 564.48
  L 645.80 564.48
  C 646.16 564.48 646.39 564.30 646.49 563.95
  L 694.86 393.26
  C 694.93 393.02 694.75 392.78 694.50 392.78
  L 672.73 392.78
  C 672.50 392.78 672.30 392.94 672.24 393.16
  C 665.13 420.31 657.73 448.58 650.05 477.96
  C 644.75 498.23 640.08 518.15 636.03 537.74
  C 635.72 539.20 635.46 539.19 635.24 537.71
  Z
  M 875.54 393.24
  C 875.54 392.98 875.33 392.77 875.07 392.77
  L 853.93 392.77
  C 853.67 392.77 853.46 392.98 853.46 393.24
  L 853.46 564.00
  C 853.46 564.26 853.67 564.47 853.93 564.47
  L 875.07 564.47
  C 875.33 564.47 875.54 564.26 875.54 564.00
  L 875.54 393.24
  Z
  M 969.28 412.01
  C 969.28 411.82 969.43 411.67 969.62 411.67
  L 1021.41 411.67
  C 1021.60 411.67 1021.75 411.52 1021.75 411.33
  L 1021.75 393.12
  C 1021.75 392.93 1021.60 392.78 1021.41 392.78
  L 895.04 392.78
  C 894.85 392.78 894.70 392.93 894.70 393.12
  L 894.70 411.33
  C 894.70 411.52 894.85 411.67 895.04 411.67
  L 946.55 411.67
  C 946.74 411.67 946.89 411.82 946.89 412.01
  L 946.89 564.14
  C 946.89 564.33 947.04 564.48 947.23 564.48
  L 968.94 564.48
  C 969.13 564.48 969.28 564.33 969.28 564.14
  L 969.28 412.01
  Z
  M 1063.00 484.35
  C 1063.00 484.15 1063.16 483.99 1063.36 483.99
  L 1129.39 483.99
  C 1129.59 483.99 1129.75 483.83 1129.75 483.63
  L 1129.75 466.07
  C 1129.75 465.87 1129.59 465.71 1129.39 465.71
  L 1063.36 465.71
  C 1063.16 465.71 1063.00 465.55 1063.00 465.35
  L 1063.00 411.77
  C 1063.00 411.57 1063.16 411.41 1063.36 411.41
  L 1133.19 411.40
  C 1133.39 411.40 1133.55 411.24 1133.55 411.04
  L 1133.55 393.14
  C 1133.55 392.94 1133.39 392.78 1133.19 392.78
  L 1041.23 392.78
  C 1041.03 392.78 1040.87 392.94 1040.87 393.14
  L 1040.87 564.12
  C 1040.87 564.32 1041.03 564.48 1041.23 564.48
  L 1137.03 564.48
  C 1137.23 564.48 1137.39 564.32 1137.39 564.12
  L 1137.39 546.21
  C 1137.39 546.01 1137.23 545.85 1137.03 545.85
  L 1063.36 545.85
  C 1063.16 545.85 1063.00 545.69 1063.00 545.49
  L 1063.00 484.35
  Z"
/>
<path fill="#ffffff" d="
  M 789.92 481.89
  C 789.52 482.03 789.52 482.59 789.92 482.73
  C 803.19 487.48 810.60 500.09 814.05 514.10
  C 816.50 524.10 819.11 534.65 821.88 545.74
  C 823.39 551.80 825.35 557.89 827.78 564.01
  C 827.91 564.32 827.80 564.48 827.46 564.48
  L 805.74 564.48
  C 805.35 564.47 805.01 564.24 804.86 563.88
  C 802.85 558.93 801.15 553.69 799.76 548.18
  C 797.12 537.63 794.70 527.60 792.51 518.11
  C 791.73 514.72 790.35 510.97 788.36 506.86
  C 782.38 494.51 771.66 490.21 758.79 490.12
  C 751.80 490.07 745.18 490.06 738.93 490.09
  C 738.71 490.09 738.53 490.27 738.53 490.49
  L 738.52 563.99
  C 738.52 564.28 738.29 564.51 738.01 564.51
  L 717.32 564.45
  C 716.73 564.45 716.43 564.16 716.43 563.57
  L 716.43 395.46
  C 716.43 395.15 716.58 394.98 716.87 394.93
  C 729.68 392.75 743.10 391.62 757.11 391.54
  C 764.78 391.49 771.85 391.90 778.33 392.75
  C 800.36 395.66 818.51 407.78 821.59 431.29
  C 824.71 455.08 812.14 474.04 789.92 481.89
  Z
  M 786.35 466.63
  C 797.76 459.18 801.78 446.23 799.17 433.01
  C 793.83 406.04 759.60 406.62 738.81 410.47
  C 738.65 410.50 738.53 410.64 738.53 410.81
  L 738.53 472.97
  C 738.53 473.16 738.68 473.31 738.86 473.30
  C 745.44 473.21 752.05 473.22 758.70 473.32
  C 768.70 473.47 778.16 471.98 786.35 466.63
  Z"
/>
<path fill="#ffffff" d="
  M 1239.85 481.91
  C 1239.12 482.18 1239.12 482.46 1239.85 482.75
  C 1253.73 488.11 1260.74 500.91 1264.20 515.29
  C 1266.18 523.51 1268.14 531.66 1270.09 539.74
  C 1272.16 548.39 1274.65 556.48 1277.55 564.01
  C 1277.67 564.32 1277.56 564.48 1277.22 564.48
  L 1255.51 564.48
  C 1255.11 564.48 1254.83 564.29 1254.67 563.92
  C 1252.81 559.47 1251.21 554.63 1249.86 549.38
  C 1247.40 539.83 1245.08 530.21 1242.91 520.52
  C 1238.68 501.61 1229.24 490.28 1208.86 490.12
  C 1202.35 490.07 1195.65 490.06 1188.76 490.09
  C 1188.46 490.09 1188.31 490.24 1188.31 490.54
  L 1188.31 563.96
  C 1188.31 564.30 1188.14 564.47 1187.79 564.47
  L 1166.71 564.48
  C 1166.37 564.48 1166.20 564.31 1166.20 563.96
  L 1166.20 395.54
  C 1166.20 395.24 1166.42 394.98 1166.72 394.93
  C 1186.44 391.60 1205.57 390.72 1224.11 392.29
  C 1252.46 394.69 1273.63 411.36 1271.75 441.59
  C 1270.52 461.21 1258.20 475.02 1239.85 481.91
  Z
  M 1215.84 473.04
  C 1236.48 471.89 1251.95 457.84 1249.46 436.12
  C 1246.05 406.33 1210.96 406.42 1188.85 410.40
  C 1188.54 410.46 1188.31 410.73 1188.31 411.05
  L 1188.31 472.70
  C 1188.31 473.03 1188.47 473.19 1188.80 473.20
  C 1201.73 473.38 1210.75 473.33 1215.84 473.04
  Z"
/>
<path fill="#ffffff" d="
  M 635.24 537.71
  C 635.46 539.19 635.72 539.20 636.03 537.74
  C 640.08 518.15 644.75 498.23 650.05 477.96
  C 657.73 448.58 665.13 420.31 672.24 393.16
  C 672.30 392.94 672.50 392.78 672.73 392.78
  L 694.50 392.78
  C 694.75 392.78 694.93 393.02 694.86 393.26
  L 646.49 563.95
  C 646.39 564.30 646.16 564.48 645.80 564.48
  L 623.50 564.48
  C 623.31 564.48 623.20 564.39 623.15 564.20
  C 613.96 526.79 605.43 491.46 597.56 458.21
  C 594.73 446.24 592.35 433.57 590.44 420.21
  C 590.19 418.52 589.92 418.52 589.62 420.20
  C 587.03 434.57 583.63 449.45 579.40 464.82
  C 570.25 498.11 561.18 531.21 552.21 564.13
  C 552.15 564.36 552.00 564.48 551.76 564.48
  L 529.26 564.48
  C 529.05 564.48 528.92 564.38 528.87 564.18
  L 485.49 393.20
  C 485.43 392.99 485.59 392.78 485.81 392.78
  L 508.46 392.78
  C 508.68 392.78 508.81 392.89 508.86 393.10
  C 516.66 426.12 524.35 458.94 531.94 491.57
  C 535.55 507.06 538.82 522.54 541.76 538.01
  C 542.02 539.39 542.28 539.39 542.54 538.00
  C 544.98 524.85 548.15 510.89 552.05 496.12
  C 561.06 461.96 570.14 427.63 579.29 393.13
  C 579.34 392.92 579.53 392.78 579.74 392.78
  L 601.98 392.78
  C 602.28 392.78 602.54 392.99 602.62 393.29
  C 612.86 435.39 621.23 470.48 627.74 498.57
  C 630.77 511.62 633.27 524.67 635.24 537.71
  Z"
/>
<rect fill="#ffffff" x="853.46" y="392.77" width="22.08" height="171.70" rx="0.47"/>
<path fill="#ffffff" d="
  M 969.28 412.01
  L 969.28 564.14
  C 969.28 564.33 969.13 564.48 968.94 564.48
  L 947.23 564.48
  C 947.04 564.48 946.89 564.33 946.89 564.14
  L 946.89 412.01
  C 946.89 411.82 946.74 411.67 946.55 411.67
  L 895.04 411.67
  C 894.85 411.67 894.70 411.52 894.70 411.33
  L 894.70 393.12
  C 894.70 392.93 894.85 392.78 895.04 392.78
  L 1021.41 392.78
  C 1021.60 392.78 1021.75 392.93 1021.75 393.12
  L 1021.75 411.33
  C 1021.75 411.52 1021.60 411.67 1021.41 411.67
  L 969.62 411.67
  C 969.43 411.67 969.28 411.82 969.28 412.01
  Z"
/>
<path fill="#ffffff" d="
  M 1063.00 484.35
  L 1063.00 545.49
  C 1063.00 545.69 1063.16 545.85 1063.36 545.85
  L 1137.03 545.85
  C 1137.23 545.85 1137.39 546.01 1137.39 546.21
  L 1137.39 564.12
  C 1137.39 564.32 1137.23 564.48 1137.03 564.48
  L 1041.23 564.48
  C 1041.03 564.48 1040.87 564.32 1040.87 564.12
  L 1040.87 393.14
  C 1040.87 392.94 1041.03 392.78 1041.23 392.78
  L 1133.19 392.78
  C 1133.39 392.78 1133.55 392.94 1133.55 393.14
  L 1133.55 411.04
  C 1133.55 411.24 1133.39 411.40 1133.19 411.40
  L 1063.36 411.41
  C 1063.16 411.41 1063.00 411.57 1063.00 411.77
  L 1063.00 465.35
  C 1063.00 465.55 1063.16 465.71 1063.36 465.71
  L 1129.39 465.71
  C 1129.59 465.71 1129.75 465.87 1129.75 466.07
  L 1129.75 483.63
  C 1129.75 483.83 1129.59 483.99 1129.39 483.99
  L 1063.36 483.99
  C 1063.16 483.99 1063.00 484.15 1063.00 484.35
  Z"
/>
<path fill="#002d72" d="
  M 799.17 433.01
  C 801.78 446.23 797.76 459.18 786.35 466.63
  C 778.16 471.98 768.70 473.47 758.70 473.32
  C 752.05 473.22 745.44 473.21 738.86 473.30
  C 738.68 473.31 738.53 473.16 738.53 472.97
  L 738.53 410.81
  C 738.53 410.64 738.65 410.50 738.81 410.47
  C 759.60 406.62 793.83 406.04 799.17 433.01
  Z"
/>
<path fill="#002d72" d="
  M 1249.46 436.12
  C 1251.95 457.84 1236.48 471.89 1215.84 473.04
  C 1210.75 473.33 1201.73 473.38 1188.80 473.20
  C 1188.47 473.19 1188.31 473.03 1188.31 472.70
  L 1188.31 411.05
  C 1188.31 410.73 1188.54 410.46 1188.85 410.40
  C 1210.96 406.42 1246.05 406.33 1249.46 436.12
  Z"
/>
</svg>
                  </div>
            

                </div>

             
              
              </div>
             

            </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Replyshow;