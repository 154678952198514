import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../hooks/storeHook';
import { ref, set, push, query, orderByChild, equalTo, get } from 'firebase/database';
import { db } from '../../firebase';

interface AutherReplyProps {
  storyID: string;
  autherID: string;
  commentID: string;
}

const AutherReply: React.FC<AutherReplyProps> = ({ storyID, autherID, commentID }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [replyStatus, setReplyStatus] = useState(false);
  const [replyData, setReplyData] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { user } = useAppSelector((state) => state.auth);

  // Check if the user has already posted a reply and set the replyStatus state accordingly
  const checkReplyStatus = async () => {
    if (user && user.email) {
      const replyRef = `allStories/${storyID}/comment/${commentID}/replies/`;
      const replyQuery = query(ref(db, replyRef), orderByChild('email'), equalTo(user.email));
      const snapshot = await get(replyQuery);
      const repliesData: Record<string, { reply: string; email: string }> = snapshot.val();

      if (repliesData) {
        setReplyStatus(true);
        setReplyData(repliesData[Object.keys(repliesData)[0]].reply); // Get the first reply for the user
      } else {
        setReplyStatus(false);
      }
    }
  };

  // Call the checkReplyStatus function on component mount
  useEffect(() => {
    checkReplyStatus();
  }, [user, storyID, commentID]);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (user && user.email) {
        const data = {
          email: user.email,
          reply: e.currentTarget.comment.value,
        };

        const replyRef = `allStories/${storyID}/comment/${commentID}/replies/`;
        const commonId = push(ref(db, replyRef)).key;

        const newReplyPathRef = `${replyRef}${commonId}`;

        await set(ref(db, newReplyPathRef), data);

        // alert('Success');
        setReplyStatus(true); // Set the replyStatus to true as the user has now posted a reply
        setReplyData(data.reply); // Update the replyData state with the user's new reply
        setLoading(false);
        setSuccess(true);
      }
    } catch (error: any) {
      setLoading(false);
      const errorCode = error.code;
      setErrorMessage(errorCode);
    }
  };

  return (
    <div className='select-none'>
      {autherID === user?.id && !replyStatus ? (
        <form className='w-full flex flex-col mt-4' onSubmit={handleFormSubmit}>
         <div className="w-full flex gap-4">
          <div className='rounded-full text-center h-8 w-8 text-xl  bg-blue-900 text-white'>
{user.userData?.name.slice(0,1).toUpperCase()}
          </div>
<div className='w-full'>

<textarea name="comment" className='w-full h-36 text-gray-900 resize-none  p-3 rounded-lg border' placeholder="Leave Your Thoughts" required />
</div>
</div>
<div className='ml-auto'>

<button className={"bg-blue-900  text-white rounded-full py-1.5 px-4 text-lg hover:bg-blue-700 cursor-pointer mt-4 " } type="submit">Submit</button>
</div>
        </form>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AutherReply;