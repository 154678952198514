import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail, sendEmailVerification, EmailAuthProvider } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { authClasses } from "./authClasses";
import { AuthSignUpForm, AuthSignInForm, authSignUpFormSchema, authSignInFormSchema } from "../../models/user/Form";
import { auth, db } from "../../firebase";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHook";
import { login } from "../../features/authSlice";
import ResetPassword from "../../components/ResetPassword/ResetPassword";
import { set, ref, update, get } from "firebase/database";
import { User } from "../../models/user/User";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from "../../assets/Logo.svg"

import bg from "../../assets/bg/authBg.jpg"
const Auth = () => {
  const [authType, setAuthType] = useState<"login" | "sign-up">("login");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [resetPassword, setResetPassword] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState<string | null>(null);
  const [resetPasswordError, setResetPasswordError] = useState<string | null>(null);
  
  const { user } = useAppSelector((state) => state.auth);
  const userInfo = user?.userData;
  const userBookshelf = user?.bookshelf
  const userSuspension = user?.suspension
  const userAuther = user?.isAuther || false;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showSignUpWelcome, setShowSignUpWelcome] = useState(true);

  useEffect(() => {
    if (Boolean(user)) {
      navigate("/");
    }
  }, [user, navigate]);
  const closeSignUpWelcome = () => {
    setShowSignUpWelcome(false);
  };
  const {
    container,
    form,
    button,
    input,
    text,
    link,
    hr,
    forgotPasswordButton,
  } = authClasses;

  const handlePasswordReset = async () => {
    if (!resetPasswordEmail.length) return;
    try {
      await sendPasswordResetEmail(auth, resetPasswordEmail);
      setResetPasswordSuccess("Password reset email sent. Please check your inbox.");
      setResetPasswordError(null);
    } catch (error: any) {
      setResetPasswordError(error.message);
      setResetPasswordSuccess(null);
    }
  };

    const getUserByEmail = async (email: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, "dummyPasswordForLinking");
      return userCredential.user;
    } catch (error) {
      // User doesn't exist with the given email and dummy password
      return null;
    }
  };


  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const { user } = await signInWithPopup(auth, provider);
      if (user && user.email) {
        const userData: User = {
          email: user.email,
          id: user.uid,
          photoUrl: user.photoURL || null,
          isAuther: userAuther || false,
          userData: userInfo || null,
          bookshelf: userBookshelf || null,
          suspension:userSuspension || null
        };
        const userRef = ref(db, `users/${user.uid}`);

        const userSnapshot = await get(userRef);
        if (userSnapshot.exists()) {
          await update(userRef, { photoUrl: userData.photoUrl });
        } else {
          await set(userRef, userData);
        }

        dispatch(login(userData));
      }
    } catch (error:any) {
      toast.error("Error signing in:", error);
    }
  };
  

  const [validationStatus, setValidationStatus] = useState({
    lowercase: false,
    uppercase: false,
    hasDigit: false,
    hasSpecialCharacter: false,
    length: false,
});
  const handleSignUp = async (data: AuthSignUpForm) => {
    setErrorMessage(null);
    setLoading(true);
    
    const { email, password } = data;
    const existingUser = await getUserByEmail(email);


    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const digitRegex = /\d/;
    const specialCharacterRegex = /[@#$%^&*!]/;
    const isLengthValid = data.password.length >= 8;
    if (existingUser) {
     toast.error("Email already in use")
     return // User exists, link the email/password credentials with the existing account
    }

    setValidationStatus({
        lowercase: lowercaseRegex.test(data.password),
        uppercase: uppercaseRegex.test(data.password),
        hasDigit: digitRegex.test(data.password),
        hasSpecialCharacter: specialCharacterRegex.test(data.password),
        length: isLengthValid,
    });
     setValidationStatus(validationStatus);

    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(user);
  
 
  
      await set(ref(db, "users/" + user.uid), {
        email,
        isAuther: true,
        id: user.uid,
        photoUrl: user.photoURL || null,
        userData: userInfo || null,
      });
  
     
  
      if (user && user.email) {
        dispatch(
          login({
            email: user.email,
            isAuther: userAuther || false,
            id: user.uid,
            photoUrl: user.photoURL || null,
            userData: userInfo || null,
            bookshelf: userBookshelf || null,
            suspension:userSuspension || null
          })
        );
        if (!user.emailVerified) {
          navigate(`/verification-pending/${user.uid}`); // Navigate to verification pending page
          return;
        }else{
          
          navigate("/");
        }
    
        setLoading(false);
     
       
      }
    } catch (error: any) {
      setLoading(false);
      const errorCode = error.code;
      toast.error(errorCode);
      if (errorCode === 'auth/weak-password') {
        toast.error('Password is too weak. Please meet the password requirements.');
      }
      setLoading(false);
    }
    setLoading(false);
  };


  const [isOKLarge, setIsOKLarge] = useState(true);

  const handleOKClick = () => {
    setShowSignUpWelcome(false)
    console.log("OK clicked");
  };

  const handleCancelClick = () => {
    setAuthType("login")
    console.log("Cancel clicked");
  };

  


  useEffect(() => {
    const interval = setInterval(() => {
      setIsOKLarge((prev) => !prev);
    }, 500); // Toggle button size every 2000 milliseconds (2 seconds)

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);
  
  const handleSignIn = async (data: AuthSignInForm) => {
    setErrorMessage(null);
    setLoading(true);
    const { email, password } = data;
  
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
  
      setLoading(false);
      if (user && user.email) {
        const userData: User = {
          email: user.email,
          id: user.uid,
          photoUrl: user.photoURL || null,
          isAuther: userAuther || false,
          userData: userInfo || null,
          bookshelf: userBookshelf || null,
          suspension: userSuspension || null
        };
        const userRef = ref(db, `users/${user.uid}`);
  
        const userSnapshot = await get(userRef);
        if (userSnapshot.exists()) {
          await update(userRef, { photoUrl: userData.photoUrl });
        } else {
          await set(userRef, userData);
        }
  
        dispatch(login(userData));
  
        // Check if the email is verified
        if (!user.emailVerified) {
          navigate(`/verification-pending/${user.uid}`); // Navigate to verification pending page
          return;
        }else{
          
          navigate("/");
        }
  
        // Navigate to the main page if verification is not pending
       
      }
    } catch (error: any) {
      setLoading(false);
      const errorCode = error.code;
      toast.error(errorCode);
    }
  }
  
  const handleFormSubmit = async (data: any) => {
    if (authType === "sign-up") {
      await handleSignUp(data);
    } else {
      await handleSignIn(data);
    }
  };
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<AuthSignUpForm | AuthSignInForm>({
    resolver: yupResolver(
      authType === "sign-up" ? authSignUpFormSchema : authSignInFormSchema
      ),
      
    });
    
    const handleAuthType = () => {
      setShowSignUpWelcome(true)
      setAuthType((prevAuthType) =>
        prevAuthType === "login" ? "sign-up" : "login"
      );
      reset()
    };
    return (
      <div className="select-none "
      style={{
        backgroundImage: ` url(${bg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  // backgroundAttachment: "fixed",
      }}
      >
        <ToastContainer />
       {/* {showSignUpWelcome && (
        <div className="">
        <button
          className={`animated-button ${isOKLarge ? "large" : "small"}`}
          onClick={handleOKClick}
        >
          OK
        </button>
        <button
          className={`animated-button ${isOKLarge ? "small" : "large"}`}
          onClick={handleCancelClick}
        >
          Cancel
        </button>
      </div>
      )} */}

{/* {authType == "sign-up" && 
<div>
{showSignUpWelcome && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md h-screen w-full flex justify-center items-center">
           
            <div className="w-full px-96">
<div className="flex w-full justify-between">

            <button
          className={`animated-button ${isOKLarge ? "large" : "small"} border rounded-md`}
          onClick={handleOKClick}
          >
          OK
        </button>
        <button
          className={`animated-button ${isOKLarge ? "small" : "large"} border rounded-md`}
          onClick={handleCancelClick}
          >
          Cancel
        </button>
            </div>
          </div>
          </div>
        </div>
      )}
</div>
} */}


      <ResetPassword
        resetPasswordEmail={resetPasswordEmail}
        resetPasswordSuccess={resetPasswordSuccess}
        resetPasswordError={resetPasswordError}
        setResetPasswordEmail={setResetPasswordEmail}
        isOpen={resetPassword}
        onClose={() => setResetPassword(false)}
        handlePasswordReset={handlePasswordReset}
      />

      <div className={container}>
        <div className="w-full max-w-sm  rounded-lg bg-[#002D72] shadow-2xl">
          {errorMessage && (
            <p className="bg-red-400 px-3 py-2 text-center rounded-md text-white">
              {errorMessage}
            </p>
          )}
          <form onSubmit={authType === "sign-up" ? handleSubmit(handleFormSubmit) : handleSubmit(handleFormSubmit)} className={form}>
            <div className="flex w-full justify-center mb-8">

          <img src={logo} alt=""  />
            </div>
            <div className="grid gap-y-3">
              <button
                onClick={signInWithGoogle}
                className={button}
                type="button"
              >
                Google
              </button>
            </div>

            <div className="my-3 flex items-center px-3 text-white">
              <hr className={hr} />
              <span className={text}>or</span>
              <hr className={hr} />
            </div>

            <div className="grid gap-y-3">
              <input
                {...register("email")}
                className={input}
                type="email"
                placeholder="Email"
              />
              {/* {signInErrors.email && (
                <p className="text-red-500">{signInErrors.email.message}</p>
              )} */}
              <input
                {...register("password")}
                className={input}
                type="password"
                placeholder="Password"
              />
              {/* {signInErrors.password && (
                <p className="text-red-500">{signInErrors.password.message}</p>
              )} */}
              {authType === "sign-up" && (
                <>
                  <input
                    {...register("confirmPassword")}
                    className={input}
                    type="password"
                    placeholder="Confirm Password"
                  />
                  {/* {signUpErrors.confirmPassword && (
                    <p className="text-red-500">{signUpErrors.confirmPassword.message}</p>
                  )} */}
                </>
              )}
            </div>
<div className="flex w-full justify-center mt-4 no-underline">

            <button
              className={button}
              type="submit"
              disabled={loading}

              >
              {loading ? "Loading..." : authType === "sign-up" ? "Sign Up" : "Sign In"}
            </button>
              </div>
          </form>

          <div className="text-center">
            <Link to="#" className={forgotPasswordButton} onClick={() => setResetPassword(true)}>
              Forgot Password?
            </Link>
          </div>

          <div className="mt-3 mb-3 text-center">
            {authType === "login" ? (
              <p className="text-slate-300">
                Don't have an account?{" "}
                <Link to="#" className={link} onClick={handleAuthType}>
                  Sign up
                </Link>
              </p>
            ) : (
              <p className="text-slate-300">
                Already have an account?{" "}
                <Link to="#" className={link} onClick={handleAuthType}>
                  Sign in
                </Link>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;