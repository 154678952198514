import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebase';
import { ref, onValue } from 'firebase/database';
import Header from './Header';
import { useAppSelector } from '../../hooks/storeHook';
import { UserNotification } from '../../models/user/Notification';
import { useNavigate, useParams } from 'react-router-dom';
import GlobalNotification from './GlobalNotification';

const Notification = () => {
  const [notifications, setNotifications] = useState<Record<string, UserNotification>>({});
  const { userID } = useParams(); 
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { user } = useAppSelector((state) => state.auth);
  const navigate =useNavigate()
  const userDetails = useAppSelector((state) => state.auth.user?.userData);
  useEffect(() => {
    const user = auth.currentUser;
    if (!user?.emailVerified) {
      navigate(`/verification-pending/${user?.uid}`);
   
    }else
    { if(user && !userDetails) {
         navigate(`/credform/${user?.uid}`);
       }
      }
    
  }, []);
  useEffect(() => {
    const fetchNotifications = async () => {
      if (user && user.id) {
        setLoading(true)
        try {
          const storyArchiveRef = ref(db, `authers/${user?.id}/notification/`);

          onValue(storyArchiveRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              const storyArchiveData: Record<string, any> = data;

              setNotifications(storyArchiveData);
            }
            setLoading(false)
          });
        } catch (error:any) {
          setLoading(false)
          setErrorMessage(error)
        }
      }
    };
    fetchNotifications();
  }, [user, userID]);

  
  return (
    <div className='select-none'>
      {/* <Header /> */}
      
                <div className="px-2 ">
                  
                  <GlobalNotification />
                  {Object.keys(notifications).length === 0 ? (
                <p>No notifications found.</p>
              ) : (
                <ul>
                {Object.entries(notifications).map(([notificationId, notification]) => (
                  <li
                    className={` my-2 py-2 text-sm shadow-md  ${
                      notification.type === 'like'
                        ? 'border-l-4 border-r-4 p-1 border-red-500'
                        : 'border-l-4 border-r-4 p-1 border-blue-500'
                    }`}
                    key={notificationId}
                    onClick={() => {
                      // Mark the notification as read when clicked
                      const updatedNotifications = Object.fromEntries(
                        Object.entries(notifications).map(([id, notif]) => [
                          id,
                          { ...notif, isread: true },
                        ])
                      );
                      setNotifications(updatedNotifications);
                    }}
                  >
                      <span className=' font-semibold text-gray-900 dark:text-gray-100'>
                    {notification.type === 'like' ? (
                      <>
                        <i className="fa-sharp fa-solid fa-heart text-red-500 mr-1"></i>
                      </>
                    ) : (
                      <>
                        <i className="fa-sharp fa-solid fa-comment text-blue-500 mr-1"></i>
                      </>
                    )}
                    
                      {notification.userName.toUpperCase()}{' '}
                    
                    {notification.type === 'like' ? (
                      <>liked your</>
                    ) : (
                      <>commented on</>
                    )}
                    {' '}
                    story{' '}
                  {notification?.storyTitle}</span>
                  </li>
                ))}
              </ul>
              )}
                </div>
    </div>
  );
};

export default Notification;