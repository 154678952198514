import * as yup from "yup";


export const chapterFormSchema = yup.object().shape({
  
  title: yup
    .string()
    .required("title address is required"),

  chapterNo: yup.number()
  .required("category address is required"),

  content: yup
    .string()
    .required("summary is required"),
    
});

export interface ChapterForm {
  timestamp?: number;
  title: string;
  chapterNo: number;
  content: string;
}
