import React, { useState } from 'react';
import { useAppSelector } from '../../hooks/storeHook';

import { ref, push, set } from 'firebase/database';
import { db } from '../../firebase';

interface FeedbackForm {
  readingExperience: number;
  writingExperience: number;
  platformExperience: number;
  suggestions: string;
  email: string;
}

const StarRating: React.FC<{ value: number; onChange: (value: number) => void }> = ({ value, onChange }) => {
  const stars = [1, 2, 3, 4, 5];

  return (
    <div className='flex w-full gap-10 select-none'>
      {stars.map((star) => (
        <span key={star} onClick={() => onChange(star)}>
          {star <= value ? (
              
              <div className='text-yellow-500 text-3xl'>
              <i className={`fa fa-star cursor-pointer`}></i>
              </div>
              ) : (
                  
                  <div className='text-yellow-500 text-3xl'>
            <i className={`far fa-star cursor-pointer`}></i>
                  </div>
          )}
        </span>
      ))}
    </div>
  );
};

const Feedback: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [feedback, setFeedback] = useState<FeedbackForm>({
    email: user?.email || '',
    readingExperience: 0,
    writingExperience: 0,
    platformExperience: 0,
    suggestions: '',
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (field: keyof FeedbackForm, value: number | string) => {
    setFeedback({
      ...feedback,
      [field]: field === 'suggestions' ? value : Number(value),
    });
  };

  const handleSubmit =async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault();
    const timestampInSeconds = Date.now() / 1000;
    console.log('Feedback submitted:', feedback);
    const feedbackWithTimestamp = {
      ...feedback,
      timestampInSeconds,
    };
    try {
        setLoading(true);
        const commonFeedbackIdRef = ref(db, 'feedback');
        const commonFeedbackId = push(commonFeedbackIdRef).key;
  
        if (commonFeedbackId) {
          await set(ref(db, `feedback/${user?.id}`), feedbackWithTimestamp);
          setLoading(false);
          setSuccess(true);
          window.location.reload()

        } else {
          // Handle the case where commonFeedbackId is null
        }
      } catch (error: any) {
        setLoading(false);
        const errorCode = error.code;
        setErrorMessage(errorCode);
      }
      setLoading(true)
    // Add logic to submit the feedback to your backend or perform other actions
  };
  if (loading) {
    return null
  }

  return (
    <div className="flex  fixed top-0 right-0 z-50 justify-center items-center content-center w-full h-full p-4 overflow-x-hidden overflow-y-scroll bg-black bg-opacity-70 md:inset-0 md:h-full ">

 
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-900 backdrop-filter backdrop-blur-lg">
    {/* <div className="bg-white p-6 rounded-md h-screen w-full flex justify-center items-center"> */}

    <form className='bg-white px-10 pb-4 rounded-lg'  onSubmit={handleSubmit}>

      <label className="block text-gray-700 text-lg font-bold mt-4 font-1">
        Reading Experience:
      </label>
        <StarRating value={feedback.readingExperience} onChange={(value) => handleChange('readingExperience', value)} />
      
      <label className="block text-gray-700 text-lg font-bold mt-4 font-1">
        Writing Experience:
      </label>
        <StarRating value={feedback.writingExperience} onChange={(value) => handleChange('writingExperience', value)} />
      <br />
      <label className="block text-gray-700 text-lg font-bold mt-4 font-1">
        Platform Experience:
      </label>
        <StarRating value={feedback.platformExperience} onChange={(value) => handleChange('platformExperience', value)} />
      <label className="block text-gray-700 text-lg font-bold mt-4 font-1">
        Suggestions for Improvement:
      </label>
        <textarea
          value={feedback.suggestions}
          className={"shadow appearance-none border resize-none h-48 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"}
          onChange={(e) => handleChange('suggestions', e.target.value)}
        />
        <div className="flex justify-center mt-2">

      <button className={" rounded-md bg-blue-900 text-white px-4 py-2.5"} type="submit">Submit Feedback</button>
        </div>
    </form>
    </div>
    </div>
    // </div>
  );
};

export default Feedback;