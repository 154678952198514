import React, { useState } from "react";
import { StoryForm } from "../../models/story/StoryForm";
import { push, ref, set } from "firebase/database";

import { db } from "../../firebase";
import { useAppSelector } from "../../hooks/storeHook";
import { useNavigate, useNavigation } from "react-router-dom";
interface ConfirmationDialogProps {
  message: string;
  onCancel: () => void;
  formData: any;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  message,
  onCancel,
  formData,
}) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const [loading, setloading] = useState(false)
  const handleConfirmClick = async () => {
    setloading(true)
    try {
      const storyArchivePath = `authers/${user?.id}/storiesArchive/`;
      const allStoriesPath = `allStories/`;
      const commonId =await push(ref(db, storyArchivePath)).key;
      const newStoryRefPath =await `${storyArchivePath}${commonId}`;
      const newAllStoryRefPath =await `${allStoriesPath}${commonId}`;
      await set(ref(db, newStoryRefPath), formData);
      await set(ref(db, newAllStoryRefPath), formData);
      // onCancel();

      navigate(`/addcover/${commonId}`);
      setloading(false)
    } catch (error) {
      console.log(error);
    }
    setloading(false)
  };
  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 z-10 flex justify-center select-none items-center bg-black bg-opacity-90`}
      style={{ transform: `translateY-100%` }}
    >
      <div className="bg-white dark:bg-slate-800 lg:w-1/2 p-10">
      <div className='text-9xl text-yellow-500 text-center'>
            <i className="fa-solid fa-triangle-exclamation"></i>
            </div>
        <p className="mt-4 font-1 font-bold text-gray-900 dark:text-gray-100">{message}</p>
        <div className="flex gap-4 mt-4">
          <button
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            onClick={handleConfirmClick}
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
          <button
            className="bg-blue-900 text-white rounded-lg px-2 py-1 w-1/2"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
