import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  PencilIcon,
  MagnifyingGlassIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { headerClasses } from "./headerClasses";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHook";
import { logout } from "../../features/authSlice";
import { signOut } from "@firebase/auth";
import { auth } from "../../firebase";
import Dropdown from "./Categories";
import Notifications from "./Notification";
import { UserAuther } from "../../models/user/Auther";
// import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
// import { Link } from "react-router-dom";
// import Auth from "../pages/Auth/Auth";
import Logo from "../../assets/Logo.png" 
import { autherLogin } from "../../features/userAutherSlice";
import { db } from "../../firebase";
import { get, ref, set, update } from "firebase/database";
import logo from "../../assets/Logo.svg"
import logo2 from "../../assets/Logo2.svg"
import { toast, ToastContainer } from 'react-toastify';

// import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  // Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  // XMarkIcon,
} from '@heroicons/react/24/outline'
import {  PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { writeClassMod } from "../../pages/Auth/authClasses";
import DarkModeToggle from "./Dark";
import React from "react";

const products = [
  { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
  { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
  { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
  { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
  { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]













interface HeaderProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
  // Other props...
}


const Header: React.FC<HeaderProps> = ({ darkMode, toggleDarkMode }) => {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = async () => {
    await signOut(auth);
    dispatch(logout());
  };
  const {
    header,
    navContainer,
    navContent,
    linkHome,
    linkProfile,
    linkSignIn,
  } = headerClasses;

  const { user } = useAppSelector((state) => state.auth);
  const autherID = useAppSelector((state) => state.auther.auther?.id);
  const [toggle, settoggle] = useState(false);
  const valuesArray = [
    "Real life experiences",
    "Fantasy",
    "Magical realism",
    "Mythology",
    "Short stories",
    "Poems",
    "Young adult",
    "Erotica",
    "Romance",
    "Mystery",
    "Fanfiction",
    "Everyday memoir",
    "Daily frustration",
    "Comedy",
    "Sci-fiction",
    "Literary fiction",
    "Adventure",
    "Essay",
    "Flash fiction",
    "Hear-me-out",
    "Thriller",
    "Exposé",
    "Confessions",
    "Self-help",
    "Tech"
    
  ];

  const [selectedValue, setSelectedValue] = useState(""); // Track the selected value

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const handleDropdownChange = (value: string) => {
    setSelectedValue(value); // Update the selected value in state
    if (value !== "") {
      navigate(`/category/${encodeURIComponent(value)}`);
    }
  };

  const handleAuther = async (e: any) => {
    
      if (user) {
        navigate(`/addstory/${user?.id}`);
      }else{
        navigate(`/auth`);
      }
      
    
  };

  const navigation = [
    {
      name: "Home",
      href: `/`,
      current: false,
      icon: "mr-2 fa-sharp fa-solid fa-house",
    },
    {
      name: "Search",
      href: `/search/${user?.id}`,
      current: false,
      icon: "mr-2 fa-sharp fa-solid fa-magnifying-glass",
    },

    {
      name: "Write",
      href:  `/addstory/${user?.id}`,
      current: false,
      icon: "mr-2 fa-sharp fa-solid fa-pen-nib",
    },

    {
      name: "Notifications",
      href: `/notify/${user?.id}`,
      current: false,
      icon: "mr-2 fa-sharp fa-solid fa-bell",
    },
    {
      name: "Top Listed Authors",
      href: user ? `/allauthors/${user?.id}` :'/auth',
      current: false,
      icon: "mr-2 fa-sharp fa-solid fa-bar-chart",
    },
    {
      name: " Latest Release",
      href: `/lateststories`,
      current: false,
      icon: "mr-2 fa-sharp fa-solid fa-star",
    },
    
    {
      name: "Profile",
      href: `/profile/${user?.id}`,
      current: false,
      icon: "ml-0.5 mb-3 mr-2 fa-sharp fa-solid fa-user",
    },
    // {
    //   name: "Feedback",
    //   href: `/feedback/${user?.id}`,
    //   current: false,
    //   icon: "mr-2 fa-sharp fa-solid fa-comments",
    // },
  ];


  



  const navigationLg = [
    {
      name: "Home",
      href: `/`,
      current: false,
      icon: "mr-2 fa-sharp fa-solid fa-house",
    },
    

    

    
    {
      name: "Top Listed Authors",
      href: `/allauthors/${user?.id}`,
      current: false,
      icon: "mr-2 fa-sharp fa-solid fa-bar-chart",
    },
    

    {
      name: " Latest Release",
      href: `/lateststories`,
      current: false,
      icon: "mr-2 fa-sharp fa-solid fa-star",
    },
  ];

 

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }



 

  return (
    <div className=" bg-neutral-50  select-none">
     
     <ToastContainer />
      <header className="bg-[#002D72]">
      <nav className="mx-auto flex  items-center justify-between  py-2 lg:py-0 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <div className="" onClick={()=>navigate("/")}>
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" className="w-full h-7 ml-1 lg:ml-0  -mt-1 lg:mt-2 lg:h-10 lg:my-2" viewBox="0 0 285.14 70.29">
  <g>
    <path d="M20,19a1.76,1.76,0,0,1-.95.45c-1,0-3-1-4.3-2-.91-.65-2.4-1.88-2.72-3.26-.11-.48.33-1.81.38-1.45a7.65,7.65,0,0,0,2.89,3.92A21.37,21.37,0,0,0,20,19Z" fill="#f9f9f9"/>
    <path d="M21.48,17.48a1.78,1.78,0,0,1-1,.49c-1.12,0-3.24-1.13-4.63-2.14-1-.7-2.58-2-2.91-3.52-.12-.52.35-2,.39-1.56A8.45,8.45,0,0,0,16.43,15C18.86,16.82,21.62,17.34,21.48,17.48Z" fill="#f9f9f9"/>
    <path d="M27.94,69.93c.29.15.28.23-.05.23-1.13,0-4.63.19-5.46-.49C10.67,60.07,16,45,21.73,34.08q4-7.51,7.92-15C31.83,14.93,34.1,8,29.14,4.9c-4.17-2.57-9,3.66-11,6.35a2.82,2.82,0,0,1-1.47,1.12,2.39,2.39,0,0,1-2.38-.73.35.35,0,0,1-.08-.39C15.49,7.4,17.89,2.62,21.74.88q5.42-2.46,9.92,2c4.07,4,4.3,9.71,1.81,14.78q-5.22,10.58-10.65,21a33.79,33.79,0,0,0-4.2,17.76Q19.1,65.51,27.94,69.93Z" fill="#f9f9f9"/>
    <path d="M13.46,42Q19.34,29.73,26,17.86a10.92,10.92,0,0,0,1.42-5.15,2.17,2.17,0,0,0-2.28-2.38c-1.67.22-3.88,3.8-4.53,5.18a.47.47,0,0,1-.79.08c-2.3-2.95,5.7-11.43,9.09-7.78,1.78,1.92,1.35,5.66.25,7.79C23.29,27,15,38.43,12.68,51.32c-1.24,6.93.62,14.18,6.62,18.31.47.33.42.46-.15.38-10.43-1.3-10.9-14-8.46-21.44A60.37,60.37,0,0,1,13.46,42Z" fill="#f9f9f9"/>
    <path d="M50.65,50.46a.34.34,0,0,0,.14-.45L41.46,30.08a.54.54,0,0,0-.77-.24C33.61,34,29,40.28,25.89,47.89c0,0,0,0,0,0h0c2.66-8.48,8.16-17.45,16.52-21a.56.56,0,0,1,.72.27L54.91,50.55a.17.17,0,0,1-.12.25c-7.69,1.59-16.81,6.08-21.14,13a.09.09,0,0,1-.09,0h0a0,0,0,0,1,0,0,10,10,0,0,1,.73-1.39A28.35,28.35,0,0,1,49.82,50.81,4.32,4.32,0,0,0,50.65,50.46Z" fill="#f9f9f9"/>
    <path d="M60,52.89a.18.18,0,0,0,.1-.24L51,33.52a.66.66,0,0,0-.7-.38l-1.71.26a.28.28,0,0,1-.32-.16l-.71-1.37a.43.43,0,0,1,.27-.62l4.32-1.14a.64.64,0,0,1,.81.37L64.77,54a.28.28,0,0,1-.23.41c-9.63.82-20.83,3.34-28.32,9.87-.39.33-.41.31-.06-.07,5.53-6,14.92-9.85,22.84-11A4.74,4.74,0,0,0,60,52.89Z" fill="#f9f9f9"/>
    <path d="M60.36,57.83l-.24-1.29c0-.19,0-.29.23-.32l4-.48a.18.18,0,0,1,.19.11L66,59.64a.08.08,0,0,1-.08.11,57.69,57.69,0,0,0-28.86,5.92h0L37,65.55a.08.08,0,0,1,0-.13c6.89-4.28,15.17-6.26,23.14-7.31A.23.23,0,0,0,60.36,57.83Z" fill="#f9f9f9"/>
    <path d="M33.07,70.28s0,0,0,0l-.06-.1A.11.11,0,0,1,33,70c7.9-5.59,18.82-7.66,28.16-8.29a.66.66,0,0,1,.74.48l.7,2c.12.33,0,.49-.35.47A58.9,58.9,0,0,0,33.07,70.28Z" fill="#f9f9f9"/>
    <path d="M11.16,14.78s-3.41,3.8-5.7,6.42a14.22,14.22,0,0,1-3.52,3.05s-.21,6.33-.52,7.87c-.15.71-.4,1.73-.62,2.58A1.05,1.05,0,0,0,.19,35a.87.87,0,0,0,0,1.15C2.3,33.43,4.37,30.69,6.42,28a2,2,0,0,1,.07-1.27.89.89,0,0,1,.68-.34.89.89,0,0,1,.7,1.43c-.23.29-.93.34-1.25.34-2,2.75-4.19,5.39-6.17,8.16a1,1,0,0,0,.44.11.88.88,0,0,0,.68-.33,1.22,1.22,0,0,0,.14-.74c.66-.4,1.52-.9,2.38-1.39,2.41-1.37,7.52-2.18,7.52-2.18a11.44,11.44,0,0,1,1.5-4c1.57-2.44,4.69-7.6,4.69-7.6a14.13,14.13,0,0,1-4-2A11,11,0,0,1,11.16,14.78Z" fill="#f9f9f9" fill-rule="evenodd"/>
    <path d="M172.78,46.76l-.57-.6q2.32-8.11,7-12.55a14.6,14.6,0,0,1,10.21-3.9,7.76,7.76,0,0,1,6.27,3c1.69,2,2.52,4.84,2.52,8.52V70.29H187V42q0-8.79-3.37-8.79c-1.77,0-3.79,1.63-6.08,4.91a32.91,32.91,0,0,0-4.78,10.43Z" fill="#f9f9f9"/>
    <path d="M136.21,12.27l.1,57a1,1,0,0,1-1.05,1.05l-6.63,0a.7.7,0,0,1-.78-.8l0-50.94a2.44,2.44,0,0,0-.44-1.39,4,4,0,0,0-1.9-1.43c-.43-.14-.43-.3,0-.45l10.07-3.47a.5.5,0,0,1,.65.48Z" fill="#f9f9f9"/>
    <path d="M173.58,13.35l0,56a.83.83,0,0,1-.92.94l-6.89,0a.7.7,0,0,1-.79-.78l0-50.34a2.09,2.09,0,0,0-.74-1.55,4.09,4.09,0,0,0-1.6-.76.55.55,0,0,1-.07-1l10-3.22c.7-.22,1.05,0,1.05.76Z" fill="#f9f9f9"/>
    <path d="M80.27,68.77a39.85,39.85,0,0,0,24.26-7.26.51.51,0,0,1,.81.41l.12,7.48a.8.8,0,0,1-.9.89l-32.76,0a.72.72,0,0,1-.82-.82l-.06-56a.46.46,0,0,1,.45-.45l7.66,0a.44.44,0,0,1,.43.45l0,54.54a.78.78,0,0,0,.86.88Z" fill="#f9f9f9"/>
    <path d="M146.53,29.91a1,1,0,0,0-.67,1.71A4,4,0,0,1,147,34.43a1.6,1.6,0,0,1-.27.94l-7.36,10-.11.09a15,15,0,0,0-2,2c-1.5,1.82-1.08,2.26,1.25,1.35q5.61-2.18,7.85,4.36c1.94,5.62,3.65,10.94,5.15,15.92a1.49,1.49,0,0,0,1.6,1.09l7.21,0a1,1,0,0,0,1-1.23c-2.52-6.74-5.06-18.29-10.6-23.49a2.8,2.8,0,0,0-1.63-.72c-1.38-.12-2.74-.21-4.09-.26h0c-1,0-1.83-.05-2.5-.05l14.86-13.35c.67-.61.57-.93-.29-.94Z" fill="#f9f9f9"/>
    <path d="M246.77,34.54c-2.86,5,3.16,8.48,7.27,10,7.42,2.7,24.29,9,16.79,20.52-5.29,8.13-20.76,5.45-27.67,1.2A2.88,2.88,0,0,1,242,62.6l3.2-7.36a.69.69,0,0,1,1.21-.1c4.48,7.06,10.37,12,19.17,12.44a2.44,2.44,0,0,0,2.83-2.42C269.49,55.91,245,55.39,242.77,43c-2.75-15.26,19.34-13.67,27.31-9.47a1.68,1.68,0,0,1,.73,2.19l-3.1,6.64a.9.9,0,0,1-1.5.19c-3.73-4.44-12-11-18.27-9A2,2,0,0,0,246.77,34.54Z" fill="#f9f9f9"/>
    <path d="M121,24.06a4.84,4.84,0,1,1-4.84-4.84A4.84,4.84,0,0,1,121,24.06Z" fill="#f9f9f9"/>
    <path d="M111,35.78a3.21,3.21,0,0,1-1-.48c-.71-.5-.65-.82.23-.93A29.27,29.27,0,0,0,120,31.13a.56.56,0,0,1,.82.49V69.46a.73.73,0,0,1-.83.83l-6.77-.05a.71.71,0,0,1-.78-.8l0-31.84A1.89,1.89,0,0,0,111,35.78Z" fill="#f9f9f9"/>
    <path d="M285.14,24.06a4.85,4.85,0,1,1-4.85-4.84A4.85,4.85,0,0,1,285.14,24.06Z" fill="#f9f9f9"/>
    <path d="M275.07,35.78a3.11,3.11,0,0,1-1-.48c-.72-.5-.66-.82.22-.93a29.32,29.32,0,0,0,9.8-3.24.56.56,0,0,1,.81.49l0,37.84a.73.73,0,0,1-.83.83l-6.77-.05a.7.7,0,0,1-.78-.8l0-31.84a1.88,1.88,0,0,0-1.4-1.82Z" fill="#f9f9f9"/>
    <path d="M213.09,36.61c-1.34.87-2.49,2.31-2.16,3.65.26,1.08,1.75,2.11,3,2.5l3.16,1a3.37,3.37,0,0,0,2.32-.08l7.38-3c3-1.23.69-5.35-2.53-6.48C219.94,32.67,216.06,34.79,213.09,36.61Zm7.09,31.81c-.61.08,3-2,3.6-2.35,7.63-5,2.19-15.31-4.31-19.75-.62-.47.34-.59.45-.77s.3-.3.47-.19c2.16,1.45,6.35,4.7,7.76,6.86,0,0-.12-1-.12-2.28l0-5.43c0-.79.29-3.44-.39-3-1.29.75-2.61,1.5-4.08,2.46A38.88,38.88,0,0,1,220,46.62a18.33,18.33,0,0,0-8.84,10.48c-2.14,6,.73,10.94,7.25,11.18a6.92,6.92,0,0,0,2.63-.61c.58-.21.8.31,1.5.35a2.32,2.32,0,0,1-.79.51C212,72.82,194.64,68,205,54.44a27.42,27.42,0,0,1,9.59-7.67c2.2-1.49,2.64-1.59-.15-2.18L203.81,41.9a1.52,1.52,0,0,1-1.08-2.1c3.39-7.81,12.52-10.71,20.34-8.65,5.13,1.33,7.14,6.19,8.4,11.58q3.18,13.5,6.43,26.35c.19.75-.09,1.11-.87,1.13l-6.58.08a1,1,0,0,1-1.1-1l-.26-6.94c0-.94-.28-1-.75-.19a15.87,15.87,0,0,1-6,6Z" fill="#f9f9f9"/>
  </g>
</svg>

          </div>
         
        </div>
        <div className="flex lg:hidden">
        <button
            // type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-100  dark:text-gray-200"
            // onClick={() => setMobileMenuOpen(true)}
          >
            <div
                          // key={item.name}
                          onClick={handleAuther}
                          className="text-[#002D72] dark:text-blue-800   cursor-pointer   hover:text-blue-700 px-3  flex items-center gap-2   text-sm font-medium no-underline" // ? "bg-gray-900 text-white" // item.current

                          // aria-current={item.current ? "page" : undefined}
                        >
                          <div className={"flex bg-gray-50 dark:bg-gray-100 text-lg  justify-evenly p-0.5 rounded-lg "}>

<span className="text-md ml-2.5">Write </span>
<span>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" className="h-6 -ml-2" viewBox="0 0 284.1 141.48">
<g>
<path d="M123.64,45.1a3,3,0,0,1-1.67.8c-1.85,0-5.34-1.85-7.61-3.52-1.61-1.16-4.26-3.33-4.81-5.76-.2-.88.58-3.21.66-2.57A13.7,13.7,0,0,0,115.32,41C119.32,44,123.87,44.88,123.64,45.1Z" fill="#002D72"/>
<path d="M126.37,42.34a3.15,3.15,0,0,1-1.76.88c-2,0-5.74-2-8.19-3.76-1.76-1.25-4.57-3.6-5.16-6.24-.21-.93.62-3.47.71-2.78a14.76,14.76,0,0,0,5.49,7.5C121.75,41.18,126.6,42.1,126.37,42.34Z" fill="#002D72"/>
<path d="M137.79,135.17c.51.26.49.4-.09.41-2,0-8.19.32-9.67-.88-20.81-17-11.37-43.58-1.22-63q7-13.32,14-26.63c3.84-7.26,7.86-19.6-.88-25-7.4-4.56-16,6.46-19.6,11.22a4.8,4.8,0,0,1-2.59,2A4.2,4.2,0,0,1,113.54,32a.62.62,0,0,1-.15-.69C115.75,24.51,120,16,126.81,13q9.58-4.35,17.58,3.46c7.19,7,7.62,17.19,3.19,26.16q-9.24,18.7-18.86,37.22c-5.18,10-8,20.1-7.42,31.42Q122.16,127.33,137.79,135.17Z" fill="#002D72"/>
<path d="M112.17,85.77Q122.57,64,134.39,43.06a19.33,19.33,0,0,0,2.51-9.13c.06-2.3-1.65-4.49-4-4.19-3,.38-6.85,6.73-8,9.15a.81.81,0,0,1-1.09.4.89.89,0,0,1-.3-.24c-4.08-5.27,10.08-20.22,16.08-13.78,3.15,3.4,2.38,10,.44,13.79-10.42,20.11-25.16,40.45-29.2,63.21-2.19,12.26,1.1,25.09,11.71,32.42.88.57.75.81-.26.67-18.46-2.3-19.29-24.73-14.94-37.94A100.58,100.58,0,0,1,112.17,85.77Z" fill="#002D72"/>
<path d="M178,100.71a.62.62,0,0,0,.25-.8L161.72,64.65a1,1,0,0,0-1.28-.48l-.1,0c-12.52,7.42-20.73,18.47-26.17,32h-.07c4.72-15,14.44-30.89,29.23-37.15a1,1,0,0,1,1.28.47l20.94,41.39a.3.3,0,0,1-.13.4.13.13,0,0,1-.08,0c-13.61,2.81-29.74,10.75-37.41,22.92a.16.16,0,0,1-.16.09h-.05v-.08a16.46,16.46,0,0,1,1.29-2.46,50.1,50.1,0,0,1,27.54-20.44A6.39,6.39,0,0,0,178,100.71Z" fill="#002D72"/>
<path d="M194.53,105a.3.3,0,0,0,.2-.38h0l-16-33.85a1.19,1.19,0,0,0-1.25-.67l-3,.46a.49.49,0,0,1-.55-.29l-1.27-2.42a.77.77,0,0,1,.32-1,.85.85,0,0,1,.17-.06l7.58-2a1.12,1.12,0,0,1,1.43.65l20.85,41.55a.5.5,0,0,1-.23.67.41.41,0,0,1-.18.05c-17,1.47-36.86,5.91-50.12,17.47-.69.59-.72.56-.1-.11,9.79-10.55,26.38-17.44,40.45-19.51A7.84,7.84,0,0,0,194.53,105Z" fill="#002D72"/>
<path d="M195.17,113.76l-.44-2.29a.43.43,0,0,1,.25-.54.45.45,0,0,1,.16,0l7-.88a.31.31,0,0,1,.31.2l2.64,6.71a.14.14,0,0,1-.08.18H205a102.24,102.24,0,0,0-51.08,10.48h0l-.11-.2a.16.16,0,0,1,0-.22l0,0c12.2-7.57,26.87-11.08,41-12.93A.42.42,0,0,0,195.17,113.76Z" fill="#002D72"/>
<path d="M146.87,135.79h-.05l-.1-.16a.23.23,0,0,1,.05-.32l0,0c14-9.87,33.32-13.53,49.84-14.66a1.18,1.18,0,0,1,1.31.88l1.25,3.51c.21.59,0,.88-.64.84A104.35,104.35,0,0,0,146.87,135.79Z" fill="#002D72"/>
<path d="M108.09,37.57s-6,6.73-10.08,11.36a25,25,0,0,1-6.25,5.39s-.35,11.21-.88,13.93c-.26,1.26-.7,3.06-1.08,4.57a1.93,1.93,0,0,0-1.09.5,1.56,1.56,0,0,0,.09,2c3.64-4.79,7.29-9.68,10.94-14.44-.11-.55-.28-1.76.13-2.25a1.5,1.5,0,0,1,1.19-.58,1.55,1.55,0,0,1,1.21,2.56c-.42.51-1.66.59-2.22.6-3.56,4.82-7.4,9.51-10.91,14.4a1.44,1.44,0,0,0,.78.2,1.56,1.56,0,0,0,1.19-.58A2.1,2.1,0,0,0,91.36,74c1.18-.7,2.69-1.58,4.22-2.45,4.25-2.42,13.32-3.82,13.32-3.82a20.06,20.06,0,0,1,2.63-7.09c2.78-4.31,8.3-13.46,8.3-13.46a25,25,0,0,1-7.1-3.59A20,20,0,0,1,108.09,37.57Z" fill="#002D72" fill-rule="evenodd"/>
</g>
</svg>
  {/* <i className="fa-sharp fa-solid fa-pen-nib pt-2"></i> */}
</span>
</div>
                        </div>
            {/* <Bars3Icon className="h-6 w-6" aria-hidden="true" /> */}
          </button>
          <button
            type="button"
            className=" inline-flex items-center justify-center rounded-md p-2.5 text-gray-100  dark:text-gray-200"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
        <div className="text-lg  font-semibold leading-6 text-gray-100 dark:text-gray-100">
          
          <div className={`cursor-pointer writte`} onClick={()=>navigate( `/`)}>
          
          Home
          </div>
          </div>
        <div className="text-lg  font-semibold leading-6 text-gray-100 dark:text-gray-100 latestAuthers">
          
          <div className={`cursor-pointer `} onClick={()=>navigate( `/allauthors/${user?.id}`)}>
          
          Top Listed Authors
          </div>
          </div>
          <div className="text-lg  font-semibold leading-6 text-gray-100 dark:text-gray-100 latestRealease">
          
          <div className={`cursor-pointer `} onClick={()=>navigate(`/lateststories`)}>
          
          Latest Release
          </div>
          </div>
{/* {navigationLg.map((item) => (

<div className="text-lg  font-semibold leading-6 text-gray-100 dark:text-gray-100">
          
<div className={`cursor-pointer ${item.name==="Top Listed Authors" && ""}`} onClick={()=>navigate(item.href)}>

{item.name}
</div>
</div>
))} */}

         
          <div className="text-sm font-semibold leading-6 text-gray-100 genres">
            <Dropdown/>
          </div>
          
          

         
        </Popover.Group>
        <div className="hidden lg:flex lg:gap-4 xl:gap-8 lg:flex-1 lg:justify-end">
        <div
                          // key={item.name}
                          onClick={handleAuther}
                          className="text-[#002D72] dark:text-blue-800  cursor-pointer   hover:text-blue-700 px-3  flex items-center gap-2   text-sm font-medium no-underline" // ? "bg-gray-900 text-white" // item.current

                          // aria-current={item.current ? "page" : undefined}
                        >
                          <div className={"flex bg-gray-50 dark:bg-gray-100  write justify-evenly p-1 rounded-lg"}>

<span className="text-xl ml-2.5">Write </span>
<span>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" className="h-6 -ml-2" viewBox="0 0 284.1 141.48">
<g>
<path d="M123.64,45.1a3,3,0,0,1-1.67.8c-1.85,0-5.34-1.85-7.61-3.52-1.61-1.16-4.26-3.33-4.81-5.76-.2-.88.58-3.21.66-2.57A13.7,13.7,0,0,0,115.32,41C119.32,44,123.87,44.88,123.64,45.1Z" fill="#002D72"/>
<path d="M126.37,42.34a3.15,3.15,0,0,1-1.76.88c-2,0-5.74-2-8.19-3.76-1.76-1.25-4.57-3.6-5.16-6.24-.21-.93.62-3.47.71-2.78a14.76,14.76,0,0,0,5.49,7.5C121.75,41.18,126.6,42.1,126.37,42.34Z" fill="#002D72"/>
<path d="M137.79,135.17c.51.26.49.4-.09.41-2,0-8.19.32-9.67-.88-20.81-17-11.37-43.58-1.22-63q7-13.32,14-26.63c3.84-7.26,7.86-19.6-.88-25-7.4-4.56-16,6.46-19.6,11.22a4.8,4.8,0,0,1-2.59,2A4.2,4.2,0,0,1,113.54,32a.62.62,0,0,1-.15-.69C115.75,24.51,120,16,126.81,13q9.58-4.35,17.58,3.46c7.19,7,7.62,17.19,3.19,26.16q-9.24,18.7-18.86,37.22c-5.18,10-8,20.1-7.42,31.42Q122.16,127.33,137.79,135.17Z" fill="#002D72"/>
<path d="M112.17,85.77Q122.57,64,134.39,43.06a19.33,19.33,0,0,0,2.51-9.13c.06-2.3-1.65-4.49-4-4.19-3,.38-6.85,6.73-8,9.15a.81.81,0,0,1-1.09.4.89.89,0,0,1-.3-.24c-4.08-5.27,10.08-20.22,16.08-13.78,3.15,3.4,2.38,10,.44,13.79-10.42,20.11-25.16,40.45-29.2,63.21-2.19,12.26,1.1,25.09,11.71,32.42.88.57.75.81-.26.67-18.46-2.3-19.29-24.73-14.94-37.94A100.58,100.58,0,0,1,112.17,85.77Z" fill="#002D72"/>
<path d="M178,100.71a.62.62,0,0,0,.25-.8L161.72,64.65a1,1,0,0,0-1.28-.48l-.1,0c-12.52,7.42-20.73,18.47-26.17,32h-.07c4.72-15,14.44-30.89,29.23-37.15a1,1,0,0,1,1.28.47l20.94,41.39a.3.3,0,0,1-.13.4.13.13,0,0,1-.08,0c-13.61,2.81-29.74,10.75-37.41,22.92a.16.16,0,0,1-.16.09h-.05v-.08a16.46,16.46,0,0,1,1.29-2.46,50.1,50.1,0,0,1,27.54-20.44A6.39,6.39,0,0,0,178,100.71Z" fill="#002D72"/>
<path d="M194.53,105a.3.3,0,0,0,.2-.38h0l-16-33.85a1.19,1.19,0,0,0-1.25-.67l-3,.46a.49.49,0,0,1-.55-.29l-1.27-2.42a.77.77,0,0,1,.32-1,.85.85,0,0,1,.17-.06l7.58-2a1.12,1.12,0,0,1,1.43.65l20.85,41.55a.5.5,0,0,1-.23.67.41.41,0,0,1-.18.05c-17,1.47-36.86,5.91-50.12,17.47-.69.59-.72.56-.1-.11,9.79-10.55,26.38-17.44,40.45-19.51A7.84,7.84,0,0,0,194.53,105Z" fill="#002D72"/>
<path d="M195.17,113.76l-.44-2.29a.43.43,0,0,1,.25-.54.45.45,0,0,1,.16,0l7-.88a.31.31,0,0,1,.31.2l2.64,6.71a.14.14,0,0,1-.08.18H205a102.24,102.24,0,0,0-51.08,10.48h0l-.11-.2a.16.16,0,0,1,0-.22l0,0c12.2-7.57,26.87-11.08,41-12.93A.42.42,0,0,0,195.17,113.76Z" fill="#002D72"/>
<path d="M146.87,135.79h-.05l-.1-.16a.23.23,0,0,1,.05-.32l0,0c14-9.87,33.32-13.53,49.84-14.66a1.18,1.18,0,0,1,1.31.88l1.25,3.51c.21.59,0,.88-.64.84A104.35,104.35,0,0,0,146.87,135.79Z" fill="#002D72"/>
<path d="M108.09,37.57s-6,6.73-10.08,11.36a25,25,0,0,1-6.25,5.39s-.35,11.21-.88,13.93c-.26,1.26-.7,3.06-1.08,4.57a1.93,1.93,0,0,0-1.09.5,1.56,1.56,0,0,0,.09,2c3.64-4.79,7.29-9.68,10.94-14.44-.11-.55-.28-1.76.13-2.25a1.5,1.5,0,0,1,1.19-.58,1.55,1.55,0,0,1,1.21,2.56c-.42.51-1.66.59-2.22.6-3.56,4.82-7.4,9.51-10.91,14.4a1.44,1.44,0,0,0,.78.2,1.56,1.56,0,0,0,1.19-.58A2.1,2.1,0,0,0,91.36,74c1.18-.7,2.69-1.58,4.22-2.45,4.25-2.42,13.32-3.82,13.32-3.82a20.06,20.06,0,0,1,2.63-7.09c2.78-4.31,8.3-13.46,8.3-13.46a25,25,0,0,1-7.1-3.59A20,20,0,0,1,108.09,37.57Z" fill="#002D72" fill-rule="evenodd"/>
</g>
</svg>
  {/* <i className="fa-sharp fa-solid fa-pen-nib pt-2"></i> */}
</span>
</div>
                        </div>

                        <div
                          className="flex items-center text-md   links border-solid border-gray-200"
                          onClick={() => {
                            navigate(`/search/${user?.id}`);
                          }}
                        >
                          <i className="fa-sharp fa-solid  fa-magnifying-glass opacity-80 hover:opacity-100 text-[#F8F6F0]"></i>
                        </div>

                        <div className="flex items-center text-md notifications links border-solid border-gray-200" >
                          <Notifications />
                        </div>
                        
                        <Menu as="div" className="relative profile">
  <div>
    <Menu.Button className={`flex rounded-full text-sm  text-white  ${user ? "focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" :""}`}>
      {Boolean(!user?.id) && (
        <i className="fa-sharp fa-solid fa-user text-white rounded-full  m-4" onClick={()=>navigate("/auth")}></i>
      )}
      {user ? (
        <div className="">
          {user?.photoUrl ? (
            <div className="flex items-center gap-4 text-xs font-bold font-2">
              <img
                className={linkProfile}
                src={user.photoUrl}
                alt="Avatar"
              />
              
            </div>
          ) : (
            <div className={linkProfile}>
              <span className="text-light text-xl font-bold">
                {user?.email[0].toUpperCase()}
              </span>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </Menu.Button>
  </div>

  <Transition
    as={Fragment}
    enter="transition ease-out duration-100"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-75"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
  >
    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-left rounded-md bg-white dark:bg-slate-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-56">
      <Menu.Item>
        {({ active }) => (
          <span
            className={classNames(
              active ? "bg-gray-100 dark:bg-gray-400" : "",
              "block px-4 py-2 text-sm text-gray-700 dark:text-gray-100 prof"
            )}
          >
            {Boolean(!user) && (
              <Link
                to="/auth"
                className={classNames(
                  active ? "bg-gray-100 dark:bg-gray-400" : "",
                  "block px-4 py-2 text-sm text-gray-700 dark:text-gray-100 prof"
                )}
              >
                <i className="fa-sharp fa-solid fa-user 0"> </i> Sign in
              </Link>
            )}
            {user ? (
              <Link
                to={`/profile/${user.id}`}
                className={classNames(
                  active ? "bg-gray-100 dark:bg-gray-400" : "",
                  "block px-4 py-2 text-sm text-gray-700 dark:text-gray-100 prof"
                )}
              >
                  <span>
                    <i className="fa-sharp fa-solid fa-user 0"> </i> Profile
                  </span>
              
              </Link>
            ) : (
              <></>
            )}
          </span>
        )}
      </Menu.Item>
      {user?.userData?.name === "" && (
        <Menu.Item>
          {({ active }) => (
            <span
              className={classNames(
                active ? "bg-gray-100 dark:bg-gray-400" : "",
                "block px-4 py-2 text-sm text-gray-700 dark:text-gray-100 prof"
              )}
            >
              <Link
                to={`/credform/${user?.id}`}
                className={classNames(
                  active ? "bg-gray-100 dark:bg-gray-400" : "",
                  "block px-4 py-2 text-sm text-gray-700 dark:text-gray-100 prof"
                )}
              >
                <span>
                  <i className="fa-sharp fa-solid fa-gear 0"> </i> cred form
                </span>
              </Link>
            </span>
          )}
        </Menu.Item>
      )}
       <Menu.Item>
          {({ active }) => (
            <div
            onClick={toggleDarkMode}
              className={classNames(
                active ? "bg-gray-100 dark:bg-gray-400" : "",
                "block px-8 py-4 text-sm text-gray-700 dark:text-gray-100 prof cursor-pointer"
              )}
            >
                
                {darkMode ? 
        <div className='flex gap-2 ' >
         <div className=''>
          <i className="fa-sharp fa-solid  fa-sun text-yellow-300 opacity-80 hover:opacity-100"></i>
          </div>
<div className={`text-md `}>

       Light Mode
</div>
        
        
        </div>
          :
        <div className='flex gap-2 '>
          <div>
          <i className="fa-sharp fa-solid  fa-moon opacity-80 hover:opacity-100 text-black"></i>
          </div>
          <div className='text-md'>

          Dark Mode
</div>
        </div>
         }
            </div>
          )}
        </Menu.Item>
{/* <Menu.Item>
          {({ active }) => (
            <button className="text-black" onClick={toggleTheme}>
            Toggle Theme
          </button>
          )}
        </Menu.Item> */}

      {Boolean(user) ? (
        <Menu.Item>
          {({ active }) => (
            <div
            onClick={handleLogout}
              className={classNames(
                active ? "bg-gray-100 dark:bg-gray-400" : "",
                "block px-7 py-4 text-sm cursor-pointer text-gray-700  dark:text-gray-100 prof"
              )}
            >
                <span >
                  <i className="fa-sharp fa-solid fa-arrow-right-to-bracket mr-0"> </i> Sign out
                </span>
            </div>
          )}
        </Menu.Item>
      ) : (
        <></>
      )}
    </Menu.Items>
  </Transition>
</Menu>
        </div>

        
      </nav>

      
      
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <div className="-m-1.5 p-1.5">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" className="w-full h-7 -mt-1 lg:mt-2 lg:h-10 lg:my-2" viewBox="0 0 285.14 70.29">
  <g>
    <path d="M20,19a1.76,1.76,0,0,1-.95.45c-1,0-3-1-4.3-2-.91-.65-2.4-1.88-2.72-3.26-.11-.48.33-1.81.38-1.45a7.65,7.65,0,0,0,2.89,3.92A21.37,21.37,0,0,0,20,19Z" fill="#000000"/>
    <path d="M21.48,17.48a1.78,1.78,0,0,1-1,.49c-1.12,0-3.24-1.13-4.63-2.14-1-.7-2.58-2-2.91-3.52-.12-.52.35-2,.39-1.56A8.45,8.45,0,0,0,16.43,15C18.86,16.82,21.62,17.34,21.48,17.48Z" fill="#000000"/>
    <path d="M27.94,69.93c.29.15.28.23-.05.23-1.13,0-4.63.19-5.46-.49C10.67,60.07,16,45,21.73,34.08q4-7.51,7.92-15C31.83,14.93,34.1,8,29.14,4.9c-4.17-2.57-9,3.66-11,6.35a2.82,2.82,0,0,1-1.47,1.12,2.39,2.39,0,0,1-2.38-.73.35.35,0,0,1-.08-.39C15.49,7.4,17.89,2.62,21.74.88q5.42-2.46,9.92,2c4.07,4,4.3,9.71,1.81,14.78q-5.22,10.58-10.65,21a33.79,33.79,0,0,0-4.2,17.76Q19.1,65.51,27.94,69.93Z" fill="#000000"/>
    <path d="M13.46,42Q19.34,29.73,26,17.86a10.92,10.92,0,0,0,1.42-5.15,2.17,2.17,0,0,0-2.28-2.38c-1.67.22-3.88,3.8-4.53,5.18a.47.47,0,0,1-.79.08c-2.3-2.95,5.7-11.43,9.09-7.78,1.78,1.92,1.35,5.66.25,7.79C23.29,27,15,38.43,12.68,51.32c-1.24,6.93.62,14.18,6.62,18.31.47.33.42.46-.15.38-10.43-1.3-10.9-14-8.46-21.44A60.37,60.37,0,0,1,13.46,42Z" fill="#000000"/>
    <path d="M50.65,50.46a.34.34,0,0,0,.14-.45L41.46,30.08a.54.54,0,0,0-.77-.24C33.61,34,29,40.28,25.89,47.89c0,0,0,0,0,0h0c2.66-8.48,8.16-17.45,16.52-21a.56.56,0,0,1,.72.27L54.91,50.55a.17.17,0,0,1-.12.25c-7.69,1.59-16.81,6.08-21.14,13a.09.09,0,0,1-.09,0h0a0,0,0,0,1,0,0,10,10,0,0,1,.73-1.39A28.35,28.35,0,0,1,49.82,50.81,4.32,4.32,0,0,0,50.65,50.46Z" fill="#000000"/>
    <path d="M60,52.89a.18.18,0,0,0,.1-.24L51,33.52a.66.66,0,0,0-.7-.38l-1.71.26a.28.28,0,0,1-.32-.16l-.71-1.37a.43.43,0,0,1,.27-.62l4.32-1.14a.64.64,0,0,1,.81.37L64.77,54a.28.28,0,0,1-.23.41c-9.63.82-20.83,3.34-28.32,9.87-.39.33-.41.31-.06-.07,5.53-6,14.92-9.85,22.84-11A4.74,4.74,0,0,0,60,52.89Z" fill="#000000"/>
    <path d="M60.36,57.83l-.24-1.29c0-.19,0-.29.23-.32l4-.48a.18.18,0,0,1,.19.11L66,59.64a.08.08,0,0,1-.08.11,57.69,57.69,0,0,0-28.86,5.92h0L37,65.55a.08.08,0,0,1,0-.13c6.89-4.28,15.17-6.26,23.14-7.31A.23.23,0,0,0,60.36,57.83Z" fill="#000000"/>
    <path d="M33.07,70.28s0,0,0,0l-.06-.1A.11.11,0,0,1,33,70c7.9-5.59,18.82-7.66,28.16-8.29a.66.66,0,0,1,.74.48l.7,2c.12.33,0,.49-.35.47A58.9,58.9,0,0,0,33.07,70.28Z" fill="#000000"/>
    <path d="M11.16,14.78s-3.41,3.8-5.7,6.42a14.22,14.22,0,0,1-3.52,3.05s-.21,6.33-.52,7.87c-.15.71-.4,1.73-.62,2.58A1.05,1.05,0,0,0,.19,35a.87.87,0,0,0,0,1.15C2.3,33.43,4.37,30.69,6.42,28a2,2,0,0,1,.07-1.27.89.89,0,0,1,.68-.34.89.89,0,0,1,.7,1.43c-.23.29-.93.34-1.25.34-2,2.75-4.19,5.39-6.17,8.16a1,1,0,0,0,.44.11.88.88,0,0,0,.68-.33,1.22,1.22,0,0,0,.14-.74c.66-.4,1.52-.9,2.38-1.39,2.41-1.37,7.52-2.18,7.52-2.18a11.44,11.44,0,0,1,1.5-4c1.57-2.44,4.69-7.6,4.69-7.6a14.13,14.13,0,0,1-4-2A11,11,0,0,1,11.16,14.78Z" fill="#000000" fill-rule="evenodd"/>
    <path d="M172.78,46.76l-.57-.6q2.32-8.11,7-12.55a14.6,14.6,0,0,1,10.21-3.9,7.76,7.76,0,0,1,6.27,3c1.69,2,2.52,4.84,2.52,8.52V70.29H187V42q0-8.79-3.37-8.79c-1.77,0-3.79,1.63-6.08,4.91a32.91,32.91,0,0,0-4.78,10.43Z" fill="#000000"/>
    <path d="M136.21,12.27l.1,57a1,1,0,0,1-1.05,1.05l-6.63,0a.7.7,0,0,1-.78-.8l0-50.94a2.44,2.44,0,0,0-.44-1.39,4,4,0,0,0-1.9-1.43c-.43-.14-.43-.3,0-.45l10.07-3.47a.5.5,0,0,1,.65.48Z" fill="#000000"/>
    <path d="M173.58,13.35l0,56a.83.83,0,0,1-.92.94l-6.89,0a.7.7,0,0,1-.79-.78l0-50.34a2.09,2.09,0,0,0-.74-1.55,4.09,4.09,0,0,0-1.6-.76.55.55,0,0,1-.07-1l10-3.22c.7-.22,1.05,0,1.05.76Z" fill="#000000"/>
    <path d="M80.27,68.77a39.85,39.85,0,0,0,24.26-7.26.51.51,0,0,1,.81.41l.12,7.48a.8.8,0,0,1-.9.89l-32.76,0a.72.72,0,0,1-.82-.82l-.06-56a.46.46,0,0,1,.45-.45l7.66,0a.44.44,0,0,1,.43.45l0,54.54a.78.78,0,0,0,.86.88Z" fill="#000000"/>
    <path d="M146.53,29.91a1,1,0,0,0-.67,1.71A4,4,0,0,1,147,34.43a1.6,1.6,0,0,1-.27.94l-7.36,10-.11.09a15,15,0,0,0-2,2c-1.5,1.82-1.08,2.26,1.25,1.35q5.61-2.18,7.85,4.36c1.94,5.62,3.65,10.94,5.15,15.92a1.49,1.49,0,0,0,1.6,1.09l7.21,0a1,1,0,0,0,1-1.23c-2.52-6.74-5.06-18.29-10.6-23.49a2.8,2.8,0,0,0-1.63-.72c-1.38-.12-2.74-.21-4.09-.26h0c-1,0-1.83-.05-2.5-.05l14.86-13.35c.67-.61.57-.93-.29-.94Z" fill="#000000"/>
    <path d="M246.77,34.54c-2.86,5,3.16,8.48,7.27,10,7.42,2.7,24.29,9,16.79,20.52-5.29,8.13-20.76,5.45-27.67,1.2A2.88,2.88,0,0,1,242,62.6l3.2-7.36a.69.69,0,0,1,1.21-.1c4.48,7.06,10.37,12,19.17,12.44a2.44,2.44,0,0,0,2.83-2.42C269.49,55.91,245,55.39,242.77,43c-2.75-15.26,19.34-13.67,27.31-9.47a1.68,1.68,0,0,1,.73,2.19l-3.1,6.64a.9.9,0,0,1-1.5.19c-3.73-4.44-12-11-18.27-9A2,2,0,0,0,246.77,34.54Z" fill="#000000"/>
    <path d="M121,24.06a4.84,4.84,0,1,1-4.84-4.84A4.84,4.84,0,0,1,121,24.06Z" fill="#000000"/>
    <path d="M111,35.78a3.21,3.21,0,0,1-1-.48c-.71-.5-.65-.82.23-.93A29.27,29.27,0,0,0,120,31.13a.56.56,0,0,1,.82.49V69.46a.73.73,0,0,1-.83.83l-6.77-.05a.71.71,0,0,1-.78-.8l0-31.84A1.89,1.89,0,0,0,111,35.78Z" fill="#000000"/>
    <path d="M285.14,24.06a4.85,4.85,0,1,1-4.85-4.84A4.85,4.85,0,0,1,285.14,24.06Z" fill="#000000"/>
    <path d="M275.07,35.78a3.11,3.11,0,0,1-1-.48c-.72-.5-.66-.82.22-.93a29.32,29.32,0,0,0,9.8-3.24.56.56,0,0,1,.81.49l0,37.84a.73.73,0,0,1-.83.83l-6.77-.05a.7.7,0,0,1-.78-.8l0-31.84a1.88,1.88,0,0,0-1.4-1.82Z" fill="#000000"/>
    <path d="M213.09,36.61c-1.34.87-2.49,2.31-2.16,3.65.26,1.08,1.75,2.11,3,2.5l3.16,1a3.37,3.37,0,0,0,2.32-.08l7.38-3c3-1.23.69-5.35-2.53-6.48C219.94,32.67,216.06,34.79,213.09,36.61Zm7.09,31.81c-.61.08,3-2,3.6-2.35,7.63-5,2.19-15.31-4.31-19.75-.62-.47.34-.59.45-.77s.3-.3.47-.19c2.16,1.45,6.35,4.7,7.76,6.86,0,0-.12-1-.12-2.28l0-5.43c0-.79.29-3.44-.39-3-1.29.75-2.61,1.5-4.08,2.46A38.88,38.88,0,0,1,220,46.62a18.33,18.33,0,0,0-8.84,10.48c-2.14,6,.73,10.94,7.25,11.18a6.92,6.92,0,0,0,2.63-.61c.58-.21.8.31,1.5.35a2.32,2.32,0,0,1-.79.51C212,72.82,194.64,68,205,54.44a27.42,27.42,0,0,1,9.59-7.67c2.2-1.49,2.64-1.59-.15-2.18L203.81,41.9a1.52,1.52,0,0,1-1.08-2.1c3.39-7.81,12.52-10.71,20.34-8.65,5.13,1.33,7.14,6.19,8.4,11.58q3.18,13.5,6.43,26.35c.19.75-.09,1.11-.87,1.13l-6.58.08a1,1,0,0,1-1.1-1l-.26-6.94c0-.94-.28-1-.75-.19a15.87,15.87,0,0,1-6,6Z" fill="#000000"/>
  </g>
</svg>
            </div>
            
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-900 dark:text-gray-900"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
           
              <div className="space-y-2 py-6">
                
                {navigation.map((item) => (
                    <div
                      key={item.name}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-gray-900"
                          : "text-gray-900  hover:bg-gray-500",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      // aria-current={item.current ? "page" : undefined}
                      onClick={()=>setMobileMenuOpen(false)}
                    >
                      <Link to={item?.href}>
                      <i className={item.icon }> </i> {item.name}
                      </Link>
                    </div>
                  ))}
<div className="ml-3.5 mt-24 font-semibold text-gray-900"     onClick={()=>setMobileMenuOpen(false)}>


<DarkModeToggle darkMode={darkMode} toggleDarkMode={toggleDarkMode} customeClass={"text-gray-900 "  } />
                </div>
                <Disclosure as="div" className="">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-1/2 items-center gap-4 rounded-lg py-2 pl-9 mt-4 pr-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Genre
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {valuesArray.map((value, index) => (
                      <div
                        key={index}
                        onClick={() => handleDropdownChange(value)}
                        className="text-gray-600  block px-4 py-2 text-xs cursor-pointer font-1" // Retain your styling here
                      >
                        <div onClick={()=>setMobileMenuOpen(false)}>

                        {value}
                        </div>
                      </div>
                    ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
              <div className="py-6" onClick={()=>setMobileMenuOpen(false)}>
                {!user ? 
                <Link to={"/auth"}
                className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Sign in
                </Link>:
                <div onClick={handleLogout} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Sign Out
                </div>
                }
              </div>
             
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
    </div>
  );
};

export default Header;
